import { MercadopagoBody, PaymentBody } from './../../models/mercadopago.entity';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, TimeoutError } from 'rxjs';
import { CartEntity } from 'src/app/models/cart.entity';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MercadopagoService {
  private readonly _apiMercadopagoUrl = 'https://gmdisegnipruebas.minos.urltemporal.com/apis/Controller/MercadopagoController.php?action=';

  /*----------------CONFIG------------------*/

  private getRequestHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return headers;
  }

  protected handleError(error) {
    console.log(error);
    if (error instanceof TimeoutError) {
      return throwError('En este momento no podemos realizar la operación. Por favor intente nuevamente más tarde.');
    }
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(error);
  }

  constructor(private http: HttpClient) { }

  /*--------------------POST------------------------*/

  realizarPagoService(body: PaymentBody) {
    const url = `${this._apiMercadopagoUrl}realizarPago`;

    return this.http.post(url, JSON.stringify(body), { headers: this.getRequestHeaders() }).pipe(
      catchError(error => {
        return this.handleError(error);
      })
    );
  }

}
