import { Component } from '@angular/core';
import { UserStorage } from './models/user-storage.entity';
import { ResultHelper } from './models/Utils/result-helper.entity';
import { CarritoService } from './services/Endpoints/carrito.endpoint';
import { LoaderInterceptorService } from './services/Interceptors/loader-interceptor.service';
import { CryptoService } from './services/Utils/crypto.service';
import { SnackBarService } from './services/Utils/snackbar.service';
import { UserStorageService } from './services/Utils/user-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ecommerce';
  showLoaderBg = false;
  private usuarioStorage = new UserStorage();

  constructor(
    private loaderInterceptorService: LoaderInterceptorService,
    private userStorageService: UserStorageService,
    private carritoService: CarritoService,
    private cryptoService: CryptoService,
    private snackBarService: SnackBarService) {
    this.checkLocalStorageValues();
  }

  checkLocalStorageValues() {
    /* BUSCA EN EL LOCALSTORAGE SI EXISTE UN ITEM DEL USUARIO LOGUEADO
             * SI LO ENCUENTRA, LO DESENCRIPTA PARA SETEARLO EN EL SERVICIO DE LA APLICACION
             */
    const encryptedStorage = localStorage.getItem('userValues');
    if (encryptedStorage) {
      this.usuarioStorage.Usuario = JSON.parse(this.cryptoService.decrypt('userValues', encryptedStorage));
      // SI ESTA LOGUEADO, BUSCO EL NROVENTA
      if (this.usuarioStorage.Usuario.IsLogged) {
        this.getLocalStorageData();
      }
    }
  }

  getLocalStorageData() {
    this.carritoService.getUsuarioDataFromStorageService(this.usuarioStorage).then((result: ResultHelper) => {
      if (result.Ok) {
        this.userStorageService.setUserStorage(result.Result);
      } else {
        this.snackBarService.openSnackBar({ tipo: "danger" }, result.Mensaje, 3000, { pos: 'right' }, undefined, 'Ok');
      }
    },
      (error) => { this.snackBarService.openSnackBar({ tipo: "danger" }, error, 3000, { pos: 'right' }, undefined, 'Ok'); });
  }
}
