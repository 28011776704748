<form>
  <div class="d-flex row-form">
    <mat-form-field class="flex-one">
      <input matInput placeholder="Nombre y apellido" [(ngModel)]="mail.NombreCliente" name="nombreCliente">
      <mat-icon matSuffix>person</mat-icon>
    </mat-form-field>
    <mat-form-field class="flex-one">
      <input matInput placeholder="Correo" [(ngModel)]="mail.Email" name="correo">
      <mat-icon matSuffix>drafts</mat-icon>
    </mat-form-field>
    <mat-form-field class="flex-one">
      <input type="tel" matInput placeholder="Teléfono" appOnlyNumber [(ngModel)]="mail.Tel" name="telefono">
      <mat-icon matSuffix>call</mat-icon>
    </mat-form-field>
  </div>
  <div class="d-flex">
    <mat-form-field class="flex-one mensaje-form-field">
      <textarea matInput placeholder="Mensaje" [(ngModel)]="mail.Mensaje" name="mensaje"></textarea>
      <mat-icon matSuffix>create</mat-icon>
    </mat-form-field>
  </div>

  <button mat-raised-button class="pr-2" #btnEnviar (click)="sendMail(btnEnviar)">
    Enviar
    <mat-icon matSuffix class="pl-2">send</mat-icon>
  </button>
</form>
