import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularMaterialModule } from './angular-material.module';
import { SnackBarService } from './services/Utils/snackbar.service';
import { RedirectorService } from './services/Utils/redirector.service';
import { SpinnerService } from './services/Utils/spinner.service';
import { BottomSheetService } from './services/Utils/bottom-sheet.service';
import { AuthGuard } from './services/Utils/auth.guard';
import { UsuarioService } from './services/Endpoints/usuarios.endpoint';
import { CryptoService } from './services/Utils/crypto.service';
import { CarritoService } from './services/Endpoints/carrito.endpoint';
import { ProductoService } from './services/Endpoints/producto.endpoint';
import { UserStorageService } from './services/Utils/user-storage.service';
import { DEFAULT_TIMEOUT, LoaderInterceptorService } from './services/Interceptors/loader-interceptor.service';
import { LoginComponent } from './components/login/login.component';
import { MobileNavbarComponent } from './components/navbar/mobile-navbar/mobile-navbar.component';
import { DesktopNavbarComponent } from './components/navbar/desktop-navbar/desktop-navbar.component';
import { SearchFieldMobileComponent } from './components/shared/search-field/search-field-mobile/search-field-mobile.component';
import { SearchFieldDesktopComponent } from './components/shared/search-field/search-field-desktop/search-field-desktop.component';
import { AnimatedArrowComponent } from './components/shared/icons/animated-arrow/animated-arrow.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpLoaderComponent } from './components/shared/http-loader/http-loader.component';
import { FooterComponent } from './components/footer/footer.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { RegisterComponent } from './components/login/register/register.component';
import { HomeComponent } from './components/home/home.component';
import { DestacadosComponent } from './components/home/destacados/destacados.component';
import { CategoriaDestacadaComponent } from './components/home/categoria-destacada/categoria-destacada.component';
import { BannerItemsComponent } from './components/home/banner-items/banner-items.component';
import { SectionTitleComponent } from './components/shared/section-title/section-title.component';
import { CardSwiperComponent } from './components/shared/card-swiper/card-swiper.component';
import { BannerSlideshowComponent } from './components/home/banner-slideshow/banner-slideshow.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { OfertasComponent } from './components/home/ofertas/ofertas.component';
import { ProductosDestacadosComponent } from './components/home/productos-destacados/productos-destacados.component';
import { InfoServicioComponent } from './components/home/info-servicio/info-servicio.component';
import { ProductosComponent } from './components/productos/productos.component';
import { CategoryMenuDesktopComponent } from './components/productos/category-menu-desktop/category-menu-desktop.component';
import { CmbDropdownComponent } from './components/shared/cmb-dropdown/cmb-dropdown.component';
import { ListCheckboxComponent } from './components/shared/list-checkbox/list-checkbox.component';
import { ChipComponent } from './components/shared/chip/chip.component';
import { ModalFiltroProductosComponent } from './components/productos/modal-filtro-productos/modal-filtro-productos.component';
import { CategoryMenuMobileComponent } from './components/productos/category-menu-mobile/category-menu-mobile.component';
import { CardProductoComponent } from './components/shared/card-producto/card-producto.component';
import { GmCurrencyPipe } from './pipes/gmCurrency.pipe';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { StepperCartComponent } from './components/checkout/stepper-cart/stepper-cart.component';
import { ModalAgregarCantidadComponent } from './components/checkout/stepper-cart/modal-agregar-cantidad/modal-agregar-cantidad.component';
import { StepCompraProcesadaComponent } from './components/checkout/stepper-cart/step-compra-procesada/step-compra-procesada.component';
import { StepDetalleComponent } from './components/checkout/stepper-cart/step-detalle/step-detalle.component';
import { ModalConfirmarPedidoComponent } from './components/checkout/stepper-cart/step-detalle/modal-confirmar-pedido/modal-confirmar-pedido.component';
import { StepMiCarritoComponent } from './components/checkout/stepper-cart/step-mi-carrito/step-mi-carrito.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { FormularioComponent } from './components/contacto/formulario/formulario.component';
import { MercadopagoComponent } from './components/mercadopago/mercadopago.component';
import { DatosPersonalesComponent } from './components/mercadopago/datos-personales/datos-personales.component';
import { HeaderTitleComponent } from './components/shared/header-title/header-title.component';
import { DatosTarjetaComponent } from './components/mercadopago/datos-tarjeta/datos-tarjeta.component';
import { ModalConfirmarPagoComponent } from './components/mercadopago/modal-confirmar-pago/modal-confirmar-pago.component';
import { MiCuentaComponent } from './components/mi-cuenta/mi-cuenta.component';
import { ActualizaDatosComponent } from './components/mi-cuenta/actualiza-datos/actualiza-datos.component';
import { MisDatosComponent } from './components/mi-cuenta/mis-datos/mis-datos.component';
import { MisFavoritosComponent } from './components/mi-cuenta/mis-favoritos/mis-favoritos.component';
import { MisPedidosComponent } from './components/mi-cuenta/mis-pedidos/mis-pedidos.component';
import { NavMiCuentaMobileComponent } from './components/mi-cuenta/nav-mi-cuenta-mobile/nav-mi-cuenta-mobile.component';
import { OfertasProductosComponent } from './components/ofertas-productos/ofertas-productos.component';
import { PoliticasPrivacidadComponent } from './components/politicas-privacidad/politicas-privacidad.component';
import { PreguntasFrecuentesComponent } from './components/preguntas-frecuentes/preguntas-frecuentes.component';
import { QuienesSomosComponent } from './components/quienes-somos/quienes-somos.component';
import { TerminosCondicionesComponent } from './components/terminos-condiciones/terminos-condiciones.component';
import { OnlyNumberDirective } from './directives/onlyNumbers.directive';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    DesktopNavbarComponent,
    MobileNavbarComponent,
    SearchFieldMobileComponent,
    SearchFieldDesktopComponent,
    AnimatedArrowComponent,
    HttpLoaderComponent,
    FooterComponent,
    HomeComponent,
    DestacadosComponent,
    CategoriaDestacadaComponent,
    BannerItemsComponent,
    SectionTitleComponent,
    CardSwiperComponent,
    BannerSlideshowComponent,
    OfertasComponent,
    ProductosDestacadosComponent,
    InfoServicioComponent,
    ProductosComponent,
    CategoryMenuDesktopComponent,
    CategoryMenuMobileComponent,
    CmbDropdownComponent,
    ListCheckboxComponent,
    ChipComponent,
    ModalFiltroProductosComponent,
    CardProductoComponent,
    GmCurrencyPipe,
    CheckoutComponent,
    StepperCartComponent,
    ModalAgregarCantidadComponent,
    StepCompraProcesadaComponent,
    StepDetalleComponent,
    ModalConfirmarPedidoComponent,
    StepMiCarritoComponent,
    ContactoComponent,
    FormularioComponent,
    MercadopagoComponent,
    DatosPersonalesComponent,
    HeaderTitleComponent,
    DatosTarjetaComponent,
    ModalConfirmarPagoComponent,
    MiCuentaComponent,
    ActualizaDatosComponent,
    MisDatosComponent,
    MisFavoritosComponent,
    MisPedidosComponent,
    NavMiCuentaMobileComponent,
    OfertasProductosComponent,
    PoliticasPrivacidadComponent,
    PreguntasFrecuentesComponent,
    QuienesSomosComponent,
    TerminosCondicionesComponent,
    OnlyNumberDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularMaterialModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    SwiperModule,
    NgxSpinnerModule
  ],
  providers: [
    SnackBarService,
    RedirectorService,
    SpinnerService,
    BottomSheetService,
    AuthGuard,
    UsuarioService,
    CryptoService,
    CarritoService,
    ProductoService,
    UserStorageService,
    LoaderInterceptorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptorService,
      multi: true
    },
    [{ provide: DEFAULT_TIMEOUT, useValue: 60000 }]
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
