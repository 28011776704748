import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DocTypesResponse } from 'src/app/models/mercadopago.entity';
import { UserStorage } from 'src/app/models/user-storage.entity';
import { UserStorageService } from 'src/app/services/Utils/user-storage.service';

@Component({
  selector: 'app-datos-personales',
  templateUrl: './datos-personales.component.html',
  styleUrls: ['./datos-personales.component.scss']
})
export class DatosPersonalesComponent implements OnInit {
  @Input() mercadopagoObj;
  @Input() doctypesList: DocTypesResponse[];
  userStorage$ = new Observable<UserStorage>();
  /*---STORAGE DEL USUARIO---*/
  userStorage = new UserStorage();

  constructor(private userStorageService: UserStorageService) {
    this.userStorage$ = this.userStorageService.emitUserStorage;
    this.userStorage$.pipe().subscribe((res: UserStorage) => {
      if (res) {
        this.userStorage = res;
      }
    });
  }

  ngOnInit() {
  }

}
