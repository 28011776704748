
<app-header-title [title]="'Información Personal'"></app-header-title>
<div class="box">
  <label class="label-info" >{{ ((userStorage$ | async)?.Usuario.Nombre + ' ' + (userStorage$ | async)?.Usuario.Apellido | titlecase) || '-' }}</label>
</div>

<app-header-title [title]="'Datos de Contacto'"></app-header-title>
<div class="box">
  <label class="label-info"><strong>Teléfono: </strong>{{ (userStorage$ | async)?.Usuario.Celular || '-' }}</label>
  <label class="label-info">
    <strong>Domicilio: </strong>{{ (userStorage$ | async)?.Usuario.Domicilio || '-' }}
    <strong>Nro: </strong>{{ (userStorage$ | async)?.Usuario.NroDomicilio || '-' }}
  </label>
  <label class="label-info"><strong>Localidad: </strong>{{ (userStorage$ | async)?.Usuario.Localidad || '-' }}</label>
  <label class="label-info"><strong>Provincia: </strong>{{ (userStorage$ | async)?.Usuario.Provincia || '-' }}</label>
</div>

<app-header-title [title]="'Datos de la Cuenta'"></app-header-title>
<div class="box mb-4 box-datos-cuenta">
  <label class="label-info"><strong>Usuario / Email: </strong>
    {{ (userStorage$ | async)?.Usuario.Email || '-' }}
  </label>
</div>

<div class="footer">
  <p>Estimado {{ (userStorage$ | async)?.Usuario.Nombre || 'undefined' | titlecase }}, lo invitamos a mantener siempre los datos
    actualizados para hacer un correcto uso de nuestro sitio web. Muchas gracias</p>
</div>

