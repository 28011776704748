import { Component, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { Observable } from 'rxjs';
import { EstadoPagoEnum } from 'src/app/models/Enums/estado-pago.enum';
import { Pedido } from 'src/app/models/pedido.entity';
import { UserStorage } from 'src/app/models/user-storage.entity';
import { ResultHelper } from 'src/app/models/Utils/result-helper.entity';
import { UsuarioService } from 'src/app/services/Endpoints/usuarios.endpoint';
import { RedirectorService } from 'src/app/services/Utils/redirector.service';
import { SnackBarService } from 'src/app/services/Utils/snackbar.service';
import { UserStorageService } from 'src/app/services/Utils/user-storage.service';

@Component({
  selector: 'app-mis-pedidos',
  templateUrl: './mis-pedidos.component.html',
  styleUrls: ['./mis-pedidos.component.scss']
})
export class MisPedidosComponent implements OnInit {
  userStorage: UserStorage;
  userStorage$ = new Observable<UserStorage>();
  isLogged = false;
  pedidosLst: Pedido[] = [];
  pedidosPendientesLst: Pedido[] = [];
  pedidosPagadosLst: Pedido[] = [];
  typesOfShoes: string[] = ['Boots', 'Clogs', 'Loafers', 'Moccasins', 'Sneakers'];

  public estadosPago = EstadoPagoEnum;

  mostrarPagados: number;
  mostrarNoPagados: number;

  showPanels = false;

  constructor(
    private redirectorService: RedirectorService,
    private userStorageService: UserStorageService,
    private usuarioService: UsuarioService,
    private snackBarService: SnackBarService
  ) {
    this.userStorage$ = this.userStorageService.emitUserStorage;
    this.userStorage$.pipe().subscribe((res) => {
      if (res) {
        this.userStorage = res;
        this.isLogged = this.userStorage.Usuario.IsLogged;
      }
    });
  }

  ngOnInit() {
    this.getPedidosUsuario();
  }

  private getPedidosUsuario() {
    this.usuarioService.getPedidosUsuariosService(this.userStorage.Usuario.Id).subscribe((resultHelper: ResultHelper) => {
      if (resultHelper.Ok) {
        this.pedidosLst = resultHelper.Result;
        this.pedidosPendientesLst = this.pedidosLst.filter(x => x.IdEstadoPago === EstadoPagoEnum.PENDIENTE);
        this.pedidosPagadosLst = this.pedidosLst.filter(x => x.IdEstadoPago === EstadoPagoEnum.PAGADO);
        this.setMostrarPanels();
      } else {
        this.snackBarService.openSnackBar({ tipo: "danger" }, resultHelper.Mensaje, 5000, { pos: 'right' }, undefined, 'Ok');
      }
    }, (error) => {
      console.log(error);
      this.snackBarService.openSnackBar({ tipo: "danger" }, error, 3000, { pos: 'right' }, undefined, 'Ok');
    });
    setTimeout(() => {
      this.showPanels = true;
    }, 1500);
  }

  setMostrarPanels() {
    this.mostrarNoPagados = this.pedidosPendientesLst?.length;
    this.mostrarPagados = this.pedidosPagadosLst?.length
  }

  realizarPago(payment, btnPagar: MatButton) {
    btnPagar.disabled = true;
    this.userStorageService.setPaymentBehavior(payment);
    this.snackBarService.openSnackBar({ tipo: "info" }, 'Te llevaremos a la pantalla para que realices el pago', 3000, { pos: 'right' }, undefined, 'Ok');
    setTimeout(() => {
      this.redirectorService.redirectToInternalPage('pago');
      btnPagar.disabled = false;
    }, 3000);
  }

}
