<div class="container my-3">
  <div class="row">
    <div class="col">
      <app-stepper-cart #stepper (stepperIndexEmit)="getStepperIndex($event)" (isNextEnabled)="getNextEnabled($event)"
        [totalAmount]="total">
      </app-stepper-cart>
    </div>
  </div>
  <div class="row" *ngIf="stepperIndex < 2">
    <div class="col-sm-12 col-lg-4 mr-auto text-center">
      <div class="discount-container d-flex justify-content-center" *ngIf="(userStorage$ | async)?.CartList">
        <div *ngIf="!codigoSolicitado">
          <mat-form-field appearance="outline" class="cod-desc-form">
            <mat-label>Ingrese código de descuento</mat-label>
            <input matInput placeholder="" [(ngModel)]="descuentoEntity.CodigoDescuento">
          </mat-form-field>
          <button mat-stroked-button class="mx-2" (click)="aplicarDescuento()">Aplicar descuento</button>
        </div>
        <div *ngIf="codigoSolicitado">
          <mat-chip-list>
            <mat-chip color="primary" selected><div>Tenés un <strong> {{ descuentoEntity.Valor }}%
            </strong> de descuento en el monto final</div></mat-chip>
          </mat-chip-list>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="stepperIndex < 2">
    <div class="col-sm-12">
      <mat-toolbar class="toolbar-footer">
        <div class="d-flex w-100">
          <div class="flex-grow-1 h-100 justify-content-center text-center">
            <p class="w-100 font-bold">Subtotal</p>
            <p class="">{{ (subtotal) | gmCurrency }}</p>
          </div>
          <div class="flex-grow-1 h-100 justify-content-center text-center">
            <p class="w-100 font-bold">Descuento</p>
            <p class="">{{ (descuentoEntity.Valor || 0) }}%</p>
          </div>
          <div class="flex-grow-1 h-100 justify-content-center text-center">
            <p class="w-100 font-bold">Total</p>
            <p class="">{{ (total) | gmCurrency }}</p>
          </div>
          <div class="flex-grow-1 h-100 justify-content-center text-center align-items-center"
            *ngIf="stepperIndex == 0">
            <button mat-fab color="warn" (click)="goForward()" class="btn-pagar">Pagar</button>
          </div>
        </div>
      </mat-toolbar>
    </div>
  </div>
</div>
