<div class="row mx-auto">
  <mat-toolbar class="px-2 w-100 soft-shadow">
    <div class="d-flex w-100 justify-content-center">
      <div class="w-20 text-center">
        <p class="p-resultados">{{ getResultadoText() }}</p>
      </div>
      <div class="w-80 text-right">
        <button mat-stroked-button type="button" class="rounded gm-default d-inline-block btn-filtrar"
          (click)="openModalFiltrar()">Filtrar</button>
      </div>
    </div>
  </mat-toolbar>
  <!-- <app-cmb-select class="cmb-filtro mr-2" [listCmb]="listCmbFiltro"></app-cmb-select> -->

</div>
