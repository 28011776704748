import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { DescuentoEntity } from 'src/app/models/descuento.entity';
import { UserStorage } from 'src/app/models/user-storage.entity';
import { ResultHelper } from 'src/app/models/Utils/result-helper.entity';
import { CarritoService } from 'src/app/services/Endpoints/carrito.endpoint';
import { UsuarioService } from 'src/app/services/Endpoints/usuarios.endpoint';
import { RedirectorService } from 'src/app/services/Utils/redirector.service';
import { SnackBarService } from 'src/app/services/Utils/snackbar.service';
import { UserStorageService } from 'src/app/services/Utils/user-storage.service';
import { ModalConfirmarPedidoComponent } from './modal-confirmar-pedido/modal-confirmar-pedido.component';

@Component({
  selector: 'app-step-detalle',
  templateUrl: './step-detalle.component.html',
  styleUrls: ['./step-detalle.component.scss']
})
export class StepDetalleComponent implements OnInit {
  @Input() totalAmount = 0;
  @Output() termsCheckedEmit = new EventEmitter();
  @Output() goForwardEmit = new EventEmitter();
  tipoEntrega: number;
  isTermsChecked = false;
  userStorage: UserStorage;
  userStorage$ = new Observable<UserStorage>();
  tieneDescuento = false;

  constructor(private redirectorService: RedirectorService,
    private usuarioService: UsuarioService,
    private snackBarService: SnackBarService,
    private userStorageService: UserStorageService,
    private dialog: MatDialog,
    private carritoService: CarritoService) {
    this.userStorage$ = this.userStorageService.emitUserStorage;
    this.userStorage$.pipe().subscribe((res) => {
      if (res) {
        this.userStorage = res;
        if (this.userStorage.IdDescuento) {
          this.tieneDescuento = true;
        }

      }
    });
  }

  ngOnInit() {
  }

  emitTermsChecked() {
    this.termsCheckedEmit.emit(this.isTermsChecked);
  }

  goForward(btnConfirmarPedido: MatButton) {
    btnConfirmarPedido.disabled = true;
    if (!this.tipoEntrega) {
      this.snackBarService.openSnackBar({ tipo: "danger" }, 'Debe seleccionar un modo de entrega', 3000, { pos: 'right' }, undefined, 'Ok');
      btnConfirmarPedido.disabled = false;
      return false;
    }
    const dialogRef = this.dialog.open(ModalConfirmarPedidoComponent, {
      width: '250px',
      panelClass: '',
      data: {
        NroVenta: this.userStorage.NroVenta
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (this.tipoEntrega) {
          this.goForwardEmit.emit({ tipoEntrega: this.tipoEntrega, avanza: true });
        }
      } else {
        btnConfirmarPedido.disabled = false;
      }
    });

  }

  getDomicilioUsuario() {
    if (this.userStorage && this.userStorage.Usuario.Domicilio) {
      return this.userStorage.Usuario.Domicilio + ' ' + this.userStorage.Usuario.NroDomicilio
    }
    return '';
  }

  getCheckboxText() {
    return this.userStorage.ValorDescuento + '% de descuento.'
  }

  removerDescuento() {
    if (!this.tieneDescuento) {
      let descuentoEntity = new DescuentoEntity();
      descuentoEntity.Id = this.userStorage.IdDescuento;
      descuentoEntity.IdCliente = this.userStorage.Usuario.Id;
      this.carritoService.removerDescuentoService(descuentoEntity).subscribe((result: ResultHelper) => {
        if (result.Ok) {
          this.snackBarService.openSnackBar({ tipo: "success" }, 'Descuento removido correctamente. Ingrese nuevamente el código para aplicar el descuento', 4000, { pos: 'right' }, undefined, 'Ok');
          this.userStorage.IdDescuento = null;
          this.userStorage.ValorDescuento = null;
          this.userStorageService.setUserStorage(this.userStorage);
        } else {
          this.snackBarService.openSnackBar({ tipo: "danger" }, result.Mensaje, 3000, { pos: 'right' }, undefined, 'Ok');
        }
      },
        (error) => { this.snackBarService.openSnackBar({ tipo: "danger" }, error, 3000, { pos: 'right' }, undefined, 'Ok'); });

    }
  }

}
