import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { getCategoryNavList, getSubNavMenuList } from 'src/app/const/nav.const';
import { NavEntity } from 'src/app/models/nav.entity';
import { UserStorage } from 'src/app/models/user-storage.entity';
import { BottomSheetService } from 'src/app/services/Utils/bottom-sheet.service';
import { RedirectorService } from 'src/app/services/Utils/redirector.service';
import { UserStorageService } from 'src/app/services/Utils/user-storage.service';

@Component({
  selector: 'app-mobile-navbar',
  templateUrl: './mobile-navbar.component.html',
  styleUrls: ['./mobile-navbar.component.scss']
})
export class MobileNavbarComponent implements OnInit {
  @Output() sidenavToggle = new EventEmitter();
  /*---NAVEGADORES---*/
  subNavList: NavEntity[];
  categoriasNavList: NavEntity[];
  categoryMenuList: NavEntity[];
  userStorage$ = new Observable<UserStorage>();
  /*---STORAGE DEL USUARIO---*/
  userStorage = new UserStorage();

  constructor(
    private redirectorService: RedirectorService,
    private bottomSheetService: BottomSheetService,
    private userStorageService: UserStorageService) {
    this.userStorage$ = this.userStorageService.emitUserStorage;
    this.userStorage$.pipe().subscribe((res: UserStorage) => {
      if (res) {
        this.userStorage = res;
      }
    });
  }

  ngOnInit() {
    this.getSubnav();
    this.getCategoriasNav();
  }

  redirect(ruta) {
    this.onToggleSidenav();
    this.redirectorService.redirectToInternalPage(ruta);
  }

  redirectToCheckout() {
    this.redirectorService.redirectToInternalPage('checkout');
  }

  private getSubnav() {
    this.subNavList = getSubNavMenuList();
    this.subNavList.forEach((item, index) => {
      item.Id = index + 1;
    });
  }

  private getCategoriasNav() {
    this.categoryMenuList = getCategoryNavList();
    this.categoryMenuList.forEach((item, index) => {
      item.Id = index + 1;
    });
  }

  onToggleSidenav() {
    this.sidenavToggle.emit();
  }

}
