<div class="container">
  <div class="row">
    <div class="col p-5">
      <h1 class="h1-title w-100 text-center">Luz Verde Dietética</h1>
      <p class="p-content">
        Negocio que se dedica a la venta de productos de dietética en la ciudad de Córdoba con una amplia variedad en
        productos Sin TACC, Diabéticos, Vegetarianos y Veganos, Productos Naturales y Orgánicos, especias, cereales,
        frutos secos, suplementos.
      </p>
    </div>
  </div>
</div>
