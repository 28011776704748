import { Component, ElementRef, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FooterListEntity } from 'src/app/models/footer-list.entity';
import { UserStorage } from 'src/app/models/user-storage.entity';
import { BottomSheetService } from 'src/app/services/Utils/bottom-sheet.service';
import { RedirectorService } from 'src/app/services/Utils/redirector.service';
import { UserStorageService } from 'src/app/services/Utils/user-storage.service';
import { LoginComponent } from '../login/login.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  userStorage$ = new Observable<UserStorage>();
  /*---STORAGE DEL USUARIO---*/
  userStorage = new UserStorage();

  /*-------LISTAS---------*/
  firstColumnList: FooterListEntity[] = [];
  secondColumnList: FooterListEntity[] = [];
  thirdColumnList: FooterListEntity[] = [];
  fourthColumnList: FooterListEntity[] = [];
  iconsImg: string[] = [];

  logoImg = 'assets/images/footer/logo-gm-disegni.png';

  constructor(
    private redirectorService: RedirectorService,
    private userStorageService: UserStorageService,
    private bottomSheetService: BottomSheetService,
    private elRef: ElementRef
  ) {
    this.userStorage$ = this.userStorageService.emitUserStorage;
    this.userStorage$.pipe().subscribe((res: UserStorage) => {
      if (res) {
        this.userStorage = res;
      }
    });
    // document.querySelector('.mat-sidenav-content').scrollTop = 0;
  }

  ngOnInit() {
    this.setFirstColumnsList();
    this.setSecondColumnsList();
    this.setThirdColumnsList();
    this.setFourthColumnsList();
    this.setIconImg();
  }
  setFirstColumnsList() {
    this.firstColumnList = [
      {
        Icon: 'fab fa-whatsapp',
        Detalle: '9 351 2091556',
        Title: 'Enviar mensaje por WhatsApp'
      },
      {
        Icon: 'far fa-envelope',
        Detalle: 'ventas@dieteticasernatural.com.ar',
        Title: 'Enviar email de consulta'
      },
      {
        Icon: 'fas fa-map-marker-alt',
        Detalle: ' Tucumán 216, Córdoba',
        Title: 'Nuestra ubicación'
      },
      {
        Icon: '',
        Detalle: 'Atención: lu-vie 10 a 13hs y 16 a 19hs',
        Title: 'Nuestros horarios'
      }
    ]
  }

  setSecondColumnsList() {
    this.secondColumnList = [
      {
        Icon: '',
        Detalle: 'Productos',
        Link: 'productos'
      },
      {
        Icon: '',
        Detalle: 'Ofertas',
        Link: 'ofertas'
      },
      {
        Icon: '',
        Detalle: 'Quiénes somos',
        Link: 'quienes-somos'
      },
      {
        Icon: '',
        Detalle: 'Contacto',
        Link: 'contacto'
      }
    ]
  }

  setThirdColumnsList() {
    this.thirdColumnList = [
      {
        Icon: '',
        Detalle: 'Mi Cuenta',
        Link: ''
      },
      {
        Icon: '',
        Detalle: 'Registrarme',
        Link: ''
      }
    ]
  }

  setFourthColumnsList() {
    this.fourthColumnList = [
      {
        Icon: '',
        Detalle: 'Preguntas frecuentes',
        Link: 'preguntas-frecuentes'
      },
      {
        Icon: '',
        Detalle: 'Cómo comprar',
        Link: ''
      },
      {
        Icon: '',
        Detalle: 'Medios de pago',
        Link: ''
      }
    ]
  }

  setIconImg() {
    this.iconsImg = [
      'assets/images/footer/mp.png',
      'assets/images/footer/master.png',
      'assets/images/footer/visa.png',
      'assets/images/footer/naranja.png',
      'assets/images/footer/rapipago.png',
      'assets/images/footer/pagofacil.png',
    ]
  }

  redirect(ruta) {
    this.redirectorService.redirectToInternalPage(ruta);
  }

  /**
   * AL PRESIONAR EL BOTON DEL CARRITO SI NO ESTA LOGUEADO,
   * ABRE EL MODAL PARA LOGIN/REGISTRO.
   * SI ESTA LOGUEADO, REDIRIGE A LA PANTALLA DE MI CUENTA
   */
  redirectToMyAccount() {
    if (!this.userStorage.Usuario.IsLogged) {
      this.bottomSheetService.openBottomSheet(LoginComponent);
    } else {
      this.redirectorService.redirectToInternalPage('mi-cuenta');
    }
  }

}
