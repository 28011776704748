import { Component, OnInit } from '@angular/core';
import { BoxSliderDataEntity } from 'src/app/models/box-slider.entity';

@Component({
  selector: 'app-categoria-destacada',
  templateUrl: './categoria-destacada.component.html',
  styleUrls: ['./categoria-destacada.component.scss']
})
export class CategoriaDestacadaComponent implements OnInit {
  imgCatDest = './assets/images/index/img-cat-destac.jpg';
  cardDataList: BoxSliderDataEntity[];

  constructor() { }

  ngOnInit() {
    this.getCardDataList();
  }

  getCardDataList() {
    this.cardDataList = [
      {
        ImgUrl: './assets/images/index/imageBox.png',
        Title: 'Zapallo en almibar x400grs',
        Price: 206.8,
        Quantity: 0
      },
      {
        ImgUrl: './assets/images/index/imageBox.png',
        Title: 'Arrope de uva x 450grs',
        Price: 170,
        Quantity: 0
      },
      {
        ImgUrl: './assets/images/index/imageBox.png',
        Title: 'Harina panaderia 000 x 25kg',
        Price: 288,
        Quantity: 0
      },
      {
        ImgUrl: './assets/images/index/imageBox.png',
        Title: 'Harina panaderia 000 x 25kg',
        Price: 288,
        Quantity: 0
      },
      {
        ImgUrl: './assets/images/index/imageBox.png',
        Title: 'Harina panaderia 000 x 25kg',
        Price: 288,
        Quantity: 0
      },
      {
        ImgUrl: './assets/images/index/imageBox.png',
        Title: 'Harina panaderia 000 x 25kg',
        Price: 288,
        Quantity: 0
      },
      {
        ImgUrl: './assets/images/index/imageBox.png',
        Title: 'Harina panaderia 000 x 25kg',
        Price: 288,
        Quantity: 0
      },
      {
        ImgUrl: './assets/images/index/imageBox.png',
        Title: 'Harina panaderia 000 x 25kg',
        Price: 288,
        Quantity: 0
      }
    ];

  }

}
