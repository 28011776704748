<div class="container">
  <div class="row">
    <div class="col">
      <app-section-title [title]="'Te puede interesar'" class="section-title"></app-section-title>
      <div class="box-content">
        <div class="box-item soft-shadow" *ngFor="let item of lstBoxOferta">

          <div class="box-txt-content">
            <p class="p-content-4">{{ item.Description }}</p>
            <button mat-stroked-button type="button" class="rounded gm-default btn-comprar">Comprar ahora</button>
          </div>

          <div class="box-img-content">
            <img [src]="item.ImgUrl" alt="" class="img-content-4" />
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
