import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { FavoritoEntity } from 'src/app/models/favorito.entity';
import { Producto } from 'src/app/models/producto.entity';
import { UserStorage } from 'src/app/models/user-storage.entity';
import { ResultHelper } from 'src/app/models/Utils/result-helper.entity';
import { CarritoService } from 'src/app/services/Endpoints/carrito.endpoint';
import { FavoritoService } from 'src/app/services/Endpoints/favorito.endpoint';
import { SnackBarService } from 'src/app/services/Utils/snackbar.service';
import { UserStorageService } from 'src/app/services/Utils/user-storage.service';

@Component({
  selector: 'app-card-producto',
  templateUrl: './card-producto.component.html',
  styleUrls: ['./card-producto.component.scss']
})
export class CardProductoComponent implements OnInit {
  @Output() productEmit = new EventEmitter();
  @Output() productFavoriteEmit = new EventEmitter();
  @Input() productoItem = new Producto();
  @Input() fromFavoritos = false;
  userStorage: UserStorage;
  isLogged = false;
  quantity: number;
  userStorage$ = new Observable<UserStorage>();

  constructor(
    private userStorageService: UserStorageService,
    private snackBarService: SnackBarService,
    private carritoService: CarritoService,
    private favoritoService: FavoritoService) {
    this.userStorage$ = this.userStorageService.emitUserStorage;
    this.userStorage$.subscribe((res: UserStorage) => {
      if (res) {
        this.userStorage = res;
        this.isLogged = this.userStorage.Usuario.IsLogged;
      }
    });
  }

  ngOnInit() {
  }

  emitProductToCart() {
    if (!this.quantity) {
      this.snackBarService.openSnackBar({ tipo: "danger" }, 'Debe ingresar una cantidad válida', 3000, { pos: 'right' }, undefined, 'Ok');
      return false;
    }
    this.productoItem.Quantity = this.quantity
    this.productEmit.emit(this.productoItem);
    this.quantity = undefined;
  }

  emitProductToFavorites() {
    let favoritoItem = new FavoritoEntity();
    favoritoItem.Fecha = new Date();
    favoritoItem.IdCliente = this.userStorage.Usuario.Id;
    favoritoItem.IdProducto = this.productoItem.Id;

    this.favoritoService.postAgregarFavoritoService(favoritoItem).subscribe((result: ResultHelper) => {
      if (result.Ok) {
        this.snackBarService.openSnackBar({ tipo: "alternative" }, 'Producto agregado como favorito', 3000, { pos: 'right' }, undefined, 'Ok');
      } else {
        this.snackBarService.openSnackBar({ tipo: "danger" }, result.Mensaje, 3000, { pos: 'right' }, undefined, 'Ok');
      }
    },
      (error) => {
        this.snackBarService.openSnackBar({ tipo: "danger" }, error, 3000, { pos: 'right' }, undefined, 'Ok');
      });
  }

  emiteRemoveFromFavorite() {
    this.productFavoriteEmit.emit(this.productoItem);
  }

}
