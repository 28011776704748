import { Component, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { Observable } from 'rxjs';
import { Pedido } from 'src/app/models/pedido.entity';
import { UserStorage } from 'src/app/models/user-storage.entity';
import { RedirectorService } from 'src/app/services/Utils/redirector.service';
import { SnackBarService } from 'src/app/services/Utils/snackbar.service';
import { UserStorageService } from 'src/app/services/Utils/user-storage.service';

@Component({
  selector: 'app-step-compra-procesada',
  templateUrl: './step-compra-procesada.component.html',
  styleUrls: ['./step-compra-procesada.component.scss']
})
export class StepCompraProcesadaComponent implements OnInit {
  userStorage: UserStorage;
  userStorage$ = new Observable<UserStorage>();
  payment = new Pedido();

  constructor(
    private redirectorService: RedirectorService,
    private userStorageService: UserStorageService,
    private snackBarService: SnackBarService
  ) {
    this.userStorage$ = this.userStorageService.emitUserStorage;
    this.userStorage$.pipe().subscribe((res) => {
      if (res) {
        this.userStorage = res;
      }
    });
  }

  ngOnInit() {
  }

  redirectToPago(btnPagar: MatButton) {
    btnPagar.disabled = true;
    this.userStorageService.setPaymentBehavior(this.payment);
    this.payment.Monto = this.userStorage.TotalVenta;
    this.payment.NroVenta = this.userStorage.NroVenta;
    this.snackBarService.openSnackBar({ tipo: "info" }, 'Te llevaremos a la pantalla para que realices el pago', 3000, { pos: 'right' }, undefined, 'Ok');
    setTimeout(() => {
      this.userStorageService.cleanPedidoVenta();
      this.redirectorService.redirectToInternalPage('pago');
    }, 3000);
  }

}
