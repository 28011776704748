import { Component, OnInit } from '@angular/core';
import { RedirectorService } from 'src/app/services/Utils/redirector.service';

@Component({
  selector: 'app-terminos-condiciones',
  templateUrl: './terminos-condiciones.component.html',
  styleUrls: ['./terminos-condiciones.component.scss']
})
export class TerminosCondicionesComponent implements OnInit {

  constructor(private redirectorService: RedirectorService) { }

  ngOnInit() {
  }

  redirect(ruta) {
    this.redirectorService.redirectToInternalPage(ruta);
  }

}
