<nav>
  <mat-toolbar>
    <!-- BOX IZQUIERDA -->
    <div class="box-contacto">
      <mat-nav-list class="list-horizontal list-contacto">
        <mat-list-item class="subheader-item">
          <a class="item link" href="#" target="new" title="Enviar mensaje por WhatsApp">
            <i class="fab fa-whatsapp"></i>
            <span class="txt-sub-header-izq">
              9 3512091556
            </span>
          </a>
        </mat-list-item>
        <mat-list-item class="subheader-item">
          <a class="item link" href="#" target="new" title="Enviar por email">
            <i class="far fa-envelope"></i>
            <span class="txt-sub-header-izq hide-mobile">
              ventas@dieteticasernatural.com.ar
            </span>
          </a>
        </mat-list-item>
        <mat-list-item class="subheader-item">
          <a class="item link" href="#" target="new" title="Ubicacion">
            <i class="fas fa-map-marker-alt"></i>
            <span class="txt-sub-header-izq hide-mobile">
              Tucumán 216
            </span>
          </a>
        </mat-list-item>
        <mat-list-item class="subheader-item">
          <a class="item" href="#" target="new" title="Seguinos en facebook">
            <i class="fab fa-facebook"></i>
          </a>
        </mat-list-item>
        <mat-list-item class="subheader-item">
          <a class="item" href="#" target="new" title="Enviar por email">
            <i class="fab fa-instagram"></i>
          </a>
        </mat-list-item>
      </mat-nav-list>
    </div>
  </mat-toolbar>

  <!-- NAV PRINCIPAL -->
  <mat-toolbar color="primary" class="toolbar-nav soft-shadow">
    <div class="nav-toolbar-content">
      <!-- Nav -->
      <div fxShow="true" fxHide.lt-sm="false" class="d-inline-flex justify-content-center nav-logo">
        logo
      </div>
      <div class="search-container-mobile">
        <div class="mobile-input">
        <app-search-field-mobile></app-search-field-mobile>
        </div>
      </div>
      <div fxShow="true" fxHide.lt-sm="true" class="nav-principal">
        <mat-nav-list class="list-horizontal d-inline-flex justify-content-center nav-list-principal">
          <mat-list-item *ngFor="let itemMenu of subNavList">
            <a mat-button href="javascript:void(0)" (click)="redirect(itemMenu.Link)">
              {{ itemMenu.Title }}</a>
          </mat-list-item>
        </mat-nav-list>
      </div>
      <div class="menu-buttons">
        <div class="d-inline-flex mobile-container">
          <div class="mobile-flex mobile-menu-btn">
            <button mat-icon-button (click)="onToggleSidenav()">
              <mat-icon>menu</mat-icon>
            </button>
          </div>
          <div class="mobile-flex">
            <button mat-icon-button aria-label="Mi cuenta" class="mr-1" (click)="redirectToMyAccount()">
              <mat-icon>account_circle</mat-icon>
            </button>
          </div>
          <div class="mobile-flex">
            <button mat-icon-button aria-label="Favoritos" class="mr-1">
              <mat-icon>favorite</mat-icon>
            </button>
          </div>
          <div class="mobile-flex">
            <button mat-icon-button class="btn-cart" matBadge="{{ (userStorage$ | async)?.CartList.length }}"
              [disabled]="!(userStorage$ | async)?.CartList?.length" (click)="redirectToCheckout()">
              <mat-icon>shopping_cart</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="box-btn-categorias" (click)="toggleMenuCategorias()">
      <a href="javascript:void(0)">
        Categorías
        <app-animated-arrow [animateIcon]="isMenuCategoriasOpened"></app-animated-arrow>
      </a>
    </div>
  </mat-toolbar>

  <mat-toolbar class="soft-shadow submenu-categorias"
    [ngClass]="{'submenu-categorias-displayed': isMenuCategoriasOpened}">
    <mat-list class="list-horizontal list-submenu-container">
      <mat-list-item class="list-item-submenu" *ngFor="let itemMenu of categoryMenuList">
        <a href="javascript:void(0)" (click)="redirect(itemMenu.Link)">
          {{ itemMenu.Title }}</a>
      </mat-list-item>

      <mat-list-item class="desktop-input">
        <app-search-field-desktop></app-search-field-desktop>
      </mat-list-item>
    </mat-list>
  </mat-toolbar>
</nav>
