import { ConfirmaPedidoEntity } from '../../models/confirma-pedido.entity';
import { UserStorage } from 'src/app/models/user-storage.entity';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { throwError, TimeoutError } from 'rxjs';
import { UserEntity } from 'src/app/models/user.entity';
import { catchError, map, retry } from 'rxjs/operators';
import { BoxSliderDataEntity } from 'src/app/models/box-slider.entity';
import { CartEntity } from 'src/app/models/cart.entity';
import { DescuentoEntity } from 'src/app/models/descuento.entity';

@Injectable({
  providedIn: 'root'
})
export class CarritoService {
  private readonly _apiCarritoUrl = 'https://gmdisegnipruebas.minos.urltemporal.com/apis/Controller/CarritoController.php?action=';

  /*----------------CONFIG------------------*/

  private getRequestHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return headers;
  }

  protected handleError(error) {
    console.log(error);
    if (error instanceof TimeoutError) {
      return throwError('En este momento no podemos realizar la operación. Por favor intente nuevamente más tarde.');
    }
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(error);
  }

  constructor(private http: HttpClient) { }

  /*--------------------GET------------------------*/

  getUsuarioDataFromStorageService(usuarioEntity: UserStorage) {
    const url = `${this._apiCarritoUrl}getUserDataFromStorage`;
    const params = new HttpParams()
      .set('param', JSON.stringify(usuarioEntity));

    return new Promise((resolve, reject) => {
      this.http.get(url, { headers: this.getRequestHeaders(), params }).pipe(
        map(data => {
          resolve(data);
        }))
          .subscribe(result => {
      }, (error) => { return this.handleError(error)})
    })
  }

  findNroVentaService(idUsuario: number) {
    const url = `${this._apiCarritoUrl}findNroVenta`;
    const params = new HttpParams()
      .set('param', (idUsuario) ? JSON.stringify(idUsuario) : null);

    return this.http.get(url, { headers: this.getRequestHeaders(), params }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  getItemsCarrito(nroVenta: number) {
    const url = `${this._apiCarritoUrl}getProductosCarrito`;
    const params = new HttpParams()
      .set('param', (nroVenta) ? JSON.stringify(nroVenta) : null);
    return this.http.get(url, { headers: this.getRequestHeaders(), params }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  /*--------------------POST------------------------*/

  postProductToCartService(item: CartEntity) {
    const url = `${this._apiCarritoUrl}agregarProductoCarrito`;

    return this.http.post(url, JSON.stringify(item), { headers: this.getRequestHeaders() }).pipe(
      catchError(error => {
        return this.handleError(error);
      })
    );
  }

  deleteProductFromCartService(item: CartEntity) {
    const url = `${this._apiCarritoUrl}deleteProductFromCart`;

    return this.http.post(url, JSON.stringify(item), { headers: this.getRequestHeaders() }).pipe(
      catchError(error => {
        return this.handleError(error);
      })
    );
  }

  modificarCantidadProductoCarritoService(item: CartEntity) {
    const url = `${this._apiCarritoUrl}modificarCantidadProductoCarrito`;

    return this.http.post(url, JSON.stringify(item), { headers: this.getRequestHeaders() }).pipe(
      catchError(error => {
        return this.handleError(error);
      })
    );
  }

  confirmarPedidoService(item: ConfirmaPedidoEntity) {
    const url = `${this._apiCarritoUrl}confirmarPedidoVenta`;

    return this.http.post(url, JSON.stringify(item), { headers: this.getRequestHeaders() }).pipe(
      catchError(error => {
        return this.handleError(error);
      })
    );
  }

  postAplicarDescuentoVentaService(descuentoEntity: DescuentoEntity) {
    const url = `${this._apiCarritoUrl}aplicarDescuentoVenta`;

    return this.http.post(url, JSON.stringify(descuentoEntity), { headers: this.getRequestHeaders() }).pipe(
      catchError(error => {
        return this.handleError(error);
      })
    );
  }

  removerDescuentoService(descuentoEntity: DescuentoEntity) {
    const url = `${this._apiCarritoUrl}removerDescuentoVenta`;

    return this.http.post(url, JSON.stringify(descuentoEntity), { headers: this.getRequestHeaders() }).pipe(
      catchError(error => {
        return this.handleError(error);
      })
    );
  }

}
