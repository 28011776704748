import { FavoritoEntity } from './../../models/favorito.entity';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, TimeoutError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FavoritoService {

  private readonly _apiFavoritoUrl = 'https://gmdisegnipruebas.minos.urltemporal.com/apis/Controller/FavoritoController.php?action=';

  /*----------------CONFIG------------------*/

  private getRequestHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return headers;
  }

  protected handleError(error) {
    console.log(error);
    if (error instanceof TimeoutError) {
      return throwError('En este momento no podemos realizar la operación. Por favor intente nuevamente más tarde.');
    }
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(error);
  }

  constructor(private http: HttpClient) { }

  /*--------------------GET------------------------*/

  getFavoritosService(idCliente) {
    const url = `${this._apiFavoritoUrl}getFavoritos`;
    const params = new HttpParams()
      .set('param', (idCliente) ? JSON.stringify(idCliente) : null);

    return this.http.get(url, { headers: this.getRequestHeaders(), params }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  /*--------------------POST------------------------*/

  postAgregarFavoritoService(item: FavoritoEntity) {
    const url = `${this._apiFavoritoUrl}agregarFavorito`;

    return this.http.post(url, JSON.stringify(item), { headers: this.getRequestHeaders() }).pipe(
      catchError(error => {
        return this.handleError(error);
      })
    );
  }

  removerFavoritoService(item: FavoritoEntity) {
    const url = `${this._apiFavoritoUrl}removerFavorito`;

    return this.http.post(url, JSON.stringify(item), { headers: this.getRequestHeaders() }).pipe(
      catchError(error => {
        return this.handleError(error);
      })
    );
  }

}
