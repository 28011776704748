<div class="d-flex w-100 flex-wrap">
  <div class="flex-grow-1 justify-content-center text-center flex-container-middle box-datos-personales">
    <mat-card class="p-3">
      <mat-card-header>
        <mat-card-title>Datos de su cuenta
          <mat-divider class="absolute-center mx-auto card-title"></mat-divider>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-list role="list">
          <mat-list-item role="listitem">
            <p class="mobile-p">Teléfono: <strong>{{ (userStorage$ | async)?.Usuario.Celular }}</strong></p>
          </mat-list-item>
          <mat-list-item role="listitem">
            <p class="mobile-p">Domicilio: <strong>{{ getDomicilioUsuario() }}</strong></p>
          </mat-list-item>
          <mat-list-item role="listitem">
            <p class="mobile-p"> Localidad: <strong>{{ (userStorage$ | async)?.Usuario.Localidad }}</strong> </p>
          </mat-list-item>
          <mat-list-item role="listitem">
            <p class="mobile-p">Usuario / Email: <strong>{{ (userStorage$ | async)?.Usuario.Email  }}</strong></p>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="flex-grow-1 justify-content-center text-center flex-container-middle">
    <mat-card class="p-3">
      <mat-card-header>
        <mat-card-title>Total de su compra
          <mat-divider class="absolute-center mx-auto card-title"></mat-divider>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <p class="w-100 mobile-p">El total de su compra consiste en los productos agregados a su carrito más el
          descuento (si
          está vigente) aplicado.</p>
        <mat-checkbox *ngIf="tieneDescuento" class="d-block mb-2" [(ngModel)]="tieneDescuento"
          (change)="removerDescuento()">{{ getCheckboxText()  }}</mat-checkbox>
        <p class="w-100 font-bold mobile-p">Su compra es de</p>
        <p class="p-total mobile-p">{{ totalAmount | gmCurrency }}</p>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="justify-content-center text-center w-100 mt-3 last-card">
    <mat-card class="p-3">
      <mat-card-header>
        <mat-card-title>A continuación seleccione el modo de entrega
          <mat-divider class="absolute-center mx-auto card-title"></mat-divider>
        </mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-radio-group [(ngModel)]="tipoEntrega">
          <div class="d-flex">
            <div class="flex-grow-1 flex-container-middle justify-content-center text-center jumbo-content">
              <mat-list role="list">
                <mat-list-item role="listitem" class="list-item-content">
                  <div class="d-flex w-100">
                    <div class="flex-grow-1">
                      <p class="p-title-jumbo w-100 text-left position-relative">Envío a Domicilio</p>
                      <p class="w-100 text-left p-text-jumbo">Entregamos el pedido a tu domicilio registrado. Es
                        importante mantener los datos actualizados correctamente.</p>
                    </div>
                    <div>
                      <mat-radio-button class="" [value]="1"></mat-radio-button>
                    </div>
                  </div>
                </mat-list-item>

                <mat-divider></mat-divider>

                <mat-list-item role="listitem" class="list-item-content">
                  <div class="d-flex w-100">
                    <div class="flex-grow-1">
                      <p class="p-title-jumbo w-100 text-left position-relative">Retiro en Local</p>
                      <p class="w-100 text-left p-text-jumbo">Retira el pedido en nuestra tienda en Avenida Colon
                        5280.
                        Ciudad de Córdoba</p>
                    </div>
                    <div>
                      <mat-radio-button class="" [value]="2"></mat-radio-button>
                    </div>
                  </div>
                </mat-list-item>
                <mat-divider class="absolute-center mx-auto"></mat-divider>
                <mat-list-item role="listitem" class="list-item-footer">
                  <div class="footer-container">
                    <mat-checkbox class="checkbox-terminos" [(ngModel)]="isTermsChecked" (change)="emitTermsChecked()">
                      Estoy aceptando los Términos y Condiciones
                    </mat-checkbox>
                    <button #btnConfirmarPedido mat-flat-button class="btn-green btn-confirmar" [disabled]="!isTermsChecked"
                      (click)="goForward(btnConfirmarPedido)">Confirmar Pedido</button>
                    <div>
                    </div>
                  </div>
                </mat-list-item>
              </mat-list>
            </div>
          </div>
        </mat-radio-group>
      </mat-card-content>
    </mat-card>
  </div>
</div>
