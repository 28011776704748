import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Observable } from 'rxjs';
import { getCategoryNavList, getSubNavMenuList } from 'src/app/const/nav.const';
import { NavEntity } from 'src/app/models/nav.entity';
import { UserStorage } from 'src/app/models/user-storage.entity';
import { BottomSheetService } from 'src/app/services/Utils/bottom-sheet.service';
import { RedirectorService } from 'src/app/services/Utils/redirector.service';
import { UserStorageService } from 'src/app/services/Utils/user-storage.service';
import { LoginComponent } from '../../login/login.component';

@Component({
  selector: 'app-desktop-navbar',
  templateUrl: './desktop-navbar.component.html',
  styleUrls: ['./desktop-navbar.component.scss']
})
export class DesktopNavbarComponent implements OnInit {
  @Output() public sidenavToggle = new EventEmitter();
  userStorage$ = new Observable<UserStorage>();
  /*---STORAGE DEL USUARIO---*/
  userStorage = new UserStorage();

  /*---NAVEGADORES---*/
  subNavList: NavEntity[];
  categoryMenuList: NavEntity[];

  /*---DATOS PARA EL TEMPLATE---*/
  cartQuantity = 0;
  @ViewChild('itemsMenuTrigger') cartMenuTrigger: MatMenuTrigger;
  isCartMenuOpened = false;
  isLogged = false;
  isMenuCategoriasOpened = false;

  constructor(
    private redirectorService: RedirectorService,
    private bottomSheetService: BottomSheetService,
    private userStorageService: UserStorageService) {
    this.userStorage$ = this.userStorageService.emitUserStorage;
    this.userStorage$.pipe().subscribe((res: UserStorage) => {
      if (res) {
        this.userStorage = res;
        this.cartQuantity = res.CartList.length;
        this.isLogged = res.Usuario.IsLogged;
      }
    });
  }

  ngOnInit() {
    this.getSubnav();
    this.getCategoryMenuList();
  }

  private getSubnav() {
    this.subNavList = getSubNavMenuList();
    this.subNavList.forEach((item, index) => {
      item.Id = index + 1;
    });
    return this.subNavList;
  }

  private getCategoryMenuList() {
    this.categoryMenuList = getCategoryNavList();
    this.categoryMenuList.forEach((item, index) => {
      item.Id = index + 1;
    });
    return this.categoryMenuList;
  }

  redirect(ruta) {
    this.redirectorService.redirectToInternalPage(ruta);
  }

  redirectToCheckout() {
    this.redirectorService.redirectToInternalPage('checkout');
  }

  /**
   * AL PRESIONAR EL BOTON DEL CARRITO SI NO ESTA LOGUEADO,
   * ABRE EL MODAL PARA LOGIN/REGISTRO.
   * SI ESTA LOGUEADO, REDIRIGE A LA PANTALLA DE MI CUENTA
   */
  redirectToMyAccount() {
    if (!this.isLogged) {
      this.bottomSheetService.openBottomSheet(LoginComponent);
    } else {
      this.redirectorService.redirectToInternalPage('mi-cuenta');
    }
  }

  onToggleSidenav() {
    this.sidenavToggle.emit();
  }

  toggleMenuCategorias() {
    this.isMenuCategoriasOpened = !this.isMenuCategoriasOpened;
  }

}
