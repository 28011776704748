import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RedirectorService {

  constructor(private router: Router) { }

  redirectToInternalPage(ruta) {
    this.router.navigate([ruta])
    // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
    //   this.router.navigate([ruta]));
  }

  redirectToHomePage() {
    this.router.navigate(['home']);
  }

}
