export class BoxSliderEntity {
  Title: string;
  Data: BoxSliderDataEntity[];
}

export class BoxSliderDataEntity {
  Id?: any;
  ImgUrl?: string;
  Title?: string;
  Price?: any;
  Quantity: number;
  BtnText?: string;
  CategoryId?: number;
  Subtotal?: number;
}


