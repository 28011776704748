import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChipEntity } from 'src/app/models/chip.entity';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})
export class ChipComponent implements OnInit {
  @Input() chipList: ChipEntity[] = [];
  @Output() chipItemEmit = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  removerItem(item: ChipEntity) {
    const index = this.chipList.indexOf(item);

    if (index >= 0) {
      this.chipList.splice(index, 1);
      this.chipItemEmit.emit(item.Id);
    }
  }

}
