<div class="top-bar">
  <button mat-icon-button class="btn-cerrar-modal" (click)="cerrarModal(false)" aria-label="Close modal filter">
    <mat-icon>highlight_off</mat-icon>
  </button>
</div>
<mat-list>
  <div mat-subheader>Precio</div>
  <mat-radio-group aria-label="Select an option" name="priceFilter"
    (change)="addFilterToList(true)" [(ngModel)]="priceItemModel">
    <mat-list-item class="filter-item" *ngFor="let priceItem of filterLists.priceList">
      <mat-radio-button name="{{priceItem.Detalle}}" [value]="priceItem.Id" [checked]="priceItem.Id == 2">
        {{ priceItem.Detalle }}</mat-radio-button>
    </mat-list-item>
  </mat-radio-group>
  <mat-divider></mat-divider>
  <div mat-subheader>Categoría</div>
  <mat-list-item class="filter-item" *ngFor="let categoriaItem of filterLists.categoryList">
    <mat-checkbox [checked]="categoriaItem.Checked" (change)="addFilterToList(false, categoriaItem, $event.checked)">
      {{ categoriaItem.Nombre }}
    </mat-checkbox>
  </mat-list-item>
</mat-list>
<div class="bottom-bar">
  <button mat-flat-button (click)="cerrarModal(true)" class="btn-aplica-filtro">Aplicar</button>
</div>
