import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap, timeout } from 'rxjs/operators';
import { SnackBarService } from '../Utils/snackbar.service';
export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptorService implements HttpInterceptor {
  isSpinnerOpen = false;

  constructor(
    private router: Router,
    @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number,
    private snackBarService: SnackBarService) {
  }

  checkSnackbarOpened() {
    return new Observable<boolean>((observer) => {
      observer.next(this.isSpinnerOpen);
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> | any {
    const timeoutValue = req.headers.get('timeout') || this.defaultTimeout;
    const timeoutValueNumeric = Number(timeoutValue);

    this.snackBarService.openLoaderSnackBar('loader-snackbar');
      this.isSpinnerOpen = true;
    // if (this.router.url !== '/login') {
    //   this.snackBarService.openLoaderSnackBar();
    //   this.isSpinnerOpen = true;
    // }
    return next
      .handle(req)
      .pipe(
        timeout(timeoutValueNumeric),
        tap((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            this.snackBarService.dismissSnackbar();
            this.isSpinnerOpen = false;
          }
        }, (error) => {
          this.snackBarService.dismissSnackbar();
          this.isSpinnerOpen = false;
        })
      );

  }

}
