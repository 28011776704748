import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { NavEntity } from 'src/app/models/nav.entity';

@Component({
  selector: 'app-nav-mi-cuenta-mobile',
  templateUrl: './nav-mi-cuenta-mobile.component.html',
  styleUrls: ['./nav-mi-cuenta-mobile.component.scss']
})
export class NavMiCuentaMobileComponent implements OnInit {
  @Input() miCuentaList: NavEntity[] = [];
  @Output() showMenuContentEmit = new EventEmitter();
  @Output() cerrarSesionEmit = new EventEmitter();
  @ViewChild(MatExpansionPanel, { static: true }) matExpansionPanelElement: MatExpansionPanel;
  panelExpanded = false;

  constructor() { }

  ngOnInit() {
  }

  showMenuContent(link) {
    this.matExpansionPanelElement.toggle();
    this.showMenuContentEmit.emit(link);
  }

  cerrarSesion() {
    this.cerrarSesionEmit.emit();
  }


}
