import { Component, OnInit } from '@angular/core';
import { BoxEntity } from 'src/app/models/box.entity';

@Component({
  selector: 'app-info-servicio',
  templateUrl: './info-servicio.component.html',
  styleUrls: ['./info-servicio.component.scss']
})
export class InfoServicioComponent implements OnInit {
  lstBox: BoxEntity[] = [];

  constructor() { }

  ngOnInit() {
    this.getListBoxData();
  }

  getListBoxData() {
    this.lstBox = [
      {
        ImgUrl: './assets/images/index/envio-home.png',
        Title: 'Envío gratuito',
        Description: 'Hacemos envíos en toda la ciudad'
      },
      {
        ImgUrl: './assets/images/index/retiro-tienda-home.png',
        Title: 'Retiro en tienda',
        Description: 'Retire en nuestro negocio'
      },
      {
        ImgUrl: './assets/images/index/soporte-home.png',
        Title: 'Asesoramiento especial',
        Description: 'Te asesoramos en tu compra'
      },
      {
        ImgUrl: './assets/images/index/pago-seguro-home.png',
        Title: 'Medios de pago seguros',
        Description: 'Aceptamos diferentes medios de pago'
      }
    ];
  }

}
