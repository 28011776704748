import { CartEntity } from 'src/app/models/cart.entity';
import { UserEntity } from 'src/app/models/user.entity';
export class UserStorage {
  Id?: number;
  NroVenta: number;
  Usuario: UserEntity;
  CartList: CartEntity[];
  CartData: CartDataEntity;
  IdDescuento: number;
  ValorDescuento: number;
  TotalVenta: number;

  constructor() {
    this.Usuario = new UserEntity();
    this.CartList = [];
    this.CartData = new CartDataEntity();
  }
}

export class CartDataEntity {
  TotalAmount: number;
  TotalQuantity: number;
}
