<div class="container">
  <div class="row">
    <div class="col-12">
      <app-section-title [title]="'Destacados'" class="section-title"></app-section-title>
    </div>
    <div class="col-12">
      <app-card-swiper *ngIf="boxList.Data?.length" [boxList]="boxList.Data"></app-card-swiper>
    </div>
  </div>
</div>
