<div class="container">
  <div class="row">
    <div class="col-sm-10 col-md-8 mx-auto">
      <div class="form-container p-3 soft-shadow">
        <div class="header soft-shadow">
          <p class="px-2 m-1">Usted va a realizar el pago de <strong>{{ payment.Monto | gmCurrency }}</strong>
            con número de pedido <strong>{{ payment.NroVenta }}</strong>
          </p>
          <mat-divider class="w-90 mx-auto"></mat-divider>
        </div>

        <mat-horizontal-stepper [linear]="true" #stepper>
          <mat-step label="Mis datos" [editable]="stepper.selectedIndex < 2">
            <app-datos-personales [mercadopagoObj]="mercadopagoObj" [doctypesList]="doctypesList"></app-datos-personales>
          </mat-step>
          <!-- Fin Primer Step -->
          <mat-step label="Datos tarjeta">
            <app-datos-tarjeta [mercadopagoObj]="mercadopagoObj" [showIssuer]="showIssuer"
            [issuers]="issuers" [showCuotas]="showCuotas" [cuotasLst]="cuotasLst"
            [gif]="gif" [amount]="amount" [paymentMethodId]="paymentMethodId"
            (guessPaymentMethodEmit)="guessPaymentMethod()" (pagarEmit)="confirmarPago()"></app-datos-tarjeta>
          </mat-step>
          <!-- Fin Segundo Step -->

        </mat-horizontal-stepper>

        <div class="w-100 position-relative justify-content-end footer-container">
          <button mat-button (click)="stepper.previous()">Volver</button>
          <button *ngIf="stepper.selectedIndex == 0" mat-button (click)="stepper.next()">Siguiente</button>
        </div>
      </div>
    </div>
  </div>
</div>
