<mat-horizontal-stepper [linear]="true"  #stepper (selectionChange)="getStepperIndex($event)">
  <mat-step label="Mi carrito" [editable]="stepper.selectedIndex < 2">
    <app-step-mi-carrito></app-step-mi-carrito>
  </mat-step>
  <!-- Fin Primer Step -->
  <mat-step label="Revisar compra" [completed]="isTermsChecked" [editable]="stepper.selectedIndex < 2">
    <app-step-detalle [totalAmount]="totalAmount"
    (termsCheckedEmit)="isTermsChecked = $event" (goForwardEmit)="goForward($event)"></app-step-detalle>
  </mat-step>
  <!-- Fin Segundo Step -->
  <mat-step label="Compra procesada">
    <app-step-compra-procesada></app-step-compra-procesada>
  </mat-step>
</mat-horizontal-stepper>
