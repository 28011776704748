<mat-sidenav-container fxFlexFill>
  <mat-sidenav #sidenav role="navigation" fxLayout="column" mode="over" opened="false" fxHide.gt-sm="true">
    <app-mobile-navbar (sidenavToggle)="sidenav.close()"></app-mobile-navbar>
  </mat-sidenav>
  <mat-sidenav-content>
    <app-desktop-navbar (sidenavToggle)="sidenav.toggle()"></app-desktop-navbar>
    <main class="site-content">
      <router-outlet></router-outlet>
    </main>
    <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
