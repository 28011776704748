import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MobileFilterEntity } from 'src/app/models/mobile-filter.entity';

@Component({
  selector: 'app-modal-filtro-productos',
  templateUrl: './modal-filtro-productos.component.html',
  styleUrls: ['./modal-filtro-productos.component.scss']
})
export class ModalFiltroProductosComponent implements OnInit {
  filters: MobileFilterEntity[] = []; // OBJETO PARA GUARDAR TODOS LOS FILTROS SELECCIONADOS
  priceItemModel: any; // VARIABLE PARA CAPTURAR EL OBJETO DEL FILTRO POR PRECIO

  filterLists = {
    priceList: [],
    categoryList: []
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public injectedData: any,
    private dialogRef: MatDialogRef<ModalFiltroProductosComponent>
  ) {
    this.filterLists = this.injectedData.lists; // CAPTURO LAS LISTAS ENVIADAS DESDE EL COMPONENTE
    this.priceItemModel = this.filterLists.priceList.find(x => x.Checked).Id; // RADIOBUTTON PRESELECCIONADO
    this.setFiltersList(); // SI TENIA FILTROS APLICADOS PREVIAMENTE, LOS ASIGNO A LA LISTA DEL MODAL
  }

  ngOnInit() {
  }

  private setFiltersList() {
    if (this.filterLists.categoryList) {
      this.filterLists.categoryList.forEach((filterItem) => {
        if (filterItem.Checked) { // SI ESTA CHEQUEADO, LO AGREGO A LA LISTA DEL MODAL
          this.addFilterToList(false, filterItem, true);
        }
      });
    }
  }

  addFilterToList(isPriceFilter: boolean, item?, checked?) {
    if (checked || isPriceFilter) { // AGREGA EL ITEM A LA LISTA SI ES QUE ESTA SELECCIONADO
      // CREO EL OBJETO FILTRO PARA GUARDARLO EN LA LISTA
      let filtro = new MobileFilterEntity();
      filtro.Id = (!isPriceFilter) ? item.Id : this.priceItemModel;
      filtro.PriceFilter = isPriceFilter;
      filtro.Checked = checked;

      this.filters.push(filtro); // LO GUARDO EN LA LISTA
      if (isPriceFilter && this.filters.map(x => x.PriceFilter === true).length === 2) { // SI SE CAMBIO DE FILTRO DE PRECIO
        const index = this.filters.findIndex(x => x.Id !== this.priceItemModel && x.PriceFilter === true);
        if (index !== -1) {
          this.filters.splice(index, 1);
        }
      }
    } else { // ELIMINA EL ITEM DE LA LISTA CUANDO SE DESELECCIONA
      const index = this.filters.findIndex(x => x.Id === item.Id && x.PriceFilter === isPriceFilter);
      if (index !== -1) {
        this.filters.splice(index, 1);
      }
    }
  }

  cerrarModal(sendList: boolean) {
    this.dialogRef.close((sendList) ? this.filters : true);
  }

}
