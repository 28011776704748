import { DescuentoEntity } from './../../models/descuento.entity';
import { UserEntity } from 'src/app/models/user.entity';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, TimeoutError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  private readonly _url: string = 'http://127.0.0.1:8080/index.php';
  private readonly _apiUsuariosUrl = 'https://gmdisegnipruebas.minos.urltemporal.com/apis/Controller/UsuariosController.php?action=';

  protected getRequestHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return headers;
  }

  getOptions() {
    return { headers: this.getRequestHeaders, withCredentials: true };
  }


  handleError(error) {
    console.log(error);
    if (error instanceof TimeoutError) {
      return throwError('En este momento no podemos realizar la operación. Por favor intente nuevamente más tarde.');
    }
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(error);
  }

  constructor(private http: HttpClient) { }

  /*--------------------GET------------------------*/

  getUsuariosEndPoint(paramsInput?) {
    const params = new HttpParams()
      .set('param', (paramsInput) ? paramsInput : 0);
    const url = `${this._apiUsuariosUrl}getProductosDestacados`;

    return this.http.get(url, { headers: this.getRequestHeaders() }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  getUsuarioLoginService(user: UserEntity) {
    const url = `${this._apiUsuariosUrl}getUsuarioLogin`;
    const params = new HttpParams()
      .set('param', (user) ? JSON.stringify(user) : null);

    return this.http.get(url, { headers: this.getRequestHeaders(), params }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  getPedidosUsuariosService(id = null) {
    const url = `${this._apiUsuariosUrl}getPedidosPorUsuario`;
    const params = new HttpParams()
      .set('param', JSON.stringify(id));

    return this.http.get(url, { headers: this.getRequestHeaders(), params }).pipe(
      retry(1),
      catchError(error => {
        return this.handleError(error);
      }));
  }

  /*-----------------POST-------------------------*/

  postRegistrarUsuarioService(usuarioEntity: UserEntity) {
    const url = `${this._apiUsuariosUrl}registrarUsuario`;

    return this.http.post(url, JSON.stringify(usuarioEntity), { headers: this.getRequestHeaders() }).pipe(
      catchError(error => {
        return this.handleError(error);
      })
    );
  }

  updateDatosPersonalesService(usuarioEntity: UserEntity) {
    const url = `${this._apiUsuariosUrl}actualizarDatosUsuario`;

    return this.http.post(url, JSON.stringify(usuarioEntity), { headers: this.getRequestHeaders() }).pipe(
      catchError(error => {
        return this.handleError(error);
      })
    );
  }

  updatePasswordUsuarioService(usuarioEntity: UserEntity) {
    const url = `${this._apiUsuariosUrl}actualizarPasswordUsuario`;

    return this.http.post(url, JSON.stringify(usuarioEntity), { headers: this.getRequestHeaders() }).pipe(
      catchError(error => {
        return this.handleError(error);
      })
    );
  }



}
