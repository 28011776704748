import { Component, Input, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper/public-api';
import { BoxSliderEntity } from 'src/app/models/box-slider.entity';

@Component({
  selector: 'app-card-swiper',
  templateUrl: './card-swiper.component.html',
  styleUrls: ['./card-swiper.component.scss']
})
export class CardSwiperComponent implements OnInit {
  @Input() boxList: BoxSliderEntity;
  @Input() spaceBetween = 50;
  config: SwiperConfigInterface;

  constructor() {
    this.config = {
      slidesPerView: 'auto',
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      loopFillGroupWithBlank: false,
      grabCursor: true
    };
   }

  ngOnInit() {
    this.config.spaceBetween = this.spaceBetween;
  }

}
