import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

@Injectable({
  providedIn: 'root'
})
export class BottomSheetService {

  constructor(private _bottomSheet: MatBottomSheet) { }

  openBottomSheet(component) {
    this._bottomSheet.open(component);
  }

  dismissBottomSheet(reOpenSheet?, component?) {
    this._bottomSheet.dismiss();
    if (reOpenSheet) {
      this.openBottomSheet(component);
    }
  }

}
