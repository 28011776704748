import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { HttpLoaderComponent } from 'src/app/components/shared/http-loader/http-loader.component';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {
  isSnackLoaderEnabled = false;

  private static mensaje: string;
  private static icono: string;
  private static contenido: string[];

  public static obtenerMensaje() {
    return SnackBarService.mensaje;
  }

  public static obtenerIcono() {
    return SnackBarService.icono;
  }

  public static obtenerContenido() {
    return SnackBarService.contenido;
  }

  constructor(
    private _snackBar: MatSnackBar) {
  }

  openSnackBar(estilo: TipoAlerta, mensaje: string, tiempo?: number,
    horPos?: HorizontalPos, verPos?: VerticalPos, btnText?: string) {
    this._snackBar.open(mensaje, '' || btnText, {
      duration: tiempo,
      panelClass: ['snack-' + estilo.tipo],
      horizontalPosition: horPos.pos,
      verticalPosition: 'bottom' || verPos.pos,
    });
  }

  dismissSnackbar() {
    this.isSnackLoaderEnabled = false;
    this._snackBar.dismiss();
  }

  openLoaderSnackBar(panelClass?) {
    this._snackBar.openFromComponent(HttpLoaderComponent, {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: panelClass
    });
    this.isSnackLoaderEnabled = true;
  }

  checkSnackbarOpened() {
    return new Observable<boolean>((observer) => {
      observer.next(this.isSnackLoaderEnabled);
    });
  }
}

export interface TipoAlerta {
  tipo: 'danger' | 'warning' | 'success' | 'info' | 'primary' | 'alternative';
}

export interface HorizontalPos {
  pos: 'start' | 'center' | 'end' | 'left' | 'right';
}

export interface VerticalPos {
  pos: 'top' | 'bottom';
}
