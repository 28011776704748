import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FavoritoEntity } from 'src/app/models/favorito.entity';
import { UserStorage } from 'src/app/models/user-storage.entity';
import { ResultHelper } from 'src/app/models/Utils/result-helper.entity';
import { FavoritoService } from 'src/app/services/Endpoints/favorito.endpoint';
import { UsuarioService } from 'src/app/services/Endpoints/usuarios.endpoint';
import { RedirectorService } from 'src/app/services/Utils/redirector.service';
import { SnackBarService } from 'src/app/services/Utils/snackbar.service';
import { UserStorageService } from 'src/app/services/Utils/user-storage.service';

@Component({
  selector: 'app-mis-favoritos',
  templateUrl: './mis-favoritos.component.html',
  styleUrls: ['./mis-favoritos.component.scss']
})
export class MisFavoritosComponent implements OnInit {
  userStorage: UserStorage;
  userStorage$ = new Observable<UserStorage>();
  favoritoEntity: FavoritoEntity[] = [];

  constructor(
    private redirectorService: RedirectorService,
    private userStorageService: UserStorageService,
    private usuarioService: UsuarioService,
    private snackBarService: SnackBarService,
    private favoritoService: FavoritoService
  ) {
    this.userStorage$ = this.userStorageService.emitUserStorage;
    this.userStorage$.pipe().subscribe((res) => {
      if (res) {
        this.userStorage = res;
      }
    });
  }

  ngOnInit() {
    this.getFavoritos();
  }

  getFavoritos() {
    this.favoritoService.getFavoritosService(this.userStorage.Usuario.Id).subscribe((res: ResultHelper) => {
      if (res.Ok) {
        this.favoritoEntity = [...res.Result];
      } else {
        this.snackBarService.openSnackBar({ tipo: "danger" }, res.Mensaje, 5000, { pos: 'right' }, undefined, 'Ok');
      }
    },
      (error) => {
        this.snackBarService.openSnackBar({ tipo: "danger" }, error, 5000, { pos: 'right' }, undefined, 'Ok');
      });
  }

  removeFromFavorites(event) {
    const favorito = this.favoritoEntity.find(x => x.Producto === event);
    this.favoritoService.removerFavoritoService(favorito).subscribe((res: ResultHelper) => {
      if (res.Ok) {
        this.snackBarService.openSnackBar({ tipo: "success" }, 'Se quitó de favoritos', 3000, { pos: 'right' }, undefined, 'Ok');
        this.getFavoritos();
      } else {
        this.snackBarService.openSnackBar({ tipo: "danger" }, res.Mensaje, 5000, { pos: 'right' }, undefined, 'Ok');
      }
    },
      (error) => {
        this.snackBarService.openSnackBar({ tipo: "danger" }, error, 5000, { pos: 'right' }, undefined, 'Ok');
      });
  }

}
