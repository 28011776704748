import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { CartEntity } from 'src/app/models/cart.entity';
import { Producto } from 'src/app/models/producto.entity';
import { UserStorage } from 'src/app/models/user-storage.entity';
import { ResultHelper } from 'src/app/models/Utils/result-helper.entity';
import { CarritoService } from 'src/app/services/Endpoints/carrito.endpoint';
import { BottomSheetService } from 'src/app/services/Utils/bottom-sheet.service';
import { RedirectorService } from 'src/app/services/Utils/redirector.service';
import { SnackBarService } from 'src/app/services/Utils/snackbar.service';
import { UserStorageService } from 'src/app/services/Utils/user-storage.service';
import { ModalAgregarCantidadComponent } from '../modal-agregar-cantidad/modal-agregar-cantidad.component';

@Component({
  selector: 'app-step-mi-carrito',
  templateUrl: './step-mi-carrito.component.html',
  styleUrls: ['./step-mi-carrito.component.scss']
})
export class StepMiCarritoComponent implements OnInit {
  userStorage: UserStorage;
  isLogged = false;
  cartColumns: string[] = [
    'producto',
    'cantidad',
    'precio',
    'subtotal',
    'accion'
  ];

  menuElimiarProducto = false;
  userStorage$ = new Observable<UserStorage>();
  dataSource = new MatTableDataSource<CartEntity>([]);

  constructor(
    private redirectorService: RedirectorService,
    private userStorageService: UserStorageService,
    private bottomSheetService: BottomSheetService,
    private dialog: MatDialog,
    private carritoService: CarritoService,
    private snackBarService: SnackBarService,
    private changeDetectorRefs: ChangeDetectorRef) {
    this.userStorage$ = this.userStorageService.emitUserStorage;
    this.userStorage$.subscribe((res: UserStorage) => {
      if (res) {
        this.userStorage = res;
        this.dataSource.data = [...this.userStorage.CartList];
      }
    });
  }

  ngOnInit() {
  }

  toggleMenuOpened() {
    this.menuElimiarProducto = !this.menuElimiarProducto;
  }

  deleteProductFromCart(cartItem: CartEntity) {
    this.carritoService.deleteProductFromCartService(cartItem).pipe(take(1)).subscribe((result: ResultHelper) => {
      if (result.Ok) {
        this.snackBarService.openSnackBar({ tipo: "success" }, 'Producto eliminado del carrito', 3000, { pos: 'right' }, undefined, 'Ok');
        this.userStorageService.removeProductFromCart(cartItem);
        if (!this.userStorage.CartList.length) {
          this.redirectorService.redirectToInternalPage('productos');
        }
      } else {
        this.snackBarService.openSnackBar({ tipo: "danger" }, result.Mensaje, 3000, { pos: 'right' }, undefined, 'Ok');
      }
    },
      (error) => {
        this.snackBarService.openSnackBar({ tipo: "danger" }, error, 3000, { pos: 'right' }, undefined, 'Ok');
      });
  }

  addQuantityToProduct(item: CartEntity) {
    const dialogRef = this.dialog.open(ModalAgregarCantidadComponent, {
      width: '350px',
      panelClass: '',
      autoFocus: false,
      data: item
    });
  }

  setSubtotalPorProducto(itemProducto: Producto) {
    itemProducto.Subtotal = itemProducto.Price * itemProducto.Quantity;
    return itemProducto.Subtotal;
  }

}
