<div class="container">
  <div class="row">
    <div class="col p-5">
      <app-section-title [title]="'Preguntas Frecuentes'"></app-section-title>
      <mat-accordion class="d-block mt-4">
        <!-- PREGUNTA 1 -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              ¿Cómo debo hacer para comprar?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>This is the primary content of the panel.</p>
        </mat-expansion-panel>

        <!-- PREGUNTA 2 -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              ¿Cuáles son los medios para pagar?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            Los medios de pago disponibles en <strong>www.luzverdedietetica.com.ar</strong> son: <br>
            Usted puede pagar de manera personal de contado / efectivo en nuestro negocio, depósito bancario o,
            si así lo prefiere, elegir el medio de pago electrónico <strong> MercadoPago</strong>. <br>
            Con <strong> MercadoPago</strong> usted puede: Abonar con <i> tarjeta de crédito: Visa, Mastercard, American
              Express,
              Argencard, Cabal, Diners, Tarjeta Naranja y otras</i>.
            O en efectivo en puntos de pago: <strong> Pago Fácil, Link, Rapipago.</strong>
          </p>
          <button mat-stroked-button type="button" class="rounded gm-default">Ver medios de pago</button>
        </mat-expansion-panel>

        <!-- PREGUNTA 3 -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              ¿Cuáles son las formas de envío?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>Usted puede elegir el medio de envío que más le convenga o bien puede elegir el Retiro Personal en nuestro
            local ubicado en Av Colón 128, ciudad de Córdoba.</p>
        </mat-expansion-panel>

        <!-- PREGUNTA 4 -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              ¿Hacen envíos en la ciudad de Córdoba?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>Sí, dentro de la ciudad de Córdoba hacemos entrega de pedidos superiores a $3000.</p>
        </mat-expansion-panel>

        <!-- PREGUNTA 5 -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              ¿Hacen envíos dentro de la provincia de Córdoba?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>Sí, enviamos a algunas localidades del interior provincial a través de comisionistas o transportes, cuyo
            flete está a cargo del cliente.</p>
        </mat-expansion-panel>

        <!-- PREGUNTA 6 -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              ¿Entregan en otras provincias?
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>Sí, entregamos en toda la República Argentina, a través del transporte de su preferencia, cuyos costos en
            concepto de flete están a cargo del cliente.</p>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
