import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Barrio } from 'src/app/models/barrio.entity';
import { Localidad } from 'src/app/models/localidad.entity';
import { Provincia } from 'src/app/models/provincia.entity';
import { UserStorage } from 'src/app/models/user-storage.entity';
import { ResultHelper } from 'src/app/models/Utils/result-helper.entity';
import { GeneralService } from 'src/app/services/Endpoints/general.endpoint';
import { UsuarioService } from 'src/app/services/Endpoints/usuarios.endpoint';
import { RedirectorService } from 'src/app/services/Utils/redirector.service';
import { SnackBarService } from 'src/app/services/Utils/snackbar.service';
import { UserStorageService } from 'src/app/services/Utils/user-storage.service';

@Component({
  selector: 'app-actualiza-datos',
  templateUrl: './actualiza-datos.component.html',
  styleUrls: ['./actualiza-datos.component.scss']
})
export class ActualizaDatosComponent implements OnInit {
  userStorage: UserStorage;
  isLogged = false;
  //user = new UserEntity();
  // newUserData = new UserEntity();
  hidePass = true;
  provinciasList: Provincia[] = [];
  localidadesList: Localidad[] = [];
  barrioList: Barrio[] = [];
  isPassUpdateEnabled = false;
  userStorage$ = new Observable<UserStorage>();

  constructor(
    private redirectorService: RedirectorService,
    private userStorageService: UserStorageService,
    private usuarioService: UsuarioService,
    private snackBarService: SnackBarService,
    private changeRef: ChangeDetectorRef,
    private generalService: GeneralService
  ) {
    this.userStorage$ = this.userStorageService.emitUserStorage;
    this.userStorage$.pipe().subscribe((res) => {
      if (res) {
        this.userStorage = res;
      }
    });
  }

  ngOnInit() {
    // this.newUserData = this.user;
    this.getProvincias();
  }

  private getProvincias() {
    this.generalService.getProvinciasService().subscribe((result: ResultHelper) => {
      if (result.Ok) {
        this.provinciasList = result.Result;
      } else {
        this.snackBarService.openSnackBar({ tipo: "danger" }, result.toString(), 3000, { pos: 'right' }, undefined, 'Ok');
      }
    },
      (error) => { this.snackBarService.openSnackBar({ tipo: "danger" }, error, 3000, { pos: 'right' }, undefined, 'Ok'); });
  }

  getLocalidadesPorProvincia(idProvincia) {
    this.generalService.getLocalidadesPorProvinciaService(idProvincia).subscribe((result: ResultHelper) => {
      if (result.Ok) {
        this.localidadesList = result.Result;
      } else {
        this.snackBarService.openSnackBar({ tipo: "danger" }, result.toString(), 3000, { pos: 'right' }, undefined, 'Ok');
      }
    },
      (error) => {
        this.snackBarService.openSnackBar({ tipo: "danger" }, error, 3000, { pos: 'right' }, undefined, 'Ok');
      });
  }

  getBarrioPorLocalidad(idLocalidad) {
    this.generalService.getBarriosPorLocalidadService(idLocalidad).subscribe((result: ResultHelper) => {
      if (result.Ok) {
        this.localidadesList = result.Result;
      } else {
        this.snackBarService.openSnackBar({ tipo: "danger" }, result.toString(), 3000, { pos: 'right' }, undefined, 'Ok');
      }
    },
      (error) => {
        this.snackBarService.openSnackBar({ tipo: "danger" }, error, 3000, { pos: 'right' }, undefined, 'Ok');
      });
  }

  updateDatosPersonales() {
    if (this.validarModel()) {
      this.usuarioService.updateDatosPersonalesService(this.userStorage.Usuario).subscribe((result: ResultHelper) => {
        if (result.Ok) {
          this.snackBarService.openSnackBar({ tipo: "success" }, 'Datos actualizados correctamente', 3000, { pos: 'right' }, undefined, 'Ok');
          this.userStorageService.setUserStorage(this.userStorage); // GUARDA LOS DATOS DEL USUARIO EN EL SERVICIO FRONT
        } else {
          this.snackBarService.openSnackBar({ tipo: "danger" }, result.Mensaje, 3000, { pos: 'right' }, undefined, 'Ok');
        }
      },
        (error) => {
          this.snackBarService.openSnackBar({ tipo: "danger" }, error, 3000, { pos: 'right' }, undefined, 'Ok');
        });
    }
  }

  private validarModel() {
    if (this.userStorage.Usuario.IdProvincia && !this.userStorage.Usuario.IdLocalidad) {
      this.snackBarService.openSnackBar({ tipo: "danger" }, 'Debe seleccionar una localidad', 3000, { pos: 'right' }, undefined, 'Ok');
      return false;
    }
    return true;
  }

  updatePassword() {
    if (this.userStorage.Usuario.Password && this.userStorage.Usuario.NewPassword) {
      this.usuarioService.updatePasswordUsuarioService(this.userStorage.Usuario).subscribe((result: ResultHelper) => {
        if (result.Ok) {
          this.snackBarService.openSnackBar({ tipo: "success" }, 'Constraseña actualizada correctamente', 3000, { pos: 'right' }, undefined, 'Ok');
          this.userStorage.Usuario.Password = '';
          this.userStorage.Usuario.NewPassword = '';
        } else {
          this.snackBarService.openSnackBar({ tipo: "danger" }, result.Mensaje, 3000, { pos: 'right' }, undefined, 'Ok');
        }
      },
        (error) => {
          this.snackBarService.openSnackBar({ tipo: "danger" }, error, 3000, { pos: 'right' }, undefined, 'Ok');
        });
    }

  }

  verifyBtnSavePass() {
    this.isPassUpdateEnabled = Boolean(this.userStorage.Usuario.Password && this.userStorage.Usuario.NewPassword);
  }

}
