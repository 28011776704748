<div class="d-flex flex-wrap justify-content-center procesada-container">
  <h1 class="h1-procesada w-100 text-center">¡Gracias por realizar su pedido!</h1>
  <p class="p-content">Elija a continuación su forma de pago.</p>
  <p class="p-content">Si usted desea, puede abonar su compra de manera inmediata seleccionando
    nuestros medios de pago tarjeta de crédito, débito o efectivo.
  </p>
  <div class="w-100 card-container">
    <mat-card class="m-2 box-container">
      <mat-card-header class="justify-content-center">
        <div class="icon-title text-center">
          <mat-icon aria-hidden="false" aria-label="Nuestro negocio" class="icon">location_on</mat-icon>
          <p class="p-title">PAGO EN TIENDA</p>
        </div>
      </mat-card-header>
      <mat-card-content>
        <p class="p-box-content text-justify">En caso de NO elegir algún medio de pago electrónico, se puede
          dirigir de manera personal a nuestro negocio para realizar el pago y retirar su pedido.</p>
        <button mat-stroked-button class="rounded w-100">Visite nuestro negocio</button>
      </mat-card-content>
    </mat-card>
    <!-- Fin primera card -->
    <mat-card class="m-2 box-container">
      <mat-card-header class="justify-content-center">
        <div class="icon-title text-center">
          <i class="fas fa-money-check icon"></i>
          <p class="p-title">PAGO ELECTRÓNICO</p>
        </div>
      </mat-card-header>
      <mat-card-content>
        <p class="p-box-content text-justify p-pago-electronico">Aceptamos tarjetas de crédito y débito,
          pago con RapiPago y Pago Fácil.</p>
           <button #btnPagar mat-stroked-button class="rounded w-100" (click)="redirectToPago(btnPagar)">Pagar ahora</button>
      </mat-card-content>
    </mat-card>
  </div>
</div>
