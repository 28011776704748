import { Component, OnInit } from '@angular/core';
import { BoxSliderEntity } from 'src/app/models/box-slider.entity';
import { ResultHelper } from 'src/app/models/Utils/result-helper.entity';
import { ProductoService } from 'src/app/services/Endpoints/producto.endpoint';
import { SnackBarService } from 'src/app/services/Utils/snackbar.service';

@Component({
  selector: 'app-destacados',
  templateUrl: './destacados.component.html',
  styleUrls: ['./destacados.component.scss']
})
export class DestacadosComponent implements OnInit {
  boxList = new BoxSliderEntity();

  constructor(
    private snackBarService: SnackBarService,
    private productoService: ProductoService) {
    this.boxList.Data = [];
    this.getBoxSliderData();
  }

  ngOnInit() {
  }

  getBoxSliderData() {
    this.productoService.getProductosService().subscribe((result: ResultHelper) => {
      if (result.Ok) {
        this.boxList.Data = result.Result;
      } else {
        this.snackBarService.openSnackBar({ tipo: "danger" }, result.Mensaje, 3000, { pos: 'right' }, undefined, 'Ok');
      }
    },
      (error) => {
        this.snackBarService.openSnackBar({ tipo: "danger" }, error, 3000, { pos: 'right' }, undefined, 'Ok');
      })
  }

  getCardDataList() {
    this.boxList.Data = [
      {
        ImgUrl: './assets/images/index/imageBox.png',
        Title: 'Zapallo en almibar x400grs',
        Price: 206.8,
        Quantity: 0
      },
      {
        ImgUrl: './assets/images/index/imageBox.png',
        Title: 'Arrope de uva x 450grs',
        Price: 170,
        Quantity: 0
      },
      {
        ImgUrl: './assets/images/index/imageBox.png',
        Title: 'Harina panaderia 000 x 25kg',
        Price: 288,
        Quantity: 0
      },
      {
        ImgUrl: './assets/images/index/imageBox.png',
        Title: 'Harina panaderia 000 x 25kg',
        Price: 288,
        Quantity: 0
      },
      {
        ImgUrl: './assets/images/index/imageBox.png',
        Title: 'Harina panaderia 000 x 25kg',
        Price: 288,
        Quantity: 0
      },
      {
        ImgUrl: './assets/images/index/imageBox.png',
        Title: 'Harina panaderia 000 x 25kg',
        Price: 288,
        Quantity: 0
      },
      {
        ImgUrl: './assets/images/index/imageBox.png',
        Title: 'Harina panaderia 000 x 25kg',
        Price: 288,
        Quantity: 0
      },
      {
        ImgUrl: './assets/images/index/imageBox.png',
        Title: 'Harina panaderia 000 x 25kg',
        Price: 288,
        Quantity: 0
      }
    ];

  }

}
