import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { MercadopagoEntity, CuotasResponse, DocTypesResponse, PaymentBody } from 'src/app/models/mercadopago.entity';
import { Pedido } from 'src/app/models/pedido.entity';
import { UserStorage } from 'src/app/models/user-storage.entity';
import { ResultHelper } from 'src/app/models/Utils/result-helper.entity';
import { MercadopagoService } from 'src/app/services/Endpoints/mercadopago.endpoint';
import { RedirectorService } from 'src/app/services/Utils/redirector.service';
import { SnackBarService } from 'src/app/services/Utils/snackbar.service';
import { UserStorageService } from 'src/app/services/Utils/user-storage.service';
import { environment } from 'src/environments/environment';
import { ModalConfirmarPagoComponent } from './modal-confirmar-pago/modal-confirmar-pago.component';

@Component({
  selector: 'app-mercadopago',
  templateUrl: './mercadopago.component.html',
  styleUrls: ['./mercadopago.component.scss']
})
export class MercadopagoComponent implements OnInit {
  private mercadoPago;
  mercadopagoObj = new MercadopagoEntity();
  public showIssuer = false;
  public issuers: Array<string>;
  public cuotasLst: CuotasResponse[];
  public doctypesList: DocTypesResponse[];
  public issuerId: number = null;
  gif;
  showCuotas = false;
  paymentMethodId;
  amount = 0;

  userStorage$ = new Observable<UserStorage>();
  pedidoStorage$ = new Observable<Pedido>();
  /*---STORAGE DEL USUARIO---*/
  userStorage = new UserStorage();
  payment = new Pedido();
  descripcionCompra = 'producto nuevo';

  /*--------------*/

  public formSubmitted: boolean = false;
  public paymentSuccess: boolean;

  formBody: PaymentBody;

  constructor(private redirectorService: RedirectorService,
    private userStorageService: UserStorageService,
    private snackBarService: SnackBarService,
    private mercadopagoService: MercadopagoService,
    private dialog: MatDialog) {
    this.pedidoStorage$ = this.userStorageService.paymentBehavior;
    this.userStorage$ = this.userStorageService.emitUserStorage;
  }

  ngOnInit() {
    this.getObservablesValues();
    this.initMercadoPago();
  }

  private getObservablesValues() {
    this.userStorage$.subscribe((res: UserStorage) => {
      if (res) {
        this.userStorage = res;
      }
    });

    this.pedidoStorage$.subscribe((res: Pedido) => {
      if (res) {
        this.payment = res;
        this.amount = this.payment.Monto;
      }
    });

    // REDIRIGE A LA HOME SI NO CONTIENE DATOS PARA PAGAR
    if (!this.payment || !this.payment.NroVenta) {
      this.redirectorService.redirectToHomePage();
    }
  }

  private initMercadoPago() {
    if (window['Mercadopago']) {
      this.mercadoPago = window['Mercadopago'];
      this.mercadoPago.setPublishableKey(environment.settings.MPPublicKey);
      this.mercadoPago.getIdentificationTypes(this.getTiposDocumentos.bind(this)); // SE OBTIENEN LOS TIPOS DE DNI Y SE GUARDAN EN UNA LISTA
    }
  }

  private getTiposDocumentos(status, response) {
    this.doctypesList = response;
  }

  private getBin() {
    const bin = this.mercadopagoObj.CardValues.NumeroTarjeta.toString().replace(/[ .-]/g, '').slice(0, 6);
    return bin;
  };

  guessPaymentMethod() {
    // FORMATEO EL NUMERO DE LA TARJETA PARA OBTENER EL BIN
    let bin = this.getBin();
    setTimeout(() => {
      // SE OBTIENEN LOS TIPOS MEDIOS DE PAGO
      if (bin.length >= 6) {
        this.mercadoPago.getPaymentMethod({
          "bin": bin
        }, this.setPaymentMethod.bind(this));
      } else {
        this.gif = '';
      }
    }, 100);

  }

  setPaymentMethod(status, response) {
    if (status == 200) {
      // GUARDO LA RESPUESA DE LA API EN UN OBJETO RESPONSE
      this.mercadopagoObj.PaymentMethodResponse = response[0];

      // SI LA API DEVOLVIO CREDIT_CARD ENTONCES SE MUESRA EL DROPDOWN DE LAS CUOTAS
      this.showCuotas = this.mercadopagoObj.PaymentMethodResponse.payment_type_id === 'credit_card';

      // GUARDO LA IMAGEN DEL TIPO DE ENTIDAD PARA MOSTRAR EN LA PANTALLA
      this.gif = this.mercadopagoObj.PaymentMethodResponse.secure_thumbnail;

      // SI LA API DEVOLVIO ISSUER_ID MUESTRO LOS BANCOS EMISORES
      this.showIssuer = this.mercadopagoObj.PaymentMethodResponse.additional_info_needed.includes('issuer_id');

      if (this.showIssuer) {
        // SE OBTIENEN LOS BANCOS EMISORES
        this.mercadoPago.getIssuers(this.mercadopagoObj.PaymentMethodResponse.id, this.setBancos.bind(this));
      }

      // SE GUARDA EL TIPO DE ENTIDAD (VISA/MASTER...)
      if (!this.paymentMethodId) {
        this.paymentMethodId = this.mercadopagoObj.PaymentMethodResponse.id;
      }

      if (!this.showCuotas) {
        // SE OBTIENEN LAS CUOTAS SI ESTA HABILITADO EL CAMPO
        this.getCuotas(this.paymentMethodId, this.amount);
      }

    } else {
      this.snackBarService.openSnackBar({ tipo: "danger" }, 'Médodo de pago incorrecto', 3000, { pos: 'right' }, undefined, 'Ok');
      return false;
    }
  }

  setBancos(status, response) {
    this.issuers = response;
    if (this.showCuotas) {
      this.getCuotas(
        this.paymentMethodId,
        this.amount,
        this.mercadopagoObj.CardValues.BancoSeleccionado
      );
    }

  }

  getCuotas(paymentMethodId, amount, issuerId?) {
    this.mercadoPago.getInstallments(
      {
        "payment_method_id": paymentMethodId,
        "amount": amount,
        "issuer_id": this.issuerId ? parseInt(issuerId) : undefined
      }, this.setCuotas.bind(this));
  }

  setCuotas(status, response) {
    if (status == 200) {
      this.cuotasLst = response[0].payer_costs;
    } else {
      this.snackBarService.openSnackBar({ tipo: "danger" }, response, 3000, { pos: 'right' }, undefined, 'Ok');
    }
  }

  confirmarPago() {
    // SE REMUEVEN LOS ESPACIOS PARA OBTENER EL TOKEN
    const creditCardNumber = this.mercadopagoObj.CardValues.NumeroTarjeta.toString().replace(/\s/g, '') || '';

    // SE ARMA EL BODY PARA SOLICITAR EL TOKEN
    this.formBody = {
      cardholderName: this.mercadopagoObj.CardValues.NombreTitular,
      email: this.userStorage.Usuario.Email,
      docType: this.mercadopagoObj.PersonalValues.DocType,
      docNumber: this.mercadopagoObj.PersonalValues.DocNumber,
      cardExpirationMonth: this.mercadopagoObj.CardValues.ExpMonth,
      cardExpirationYear: this.mercadopagoObj.CardValues.ExpYear,
      cardNumber: creditCardNumber,
      securityCode: this.mercadopagoObj.CardValues.Cvv,
      issuer: this.mercadopagoObj.CardValues.BancoSeleccionado,
      installments: this.mercadopagoObj.CardValues.CuotaSeleccionada || '1',
      transactionAmount: this.amount,
      paymentMethodId: this.paymentMethodId,
      description: this.descripcionCompra
    };

    // SE VALIDA EL FORMULARIO PRIMERO
    if (this.validarFormulario(this.formBody)) {
      const dialogRef = this.dialog.open(ModalConfirmarPagoComponent, {
        width: '250px',
        panelClass: '',
        data: {
          NroVenta: this.payment.NroVenta
        }
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.pagar();
        }
      });
    }
  }

  pagar() {
    // SE VALIDA EL FORMULARIO PRIMERO
    if (this.validarFormulario(this.formBody)) {
      // SI SE OBTUBO CORRECTAMENTE EL TOKEN, ENVIO LOS DATOS AL BACK
      this.mercadoPago.createToken(this.formBody, this.sdkResponseHandler.bind(this));
    }
  }

  public sdkResponseHandler(status, response) {
    if (status != 200 && status != 201) {
      this.displayError(response);
    } else {
      const bodyRequest: PaymentBody = {
        transactionAmount: this.amount,
        token: response.id,
        description: this.descripcionCompra,
        installments: this.mercadopagoObj.CardValues.CuotaSeleccionada || '1',
        paymentMethodId: this.paymentMethodId,
        issuer_id: this.mercadopagoObj.CardValues.BancoSeleccionado || null,
        email: this.userStorage.Usuario.Email,
        docType: this.mercadopagoObj.PersonalValues.DocType,
        docNumber: this.mercadopagoObj.PersonalValues.DocNumber,
        nombre: this.userStorage.Usuario.Nombre,
        apellido: this.userStorage.Usuario.Apellido,
        external_reference: this.payment.NroVenta
      };

      this.mercadopagoService.realizarPagoService(bodyRequest).subscribe((result: ResultHelper) => {
        if (result.Ok) {
          this.snackBarService.openSnackBar({ tipo: "success" }, 'El pago se realizó correctamente. Muchas gracias por tu compra', 5000, { pos: 'right' }, undefined, 'Ok');
          this.mercadopagoObj = new MercadopagoEntity();
          this.formBody = {} as PaymentBody;
          setTimeout(() => {
            this.snackBarService.openSnackBar({ tipo: "alternative" }, 'Redirigiendo a la página principal', 4000, { pos: 'right' }, undefined, 'Ok');
          }, 3000);
          setTimeout(() => {
            this.redirectorService.redirectToHomePage();
          }, 4000);
        } else {
          if (!result.Result || (result.Result.status === 'rejected')) {
            this.snackBarService.openSnackBar({ tipo: "danger" }, result.Mensaje, 5000, { pos: 'right' }, undefined, 'Ok');
          }
        }
      },
        (error) => {
          this.snackBarService.openSnackBar({ tipo: "danger" }, error, 3000, { pos: 'right' }, undefined, 'Ok');
        });
    }
  };


  /*---------------VALIDACIONES----------------------*/

  displayError(response) {
    response.cause.forEach((itemError) => {
      const codeError = itemError.code;
      switch (codeError) {
        case '220':
          this.snackBarService.openSnackBar({ tipo: "danger" }, 'Debe seleccionar un banco emisor', 3000, { pos: 'right' }, undefined, 'Ok');
          break;
        case '221':
          this.snackBarService.openSnackBar({ tipo: "danger" }, 'Ingrese el nombre del titular de la tarjeta', 3000, { pos: 'right' }, undefined, 'Ok');
          break;
        case '224':
          this.snackBarService.openSnackBar({ tipo: "danger" }, 'Ingrese el código de seguridad de la tarjeta', 3000, { pos: 'right' }, undefined, 'Ok');
          break;
        case 'E301':
          this.snackBarService.openSnackBar({ tipo: "danger" }, 'Número de tarjeta inválido', 3000, { pos: 'right' }, undefined, 'Ok');
          break;
        case 'E302':
          this.snackBarService.openSnackBar({ tipo: "danger" }, 'Debe revisar el código de seguridad ingresado', 3000, { pos: 'right' }, undefined, 'Ok');
          break;
        case '316':
          this.snackBarService.openSnackBar({ tipo: "danger" }, 'Revise el nombre del titular de la tarjeta', 3000, { pos: 'right' }, undefined, 'Ok');
          break;
        case '322':
          this.snackBarService.openSnackBar({ tipo: "danger" }, 'El tipo de documento no es válido', 3000, { pos: 'right' }, undefined, 'Ok');
          break;
        case '324':
          this.snackBarService.openSnackBar({ tipo: "danger" }, 'El número de documento no es válido', 3000, { pos: 'right' }, undefined, 'Ok');
          break;
        case '325':
          this.snackBarService.openSnackBar({ tipo: "danger" }, 'El mes de vencimiento no es válido', 3000, { pos: 'right' }, undefined, 'Ok');
          break;
        case '326':
          this.snackBarService.openSnackBar({ tipo: "danger" }, 'El año de vencimiento no es válido', 3000, { pos: 'right' }, undefined, 'Ok');
          break;
        case 'default':
          this.snackBarService.openSnackBar({ tipo: "danger" }, 'Revise los datos ingresados', 3000, { pos: 'right' }, undefined, 'Ok');
          break;
        default:
          break;
      }
    });
  }

  validarFormulario(body: PaymentBody) {
    if (!body.cardholderName) {
      this.snackBarService.openSnackBar({ tipo: "danger" }, 'Ingrese nombre de titular válido', 3000, { pos: 'right' }, undefined, 'Ok');
      return false;
    }
    if (!body.email) {
      this.snackBarService.openSnackBar({ tipo: "danger" }, 'Ingrese dirección de email válido', 3000, { pos: 'right' }, undefined, 'Ok');
      return false;
    }
    if (!body.docType) {
      this.snackBarService.openSnackBar({ tipo: "danger" }, 'Debe seleccionar un tipo de documento', 3000, { pos: 'right' }, undefined, 'Ok');
      return false;
    }
    if (!body.docNumber) {
      this.snackBarService.openSnackBar({ tipo: "danger" }, 'Ingrese un número de dcumento', 3000, { pos: 'right' }, undefined, 'Ok');
      return false;
    }
    if (!body.cardExpirationMonth) {
      this.snackBarService.openSnackBar({ tipo: "danger" }, 'Ingrese el mes de vencimiento de la tarjeta', 3000, { pos: 'right' }, undefined, 'Ok');
      return false;
    }
    if (!body.cardExpirationYear) {
      this.snackBarService.openSnackBar({ tipo: "danger" }, 'Ingrese el año de vencimiento de la tarjeta', 3000, { pos: 'right' }, undefined, 'Ok');
      return false;
    }
    if (!body.cardNumber) {
      this.snackBarService.openSnackBar({ tipo: "danger" }, 'Ingrese el número de la tarjeta', 3000, { pos: 'right' }, undefined, 'Ok');
      return false;
    }
    if (!body.securityCode) {
      this.snackBarService.openSnackBar({ tipo: "danger" }, 'Ingrese el código de seguridad de la tarjeta', 3000, { pos: 'right' }, undefined, 'Ok');
      return false;
    }
    if (!body.securityCode) {
      this.snackBarService.openSnackBar({ tipo: "danger" }, 'Ingrese el código de seguridad de la tarjeta', 3000, { pos: 'right' }, undefined, 'Ok');
      return false;
    }
    return true;
  }

}
