export class MailDataEntity {
  Id?: number;
  IdCliente: number;
  NombreCliente: string;
  NroVenta: number;
  Fecha: Date;
  Email: string;
  Mensaje?: string;
  Tel?: number;
}
