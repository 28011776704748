<div class="container">
  <div class="row">
    <div class="col mx-auto mb-3">
      <app-section-title [title]="'Ofertas seleccionadas'" class="section-title"></app-section-title>
      <div class="card-deck pl-2 mx-auto">
        <app-card-producto *ngFor="let productItem of listProducts" [productoItem]="productItem"
        class="product-oferta"></app-card-producto>
      </div>
    </div>
  </div>
</div>
