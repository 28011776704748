import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-animated-arrow',
  templateUrl: './animated-arrow.component.html',
  styleUrls: ['./animated-arrow.component.scss']
})
export class AnimatedArrowComponent implements OnInit {
  @Input() animateIcon = false;

  constructor() { }

  ngOnInit() {
  }

}
