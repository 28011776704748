<div class="container">
  <div class="row">
    <div class="col-12">
      <app-section-title [title]="'Comprar por categorías'" class="section-title"></app-section-title>
    </div>
    <div class="col-12">
      <div class="box-content">
        <div class="content">
          <div class="img-container">
            <img [src]="imgCatDest" alt="" />
          </div>
          <div class="sub-container">
            <h2 class="h2-title">Categoría 1</h2>
            <button mat-stroked-button type="button" class="rounded gm-default">
              Ver más
            </button>
          </div>
        </div>

        <div class="slider-content">
          <app-card-swiper *ngIf="cardDataList?.length" [spaceBetween]="30" [boxList]="cardDataList"></app-card-swiper>
        </div>
      </div>
    </div>
  </div>

</div>
