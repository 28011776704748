import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { DescuentoEntity } from 'src/app/models/descuento.entity';
import { UserStorage } from 'src/app/models/user-storage.entity';
import { ResultHelper } from 'src/app/models/Utils/result-helper.entity';
import { CarritoService } from 'src/app/services/Endpoints/carrito.endpoint';
import { UsuarioService } from 'src/app/services/Endpoints/usuarios.endpoint';
import { RedirectorService } from 'src/app/services/Utils/redirector.service';
import { SnackBarService } from 'src/app/services/Utils/snackbar.service';
import { UserStorageService } from 'src/app/services/Utils/user-storage.service';
import { StepperCartComponent } from './stepper-cart/stepper-cart.component';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  @ViewChild('stepper') stepper: StepperCartComponent;
  userStorage: UserStorage;
  userStorage$ = new Observable<UserStorage>();
  isLogged = false;
  subtotal = 0;
  total = 0;
  porcentajeDescuento = 0;
  stepperIndex = 0;
  isNextStepEnabled = false;
  codigoDescuento: number;
  descuentoEntity = new DescuentoEntity();
  codigoSolicitado = false;

  constructor(
    private redirectorService: RedirectorService,
    private usuarioService: UsuarioService,
    private snackBarService: SnackBarService,
    private userStorageService: UserStorageService,
    private carritoService: CarritoService
  ) {
    this.userStorage$ = this.userStorageService.emitUserStorage;
    this.userStorageService.emitUserStorage.subscribe((res) => {
      if (res) {
        this.userStorage = res;
        this.subtotal = this.userStorage.CartData.TotalAmount; // CAPTURO EL TOTAL
        this.calcularTotal(); // SE CALCULAN LOS TOTALES PARA MOSTRARLOS EN EL CUADRO INFERIOR
        // SI EL USUARIO TIENE UN DESCUENTO APLICADO, LO PASO AL OBJETO
        if (this.userStorage.IdDescuento) {
          this.codigoSolicitado = true;
          this.descuentoEntity.Valor = this.userStorage.ValorDescuento;
        } else {
          this.codigoSolicitado = false;
          this.descuentoEntity = new DescuentoEntity();
        }
      }
    });
  }

  ngOnInit() {
  }

  getStepperIndex(event) {
    this.stepperIndex = event.selectedIndex;
  }

  goBack() {
    this.stepper.stepperRef.previous();
  }

  goForward() {
    this.stepper.stepperRef.next();
    this.userStorageService.setTotalVenta(this.total);
  }

  getNextEnabled(enabled) {
    this.isNextStepEnabled = enabled;
  }

  nextStepEnabled() {
    return this.stepperIndex > 0 && this.isNextStepEnabled;
  }

  calcularTotal() {
    this.total = this.subtotal - ((this.subtotal * (this.descuentoEntity.Valor | 0)) / 100);
  }

  aplicarDescuento() {
    this.descuentoEntity.IdCliente = this.userStorage.Usuario.Id;
    this.carritoService.postAplicarDescuentoVentaService(this.descuentoEntity).subscribe((result: ResultHelper) => {
      if (result.Ok) {
        this.descuentoEntity = result.Result;
        this.snackBarService.openSnackBar({ tipo: "success" }, 'Descuento aplicado correctamente', 3000, { pos: 'right' }, undefined, 'Ok');
        this.codigoSolicitado = true;
        this.calcularTotal();
        this.userStorage.IdDescuento = this.descuentoEntity.Id;
        this.userStorage.ValorDescuento = this.descuentoEntity.Valor;
        this.userStorageService.getEmitUserStorage();
      } else {
        this.snackBarService.openSnackBar({ tipo: "danger" }, result.Mensaje, 3000, { pos: 'right' }, undefined, 'Ok');
      }
    },
      (error) => { this.snackBarService.openSnackBar({ tipo: "danger" }, error, 3000, { pos: 'right' }, undefined, 'Ok'); });
  }
}
