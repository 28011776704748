import { Component, OnInit } from '@angular/core';
import { ImageEntity } from 'src/app/models/image.entity';

@Component({
  selector: 'app-banner-items',
  templateUrl: './banner-items.component.html',
  styleUrls: ['./banner-items.component.scss']
})
export class BannerItemsComponent implements OnInit {
  imageBoxList: ImageEntity[] = [
    {
      Url: './assets/images/index/oferta1.png',
      Alt: ''
    },
    {
      Url: './assets/images/index/oferta1.png',
      Alt: ''
    },
    {
      Url: './assets/images/index/oferta1.png',
      Alt: ''
    }
  ];

  constructor() { }

  ngOnInit() {
  }

}
