import { Injectable } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { Spinner } from 'ngx-spinner/lib/ngx-spinner.enum';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private spinnerOptions: Spinner = {
    type: 'ball-circus',
    size: "small",
    bdColor: "rgba(252,252,252,0.9)",
    color: "black",
    zIndex: 99999999
  };

  constructor(private spinner: NgxSpinnerService) {
   }

  showSpinner(spinnerName, fullscreen = true) {
    this.spinnerOptions.fullScreen = fullscreen;
    this.spinner.show(spinnerName, this.spinnerOptions);
  }

  closeSpinner(spinnerName) {
    setTimeout(() => {
      this.spinner.hide(spinnerName);
    }, 1000);
  }

}

export interface SpinnerTypes {
  tipoSpinner : 'line-scale-party';
}
