<div class="login-container m-2">
  <div class="header">
    <p class="p-title text-center">Ingresar a mi cuenta</p>
    <mat-divider class="mx-auto w-50"></mat-divider>
    <p class="p-subtitle w-100 m-3">Utilice la dirección de correo con la que se creó la cuenta</p>
  </div>

  <div class="form">
    <form [formGroup]="checkoutForm" (ngSubmit)="getLoginUsuario(checkoutForm.value)">
      <mat-form-field appearance="outline" class="d-block w-100">
        <mat-label>Correo</mat-label>
        <input matInput placeholder="" formControlName="Email">
        <mat-icon matSuffix>email</mat-icon>
      </mat-form-field>

      <mat-form-field appearance="outline" class="d-block w-100">
        <mat-label>Ingrese su contraseña</mat-label>
        <input matInput [type]="hidePass ? 'password' : 'text'" formControlName="Password">
        <button mat-icon-button matSuffix type="button" (click)="hidePass = !hidePass" [attr.aria-label]="'Ocultar contraseña'"
          [attr.aria-pressed]="hidePass">
          <mat-icon>{{hidePass ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>

      <button mat-stroked-button class="mx-auto w-60 rounded d-block" type="submit">Iniciar Sesión</button>
    </form>
    <p class="p-recuperar-pass text-center w-100 my-2">¿Te olvidaste la contraseña?</p>
    <p class="p-registro text-center w-100 my-2">Aún no estoy registrado, <strong
        (click)="openRegisterForm()">registrarme</strong>.</p>
  </div>
</div>
