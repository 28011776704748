import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UserStorage } from 'src/app/models/user-storage.entity';
import { UserStorageService } from 'src/app/services/Utils/user-storage.service';

@Component({
  selector: 'app-mis-datos',
  templateUrl: './mis-datos.component.html',
  styleUrls: ['./mis-datos.component.scss']
})
export class MisDatosComponent implements OnInit {
  userStorage: UserStorage;
  userStorage$ = new Observable<UserStorage>();

  constructor(
    private userStorageService: UserStorageService,
  ) {
    this.userStorage$ = this.userStorageService.emitUserStorage;
    this.userStorage$.pipe().subscribe((res) => {
      if (res) {
        this.userStorage = res;
      }
    });
  }

  ngOnInit() {
  }

}
