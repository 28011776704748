<mat-card class="soft-shadow">
  <div class="mobile-header-container">
    <div class="img-container">
      <img mat-card-image class="card-img-top img-fluid" [src]="'assets/images/products/'+productoItem.ImgUrl" alt="Imágen del producto">
    </div>
    <mat-card-content>
      <p class="w-100 card-product-title brand">{{ productoItem.NombreSubCat | titlecase }}</p>
      <p class="w-100 card-product-title product">{{ productoItem.Title | titlecase }}</p>
      <p class="card-text w-100 text-center font-weight-bold card-price">
       {{ productoItem.Price | gmCurrency }}
      </p>
    </mat-card-content>
  </div>

  <mat-card-actions>
    <div class="form-inline justify-content-center">
      <mat-form-field class="w-50" id="mat-form-id-{{productoItem.Id}}">
        <mat-label>Cantidad</mat-label>
        <input appOnlyNumber matInput [(ngModel)]="quantity" [id]="productoItem.Id" [name]="productoItem.Id">
      </mat-form-field>
      <div matTooltip="Inicie sesión para comprar" [matTooltipDisabled]="isLogged">
        <button mat-mini-fab aria-label="Add to cart" [disabled]="!isLogged || !quantity || (quantity && quantity == 0)"
          (click)="emitProductToCart()" [id]="productoItem.Id" [name]="productoItem.Id">
          <i class="fas fa-shopping-cart"></i>
        </button>
      </div>
      <div matTooltip="Inicie sesión para agregar a favoritos" [matTooltipDisabled]="isLogged" *ngIf="!fromFavoritos">
        <button class="ml-2" mat-mini-fab aria-label="Add to favorites" [disabled]="!isLogged"
          (click)="emitProductToFavorites()" [id]="productoItem.Id" [name]="productoItem.Id">
          <i class="fas fa-heart"></i>
        </button>
      </div>
      <div *ngIf="fromFavoritos" matTooltip="Quitar de favoritos">
        <button class="ml-2" mat-mini-fab aria-label="Remove from favorites" [disabled]="!isLogged"
          (click)="emiteRemoveFromFavorite()" [id]="productoItem.Id" [name]="productoItem.Id">
          <i class="fas fa-trash-alt"></i>
        </button>
      </div>
    </div>
  </mat-card-actions>
</mat-card>


