<app-header-title [title]="'Datos de la tarjeta'"></app-header-title>
<form card container=".card-container" card-width="200" class="form-container" *ngIf="formLoaded">
  <div class="card-container"></div>
  <div class="form-field-container px-3 mt-3" #formFieldContainer>
    <mat-form-field class="field-card-holder" appearance="outline" [floatLabel]="'always'">
      <mat-label>Titular de la tarjeta
        <span style="font-style: italic;">*como aparece en la tarjeta</span>
      </mat-label>
      <input matInput placeholder="" [(ngModel)]="mercadopagoObj.CardValues.NombreTitular" id="cardholderName"
       card-name name="nombreTitular" (input)="setJpCardHolderInvalidText()">
    </mat-form-field>

    <!-- PARA EVITAR ERROR CON LA FECHA DE VENCIMIENTO DE LA TARJETA -->
    <input type="hidden" card-expiry>
    <mat-form-field class="field-exp-month" appearance="outline" [floatLabel]="'always'">
      <mat-label>Mes expiración</mat-label>
      <input type="tel" [(ngModel)]="mercadopagoObj.CardValues.ExpMonth" matInput placeholder="MM"
        id="cardExpirationMonth" id="cardExpirationMonth" name="mesExpiracion"
        onselectstart="return false" onpaste="return false" oncopy="return false" oncut="return false"
        ondrag="return false" ondrop="return false" autocomplete=off maxlength="2" (input)="setCardExpiration()">
    </mat-form-field>

    <mat-form-field class="field-exp-year" appearance="outline" [floatLabel]="'always'">
      <mat-label>Año expiración</mat-label>
      <input matInput type="text" placeholder="YY" id="cardExpirationYear"
        [(ngModel)]="mercadopagoObj.CardValues.ExpYear" onselectstart="return false" name="anioExpiracion"
        onpaste="return false" onCopy="return false" onCut="return false" onDrag="return false" onDrop="return false"
        autocomplete=off maxlength="2" (input)="setCardExpiration()">
    </mat-form-field>
  </div>
  <div class="form-field-container px-3">
    <mat-form-field class="flex-one mr-2" appearance="outline" [floatLabel]="'always'">
      <input matInput placeholder="Número de tarjeta" id="cardNumber" name="nroTarjeta"
        onselectstart="return false" onpaste="return false" onCopy="return false" onCut="return false"
        onDrag="return false" onDrop="return false" autocomplete=off
        [(ngModel)]="mercadopagoObj.CardValues.NumeroTarjeta" (keyup)="guessPaymentMethod()" appOnlyNumber
        (input)="formatCreditCard($event)" maxlength="19" card-number>
    </mat-form-field>
    <mat-form-field class="field-cod-seguridad" appearance="outline" [floatLabel]="'always'">
      <input type="text" matInput placeholder="CVV" id="securityCode" name="cvv"
        onselectstart="return false" onpaste="return false" onCopy="return false" onCut="return false"
        onDrag="return false" onDrop="return false" autocomplete=off [(ngModel)]="mercadopagoObj.CardValues.Cvv"
        maxlength="3" appOnlyNumber card-cvc>
    </mat-form-field>
  </div>
  <div class="px-3 form-field-container" [ngClass]="{'d-none': !showIssuer, 'd-flex': showIssuer}">
    <mat-form-field class="flex-one" appearance="outline" [floatLabel]="'always'">
      <mat-label>Seleccione el banco</mat-label>
      <mat-select [(ngModel)]="mercadopagoObj.CardValues.BancoSeleccionado" name="bancoEmisor">
        <mat-option *ngFor="let issuer of issuers" [value]="issuer.id">{{issuer.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="px-3 form-field-container" [ngClass]="{'d-none': !showCuotas, 'd-flex': showCuotas}">
    <mat-form-field class="flex-one" appearance="outline" [floatLabel]="'always'">
      <mat-label>Cuotas</mat-label>
      <mat-select [(ngModel)]="mercadopagoObj.CardValues.CuotaSeleccionada" name="cuotaSeleccionada">
        <mat-option *ngFor="let cuotaItem of cuotasLst" [value]="cuotaItem.installments">
          {{ cuotaItem.recommended_message }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div><img [src]="gif" alt=""></div>
  <input type="hidden" name="transactionAmount" id="amount" [(ngModel)]="amount" />
  <input type="hidden" name="paymentMethodId" id="paymentMethodId" [(ngModel)]="paymentMethodId" />
  <input type="hidden" name="description" id="description" />

  <div class="d-flex px-3">
    <button mat-raised-button class="w-70 mx-auto" color="primary" (click)="pagar()">
      Pagar
    </button>
  </div>
</form>
