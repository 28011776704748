import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { getMiCuentaNavList } from 'src/app/const/mi-cuenta-nav.const';
import { NavEntity } from 'src/app/models/nav.entity';
import { UserStorage } from 'src/app/models/user-storage.entity';
import { UserEntity } from 'src/app/models/user.entity';
import { RedirectorService } from 'src/app/services/Utils/redirector.service';
import { SnackBarService } from 'src/app/services/Utils/snackbar.service';
import { UserStorageService } from 'src/app/services/Utils/user-storage.service';

@Component({
  selector: 'app-mi-cuenta',
  templateUrl: './mi-cuenta.component.html',
  styleUrls: ['./mi-cuenta.component.scss']
})
export class MiCuentaComponent implements OnInit {
  userStorage: UserStorage;
  isLogged = false;
  user: UserEntity = new UserEntity();
  typeMenuContent = 'a';
  miCuentaList: NavEntity[] = [];
  userStorage$ = new Observable<UserStorage>();

  constructor(
    private redirectorService: RedirectorService,
    private userStorageService: UserStorageService,
    private snackBarService: SnackBarService
  ) {
    this.userStorage$ = this.userStorageService.emitUserStorage;
    this.userStorage$.pipe().subscribe((res: UserStorage) => {
      if (res) {
        this.userStorage = res;
      }
    });

    if (!this.userStorage) {
      this.userStorage = this.userStorageService.getUserStorage;
    }
  }

  ngOnInit() {
    this.setNavItems();
  }

  showMenuContent(type) {
    this.typeMenuContent = type;
  }

  private setNavItems() {
    this.miCuentaList = getMiCuentaNavList();
  }

  cerrarSesion() {
    this.userStorageService.cerrarSesionService().subscribe((result) => {
      if (result) {
        this.snackBarService.openSnackBar({ tipo: "info" }, 'Sesión cerrada correctamente', 3000, { pos: 'right' }, undefined, 'Ok');
        this.redirectorService.redirectToHomePage();
      }
    });
  }

}
