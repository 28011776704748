import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MailDataEntity } from 'src/app/models/mail-data-entity';
import { SnackBarService } from 'src/app/services/Utils/snackbar.service';

@Component({
  selector: 'app-formulario',
  templateUrl: './formulario.component.html',
  styleUrls: ['./formulario.component.scss']
})
export class FormularioComponent implements OnInit {
  @Input() mail: MailDataEntity;
  @Output() sendMailEmitter = new EventEmitter();

  constructor(private snackBarService: SnackBarService) { }

  ngOnInit() {
  }

  sendMail(btnEnviar: MatButton) {
    if (this.validarMail()) {
      btnEnviar.disabled = true;
      this.sendMailEmitter.emit(btnEnviar);
    }
  }

  validarMail() {
    if (!this.mail.NombreCliente) {
      this.snackBarService.openSnackBar({ tipo: "danger" }, 'Ingrese un nombre de contacto', 3000, { pos: 'right' }, undefined, 'Ok');
      return false;
    }
    if (!this.mail.Email) {
      this.snackBarService.openSnackBar({ tipo: "danger" }, 'Ingrese un mail de contacto', 3000, { pos: 'right' }, undefined, 'Ok');
      return false;
    }
    if (!this.mail.Tel) {
      this.snackBarService.openSnackBar({ tipo: "danger" }, 'Ingrese un teléfono de contacto', 3000, { pos: 'right' }, undefined, 'Ok');
      return false;
    }
    if (!this.mail.Mensaje) {
      this.snackBarService.openSnackBar({ tipo: "danger" }, 'Ingrese un mensaje de contacto', 3000, { pos: 'right' }, undefined, 'Ok');
      return false;
    }
    return true;
  }

}
