<div class="container">
  <div class="row">
    <div class="col-12">
      <app-section-title [title]="'Pequeños gustos'" class="section-title"></app-section-title>
    </div>

    <div class="col-12">
      <app-card-swiper *ngIf="cardDataList?.length" [boxList]="cardDataList"></app-card-swiper>
    </div>
  </div>
</div>
