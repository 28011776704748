import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { UserStorage } from 'src/app/models/user-storage.entity';
import { UserEntity } from 'src/app/models/user.entity';
import { ResultHelper } from 'src/app/models/Utils/result-helper.entity';
import { UsuarioService } from 'src/app/services/Endpoints/usuarios.endpoint';
import { BottomSheetService } from 'src/app/services/Utils/bottom-sheet.service';
import { RedirectorService } from 'src/app/services/Utils/redirector.service';
import { SnackBarService } from 'src/app/services/Utils/snackbar.service';
import { UserStorageService } from 'src/app/services/Utils/user-storage.service';
import { LoginComponent } from '../login.component';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  userStorage: UserStorage;
  userStorage$ = new Observable<UserStorage>();
  isLogged = false;
  hidePass = true;
  empresa = 'Dietética Ser Natural'
  userEntity = new UserEntity();
  checkoutForm;

  constructor(
    private redirectorService: RedirectorService,
    private userStorageService: UserStorageService,
    private bottomSheetService: BottomSheetService,
    private snackBarService: SnackBarService,
    private usuariosService: UsuarioService,
    private formBuilder: FormBuilder
  ) {
    this.userStorage$ = this.userStorageService.emitUserStorage;
    this.userStorage$.pipe().subscribe((res) => {
      if (res) {
        this.userStorage = res;
        this.isLogged = this.userStorage.Usuario.IsLogged;
      }
    });

    this.checkoutForm = this.formBuilder.group(
      {
        Nombre: '',
        Apellido: '',
        Email: '',
        Password: '',
        RepeatPassword: ''
      }
    );
  }

  ngOnInit() {
  }

  openLoginForm() {
    this.bottomSheetService.dismissBottomSheet(true, LoginComponent);
  }

  postRegistrarUsuario(values) {
    this.userEntity.registerUser(values);
    if (this.validarDatos()) {
      this.userEntity.FechaRegistro = this.userEntity.getFechaRegistro();
      this.userEntity.TipoUsuario = 'C';
      this.usuariosService.postRegistrarUsuarioService(this.userEntity).subscribe((result: ResultHelper) => {
        if (result.Ok) {
          this.snackBarService.openSnackBar({ tipo: "success" }, 'Se registró correctamente', 3000, { pos: 'right' }, undefined, 'Ok');
          this.bottomSheetService.dismissBottomSheet(); // OCULTA EL BOTTOM SHEET DONDE ESTA EL FORM DE LOGUEO
          this.checkoutForm.reset();
        } else {
          this.snackBarService.openSnackBar({ tipo: "danger" }, result.Mensaje, 3000, { pos: 'right' }, undefined, 'Ok');
        }
      },
        (error) => { this.snackBarService.openSnackBar({ tipo: "danger" }, error, 3000, { pos: 'right' }, undefined, 'Ok'); });
    }
  }

  private validarDatos() {
    if (!this.userEntity.Nombre) {
      this.snackBarService.openSnackBar({ tipo: "danger" }, 'Debe ingresar un nombre válido', 3000, { pos: 'right' }, undefined, 'Ok');
      return false;
    }
    if (!this.userEntity.Apellido) {
      this.snackBarService.openSnackBar({ tipo: "danger" }, 'Debe ingresar un apellido válido', 3000, { pos: 'right' }, undefined, 'Ok');
      return false;
    }
    if (!this.userEntity.Apellido) {
      this.snackBarService.openSnackBar({ tipo: "danger" }, 'Debe ingresar un apellido válido', 3000, { pos: 'right' }, undefined, 'Ok');
      return false;
    }
    if (!this.userEntity.Email) {
      this.snackBarService.openSnackBar({ tipo: "danger" }, 'Debe ingresar un email válido', 3000, { pos: 'right' }, undefined, 'Ok');
      return false;
    }
    if (!this.userEntity.validarPassword()) {
      this.snackBarService.openSnackBar({ tipo: "danger" }, 'Las contraseñas no coinciden', 3000, { pos: 'right' }, undefined, 'Ok');
      return false;
    }
    return true;
  }

}
