<div class="register-container m-2">
  <div class="header">
    <p class="p-title text-center">Crear cuenta en {{ empresa }}</p>
    <mat-divider class="mx-auto w-50"></mat-divider>
    <p class="p-subtitle w-100 m-3">Para operar en {{ empresa }}, es necesario registrarse</p>
  </div>
  <mat-divider class="mx-auto w-70"></mat-divider>
  <div class="form">
    <form [formGroup]="checkoutForm" (ngSubmit)="postRegistrarUsuario(checkoutForm.value)">
      <p class="p-subtitle m-3">Datos personales</p>
      <mat-form-field appearance="outline" class="d-block w-100">
        <mat-label>Nombre</mat-label>
        <input matInput placeholder="" formControlName="Nombre">
        <mat-icon matSuffix>face</mat-icon>
      </mat-form-field>

      <mat-form-field appearance="outline" class="d-block w-100">
        <mat-label>Apellido</mat-label>
        <input matInput placeholder="" formControlName="Apellido">
        <mat-icon matSuffix>face</mat-icon>
      </mat-form-field>

      <mat-divider class="mx-auto w-70"></mat-divider>

      <p class="p-subtitle m-3">Datos cuenta</p>

      <mat-form-field appearance="outline" class="d-block w-100">
        <mat-label>Correo</mat-label>
        <input matInput placeholder="" formControlName="Email">
        <mat-icon matSuffix>email</mat-icon>
      </mat-form-field>

      <mat-form-field appearance="outline" class="d-block w-100">
        <mat-label>Ingrese su contraseña</mat-label>
        <input matInput [type]="hidePass ? 'password' : 'text'" formControlName="Password">
        <button mat-icon-button matSuffix type="button" (click)="hidePass = !hidePass"
          [attr.aria-label]="'Ocultar contraseña'" [attr.aria-pressed]="hidePass">
          <mat-icon>{{hidePass ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field appearance="outline" class="d-block w-100">
        <mat-label>Repetir contraseña</mat-label>
        <input matInput [type]="hidePass ? 'password' : 'text'" formControlName="RepeatPassword">
        <button mat-icon-button matSuffix type="button" (click)="hidePass = !hidePass"
          [attr.aria-label]="'Ocultar contraseña'" [attr.aria-pressed]="hidePass">
          <mat-icon>{{hidePass ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>

      <button mat-stroked-button class="mx-auto w-60 rounded d-block" type="submit">Crear Cuenta</button>
    </form>
    <p class="p-registro text-center w-100 my-2">Ya tengo cuenta, <strong (click)="openLoginForm()">iniciar
        sesión</strong>.</p>
  </div>
</div>
