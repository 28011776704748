<app-header-title [title]="'Mis Pedidos'"></app-header-title>
<div class="px-2 mx-auto position-relative mt-2">

  <!-- PROGRESS BAR -->
  <mat-progress-bar class="progress-bar" [mode]="'indeterminate'" *ngIf="!showPanels"></mat-progress-bar>

  <mat-accordion class="d-block" *ngIf="showPanels">
    <mat-expansion-panel class="mt-2 w-100 expansion-pagar" [disabled]="!mostrarNoPagados">
      <mat-expansion-panel-header>
        <mat-panel-title class="w-30">
          Pendientes de pago
        </mat-panel-title>
        <mat-panel-description>
          <p *ngIf="!mostrarNoPagados">
            No hay pedidos sin pagar
          </p>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-toolbar *ngFor="let itemPedido of pedidosPendientesLst, let i = index" class="p-0 mb-1">
        <div class="w-100 d-inline-flex list-box position-relative">
          <p>Pedido nº: <strong>{{ itemPedido.NroVenta }}</strong></p>
          <p>Modo de entrega: <strong>{{ itemPedido.ModoEntrega }}</strong></p>
          <p>Monto: <strong>{{ itemPedido.Monto | gmCurrency }}</strong></p>
          <button #btnPagar mat-raised-button class="rounded position-absolute"
            (click)="realizarPago(itemPedido, btnPagar);">Pagar</button>
        </div>
      </mat-toolbar>
    </mat-expansion-panel>

    <mat-expansion-panel class="mt-2 w-100 expansion-pagado" [disabled]="!mostrarPagados">
      <mat-expansion-panel-header>
        <mat-panel-title class="w-30">
          Pagados
        </mat-panel-title>
        <mat-panel-description>
          <p *ngIf="!mostrarPagados">
            No hay pedidos pagados
          </p>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <mat-toolbar *ngFor="let itemPedido of pedidosPagadosLst, let i = index" class="p-0 mb-1">
        <div class="w-100 d-inline-flex list-box position-relative">
          <p>Pedido nº: <strong>{{ itemPedido.NroVenta }}</strong></p>
          <p>Modo de entrega: <strong>{{ itemPedido.ModoEntrega }}</strong></p>
          <p>Monto: <strong>{{ itemPedido.Monto | gmCurrency }}</strong></p>
        </div>
      </mat-toolbar>
    </mat-expansion-panel>
  </mat-accordion>
</div>
