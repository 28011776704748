import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Categoria } from 'src/app/models/categoria.entity';
import { CmbSelectData, CmbSelectEntity } from 'src/app/models/cmb-select.entity';

@Component({
  selector: 'app-category-menu-desktop',
  templateUrl: './category-menu-desktop.component.html',
  styleUrls: ['./category-menu-desktop.component.scss']
})
export class CategoryMenuDesktopComponent implements OnInit {
  @Input() listCmbFiltro: CmbSelectEntity[] = [];
  @Input() categoryList: Categoria[] = [];
  // categoryList: ListCheckboxEntity[] = [];
  @Output() itemCategoryEmit = new EventEmitter();
  @Input() set setCategoryChecked(id) {
    if (id > 0) {
      this.categoryList.find(x => x.Id === id && x.Checked).Checked = false;
    }
  };

  cmbItemSelected: CmbSelectData;
  @Output() productSortEmitter = new EventEmitter<CmbSelectData>();

  constructor() { }

  ngOnInit() {
  }

  addCategoryToList(item: Categoria) {
    this.itemCategoryEmit.emit(item);
  }

  getCmbSelectedOption(item: CmbSelectData) {
    this.cmbItemSelected = item;
  }

  filtrarProductos() {
    this.productSortEmitter.emit(this.cmbItemSelected);
  }

}
