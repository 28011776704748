import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { CartEntity } from 'src/app/models/cart.entity';
import { Categoria } from 'src/app/models/categoria.entity';
import { ChipEntity } from 'src/app/models/chip.entity';
import { CmbSelectEntity } from 'src/app/models/cmb-select.entity';
import { Producto } from 'src/app/models/producto.entity';
import { UserStorage } from 'src/app/models/user-storage.entity';
import { ResultHelper } from 'src/app/models/Utils/result-helper.entity';
import { CarritoService } from 'src/app/services/Endpoints/carrito.endpoint';
import { ProductoService } from 'src/app/services/Endpoints/producto.endpoint';
import { SnackBarService } from 'src/app/services/Utils/snackbar.service';
import { SpinnerService } from 'src/app/services/Utils/spinner.service';
import { UserStorageService } from 'src/app/services/Utils/user-storage.service';

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.scss']
})
export class ProductosComponent implements OnInit {
  userStorage: UserStorage;
  isLogged = false;
  listProducts: Producto[] = []; // LISTA DE PRODUCTOS
  listProductsCopy: Producto[] = []; // LISTA DE PRODUCTOS
  listChipCategory: ChipEntity[] = []; // LISTA PARA LOS CHIPS QUE APARECEN COMO FILTROS SELECCIONADOS
  categoryId; // INDEX PARA EL MENU DE CATEGORIAS
  categoryListSelected: any[];
  userStorage$ = new Observable<UserStorage>();
  isListCopied = false;
  listCmbFiltro: CmbSelectEntity[] = [];
  categoryList: Categoria[] = [];

  constructor(
    private snackBarService: SnackBarService,
    private carritoService: CarritoService,
    private productoService: ProductoService,
    private spinnerService: SpinnerService,
    private userStorageService: UserStorageService) {
    this.userStorage$ = this.userStorageService.emitUserStorage;
    this.userStorage$.subscribe((userStorageResult: UserStorage) => {
      if (userStorageResult) {
        this.userStorage = userStorageResult;
        this.isLogged = this.userStorage.Usuario.IsLogged;
      }
    });
  }

  ngOnInit() {
    this.getProductList();
    this.getCategoriasList();
    this.getListFiltro();
  }

  private getProductList() {
    this.productoService.getProductosService().subscribe((result: ResultHelper) => {
      if (result.Ok) {
        result.Result.forEach((element: Producto) => {
          if (!element.ImgUrl) {
            element.ImgUrl = 'default.jpg';
          }
        });
        this.listProducts = [...result.Result];

        Object.assign(this.listProductsCopy, this.listProducts);
      } else {
        this.snackBarService.openSnackBar({ tipo: "danger" }, result.Mensaje, 3000, { pos: 'right' }, undefined, 'Ok');
      }
    },
      (error) => { this.snackBarService.openSnackBar({ tipo: "danger" }, error, 3000, { pos: 'right' }, undefined, 'Ok'); });
  }

  private getListFiltro() {
    this.listCmbFiltro = [
      {
        Id: 1,
        Label: 'Seleccione ordenamiento',
        Data: [
          {
            Id: 1,
            Detalle: 'Ascendente'
          },
          {
            Id: 2,
            Detalle: 'Descendente'
          }
        ]
      }
    ];
  }

  private getCategoriasList() {
    this.productoService.getCategoriasService().subscribe((result: ResultHelper) => {
      if (result.Ok) {
        this.categoryList = result.Result;
      } else {
        this.snackBarService.openSnackBar({ tipo: "danger" }, result.Mensaje, 3000, { pos: 'right' }, undefined, 'Ok');
      }
    },
      (error) => { this.snackBarService.openSnackBar({ tipo: "danger" }, error, 3000, { pos: 'right' }, undefined, 'Ok'); });
  }

  addCategoryToChips(item: Categoria) {
    let chipItem = new ChipEntity();
    chipItem.Id = item.Id;
    chipItem.Detalle = item.Nombre;

    const index = this.listChipCategory.findIndex(x => x.Id === chipItem.Id);
    if (index === -1 && item.Checked) {
      this.listChipCategory.push(chipItem);
    } else {
      this.removeCategoryFromChips(index);
    }
    this.filterItem();
  }

  removeCategoryFromChips(index) {
    this.listChipCategory.splice(index, 1);
    this.filterItem();
  }

  getIndexCategoryToRemove(id) {
    this.categoryId = id;
    this.removeCategoryFromChips(this.categoryId);
  }

  filterItem(isMobileFilterEmpty?: boolean) {
    this.spinnerService.showSpinner('spinnerProducts', false);
    if (!isMobileFilterEmpty && this.listChipCategory.length) {
      this.listProducts = this.listProductsCopy.filter(item => {
        const itemFind = this.listChipCategory.some(x => x.Id === item.IdCat);
        return itemFind;
      });
    } else {
      Object.assign(this.listProducts, this.listProductsCopy);
    }
    this.spinnerService.closeSpinner('spinnerProducts');
  }

  getProductoQuantity(productItem: Producto) {
    this.addToCart(productItem);
  }

  private addToCart(productoItem: Producto) {
    let cartProduct = new CartEntity();
    cartProduct.IdCliente = this.userStorage.Usuario.Id;
    cartProduct.IdProducto = productoItem.Id;
    cartProduct.NroVenta = this.userStorage.NroVenta;
    cartProduct.Fecha = new Date();
    cartProduct.Cantidad = productoItem.Quantity;
    cartProduct.Posicion = 1; // ACTUALIZAR DESPUES DE DEFINIR
    cartProduct.Producto = productoItem;
    this.userStorageService.addProductToCart(cartProduct).subscribe((result) => {
      cartProduct.ActualizaCantidad = false;
      if (result['Actualiza']) {
        cartProduct.ActualizaCantidad = true;
        cartProduct.Cantidad = result['Cantidad'];
        cartProduct.Id = result['IdCart'];
      }
      this.addDbProductToCart(cartProduct);
    });
  }

  private addDbProductToCart(cartProduct: CartEntity) {
    this.carritoService.postProductToCartService(cartProduct).subscribe((result: ResultHelper) => {
      if (result.Ok) {
        const mensaje = (cartProduct.ActualizaCantidad) ? 'Producto actualizado correctamente' : 'Producto agregado correctamente';
        this.snackBarService.openSnackBar({ tipo: "primary" }, mensaje, 3000, { pos: 'right' }, undefined, 'Ok');
        this.userStorageService.actualizaTotales(); // ACTUALIZO LOS VALORES TOTALES
        this.userStorageService.setNroVentaStorage(result.Result); // ACTUALIZO EL NRO DE VENTA
      } else {
        this.snackBarService.openSnackBar({ tipo: "danger" }, result.Mensaje, 3000, { pos: 'right' }, undefined, 'Ok');
      }
    },
      (error) => {
        this.snackBarService.openSnackBar({ tipo: "danger" }, error, 3000, { pos: 'right' }, undefined, 'Ok');
      });
  }

  filtrarProductosPorPrecio(idType) {
    this.spinnerService.showSpinner('spinnerProducts', false);
    if (this.listProducts.length > 1) {
      if (idType === 1) { // ASCENDENTE
        this.listProducts = [...this.listProducts.sort((a, b) => parseFloat(a.Price) - parseFloat(b.Price))];
      } else { // DESCENDENTE
        this.listProducts = [...this.listProducts.sort((a, b) => parseFloat(b.Price) - parseFloat(a.Price))];
      }
    }
    this.spinnerService.closeSpinner('spinnerProducts');
  }

  /**
   * SI VIENE DEL MODAL DE FILTROS PARA CELULAR:
   * SI NO TIENE FILTROS APLICADOS, se restablece la lista de productos.
   * SI TIENE FILTROS APLICADOS, SE LIMPIA LA LISTA DE "CHIPS" PARA
   * IR AGREGANDO LOS FILTROS A ESA LISTA
   */
  getMobileFilters(filters: Array<any>) { // FILTROS QUE VIENEN DEL MODAL MOBILE
    if (!filters.length) {
      this.filterItem(true);
    } else {
      this.listChipCategory = []; // SE LIMPIA LA LISTA DE FILTROS
      filters.forEach((filterItem) => {
        if (filterItem.PriceFilter) {
          this.filtrarProductosPorPrecio(filterItem.Id);
        } else {
          this.addCategoryToChips(filterItem);
        }
      });
    }
  }

}
