import { Component, OnInit } from '@angular/core';
import { BoxSliderDataEntity } from 'src/app/models/box-slider.entity';

@Component({
  selector: 'app-ofertas-productos',
  templateUrl: './ofertas-productos.component.html',
  styleUrls: ['./ofertas-productos.component.scss']
})
export class OfertasProductosComponent implements OnInit {
  listProducts: BoxSliderDataEntity[] = []; // LISTA DE PRODUCTOS

  constructor() { }

  ngOnInit() {
    this.getProductList();
  }

  private getProductList() {
    this.listProducts = [
      {
        Id: 1,
        Title: 'Alfajor de arroz - Blanco Promo 12 - X 24 Unidades',
        Price: 3485,
        ImgUrl: './assets/images/index/imageBox.png',
        Quantity: 0
      },
      {
        Id: 2,
        Title: 'ALMIDÓN DE MAÍZ 10 X 500 GRS',
        Price: 213,
        ImgUrl: './assets/images/index/imageBox.png',
        Quantity: 0
      },
      {
        Id: 3,
        Title: 'ALMIDÓN DE MAÍZ X 1 KG',
        Price: 556,
        ImgUrl: './assets/images/index/imageBox.png',
        Quantity: 0
      },
      {
        Id: 4,
        Title: 'ARITOS DE MIEL 8 X 130 GRS',
        Price: 98,
        ImgUrl: './assets/images/index/imageBox.png',
        Quantity: 0
      },
      {
        Id: 5,
        Title: 'ARITOS DE MIEL 8 X 130 GRS',
        Price: 98,
        ImgUrl: './assets/images/index/imageBox.png',
        Quantity: 0
      },
      {
        Id: 6,
        Title: 'ARITOS DE MIEL 8 X 130 GRS',
        Price: 98,
        ImgUrl: './assets/images/index/imageBox.png',
        Quantity: 0
      }
    ];
  }

}
