<h2 mat-dialog-title class="h2-title">Agregá más cantidad al producto</h2>

<mat-dialog-content class="mat-typography">
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Cantidad</mat-label>
    <input matInput placeholder="" [value]="quantity" [(ngModel)]="quantity">
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancelar</button>
  <button mat-stroked-button cdkFocusInitial (click)="agregarCantidadAProducto()" [disabled]="!quantity">Agregar</button>
</mat-dialog-actions>
