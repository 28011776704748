import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, TimeoutError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MailDataEntity } from 'src/app/models/mail-data-entity';

@Injectable({
  providedIn: 'root'
})
export class MailService {
  private readonly _apiMailUrl = 'https://gmdisegnipruebas.minos.urltemporal.com/apis/Controller/MailController.php?action=';


  protected getRequestHeaders(params?: any): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
      // 'Access-Control-Allow-Headers': 'Content-Type'
    });
    return headers;
  }


  private handleError(error: HttpErrorResponse) {
    if (error instanceof TimeoutError) {
      return throwError('En este momento no podemos realizar la operación. Por favor intente nuevamente más tarde.');
    }
    return throwError(error);
  }

  constructor(private http: HttpClient) { }

  /*----------------------------POST---------------------------*/

  sendPedidoMailService(mailDataEntity: MailDataEntity) {
    const url = `${this._apiMailUrl}sendPedidoMail`;

    return this.http.post(url, JSON.stringify(mailDataEntity), { headers: this.getRequestHeaders() }).pipe(
      catchError(error => {
        return this.handleError(error);
      })
    );
  }

  sendContactMailService(mailDataEntity: MailDataEntity) {
    const url = `${this._apiMailUrl}sendContactoMail`;

    return this.http.post(url, JSON.stringify(mailDataEntity), { headers: this.getRequestHeaders() }).pipe(
      catchError(error => {
        return this.handleError(error);
      })
    );
  }

}
