<div class="row mx-auto">
  <div class="col">
    <h2 class="h2-menu">SELECCIONE UN FILTRO</h2>
    <h3 class="h3-menu">Ordenar por precio</h3>
  </div>

  <div class="px-4 py-3 w-100">
    <app-cmb-dropdown [listCmb]="listCmbFiltro" (cmbSelectedEmit)="getCmbSelectedOption($event)"></app-cmb-dropdown>
    <button mat-stroked-button type="button" class="rounded gm-default" (click)="filtrarProductos()">Filtrar</button>
  </div>
</div>

<div class="row mx-auto">
  <div class="col">
    <h3 class="h3-menu">Ordenar por categoría</h3>
  </div>
  <div class="mx-auto w-100 px-1">
    <app-list-checkbox [itemList]="categoryList" (optionSelectedEmitter)="addCategoryToList($event)"></app-list-checkbox>
  </div>
</div>
