import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { MercadopagoEntity } from 'src/app/models/mercadopago.entity';

@Component({
  selector: 'app-datos-tarjeta',
  templateUrl: './datos-tarjeta.component.html',
  styleUrls: ['./datos-tarjeta.component.scss']
})
export class DatosTarjetaComponent implements OnInit, AfterViewInit {
  @Input() mercadopagoObj: MercadopagoEntity;
  @Input() showIssuer = true;
  @Input() gif;
  @Input() amount;
  @Input() paymentMethodId;
  @Input() issuers = Array;
  @Input() cuotasLst = Array;
  @Input() showCuotas;

  @Output() guessPaymentMethodEmit = new EventEmitter();
  @Output() pagarEmit = new EventEmitter();

  @ViewChild('formFieldContainer') formFieldContainer: ElementRef;
  cardWidth: number;
  formLoaded = false;
  cardExpiryEl;

  constructor(
    private renderer: Renderer2,
    private elRef: ElementRef) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.formLoaded = true;
      this.setCardWidth(); // BUSCA EL ESTILO DEL CONTENEDOR PARA ASIGNAR EL ANCHO DE LA CARD
    }, 1000);

  }

  guessPaymentMethod() {
    this.guessPaymentMethodEmit.emit();
  }

  pagar() {
    this.pagarEmit.emit();
  }

  formatCreditCard(e) {
    e.target.value = e.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
  }

  setCardWidth() {
    setTimeout(() => {
      const containerStyle = window.getComputedStyle(this.formFieldContainer.nativeElement);
      const containerDisplayType = containerStyle.display;
      this.setJpCardHolderText();
      this.setJpCardExpiryText();
      if (containerDisplayType != 'flex') {
        this.cardWidth = 100;
        this.setJpCardContainerStyle();
        this.setJpCardStyle();
      }
    }, 500);
  }

  private setJpCardContainerStyle() {
    const element = this.elRef.nativeElement.querySelector('.jp-card-container');
    this.renderer.setStyle(element, 'right', '12px');
    this.renderer.setStyle(element, 'height', '175px');
  }

  private setJpCardStyle() {
    const element = this.elRef.nativeElement.querySelector('.jp-card');
    this.renderer.setStyle(element, 'min-width', '280px');
  }

  private setJpCardHolderText() {
    const elName = this.elRef.nativeElement.querySelector('.jp-card-name');
    this.renderer.setProperty(elName, 'innerHTML', 'NOMBRE TITULAR');
  }

  setJpCardHolderInvalidText() {
    setTimeout(() => {
      const elNameInvalid = this.elRef.nativeElement.querySelector('.jp-card-invalid');
      if (elNameInvalid) {
        this.renderer.setProperty(elNameInvalid, 'innerHTML', 'NOMBRE TITULAR');
      }
    }, 200);
  }

  private setJpCardExpiryText() {
    this.cardExpiryEl = this.elRef.nativeElement.querySelector('.jp-card-expiry');
    this.renderer.setAttribute(this.cardExpiryEl, 'data-before', 'mes/año');
    this.renderer.setAttribute(this.cardExpiryEl, 'data-after', 'válida\r\nhasta');
  }

  setCardExpiration() {
    let expDate = (this.mercadopagoObj.CardValues.ExpMonth || '••') + '/' + (this.mercadopagoObj.CardValues.ExpYear || '••');
    if (!this.mercadopagoObj.CardValues.ExpMonth && !this.mercadopagoObj.CardValues.ExpYear) {
      expDate = '••/••';
    }
    this.renderer.setProperty(this.cardExpiryEl, 'innerHTML', expDate);
  }

}
