<section class="slider-images">
  <div class="bg">
    <app-banner-slideshow></app-banner-slideshow>
  </div>
</section>

<section class="promociones">
  <div class="bg">
    <app-banner-items></app-banner-items>
  </div>
</section>

<section class="destacados">
  <div class="bg">
    <app-destacados class="mt-3"></app-destacados>
  </div>
</section>

<section class="section-ofertas">
  <app-ofertas class="mt-3"></app-ofertas>
</section>

<section class="section-cat-destacada">
  <app-categoria-destacada class="mt-3"></app-categoria-destacada>
</section>

<section class="section-prod-detacados">
  <app-productos-destacados class="mt-3"></app-productos-destacados>
</section>

<section class="section-info-servicio">
  <app-info-servicio class="mt-3"></app-info-servicio>
</section>
