<div class="container">
  <div class="row">
    <div class="col p-4">
      <app-section-title [title]="'Términos y Condiciones'"></app-section-title>
      <mat-accordion class="d-block mt-4">
        <!-- ITEM 1 -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Condiciones generales
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            El presente documento describe los Términos y Condiciones (en adelante los Términos y Condiciones). Al
            acceder y utilizar el servicio dentro del sitio web <strong> www.luzverdedietetica.com.ar</strong> (en
            adelante el Sitio), el
            Usuario acepta y accede a los términos y disposiciones de este acuerdo de los servicios ofrecidos por Luz
            Verde Dietética. Por Usuario (en adelante el Usuario) se entiende a toda persona que ingrese al sitio y haga
            uso de este tanto para registrarse como para visitar el sitio por única vez.
            <br>
            Dirección del negocio: Av. Colón 128, Córdoba, Provincia de Córdoba.
            <br>
            Contacto: <strong>+ (54) 351 591-2539</strong>
          </p>
        </mat-expansion-panel>

        <!-- ITEM 2 -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Aceptación y conocimiento de los términos y condiciones
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            Toda participación en este servicio constituirá el conocimiento y la aceptación de este acuerdo. Si usted no
            está de acuerdo o no acepta cumplir con lo anterior, por favor, no lo utilice.
          </p>
          <button mat-stroked-button type="button" class="rounded gm-default">Ver medios de pago</button>
        </mat-expansion-panel>

        <!-- ITEM 3 -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Declaración de privacidad
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>El Sitio y uso del mismo implica recopilar y guardar información personal de los Usuarios, por eso se
            requiere que éstos sean verdaderos. Lo invitamos a leer nuestra <span class="link-span"
              (click)="redirect('politicas-privacidad')">Política de Privacidad</span> en la cual se
            establece la manera en qué se utilizará la información privada de un Usuario y cuándo, si acaso alguna vez
            sucede, la información se difundirá a otras personas.</p>
        </mat-expansion-panel>

        <!-- ITEM 4 -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Disponibilidad y precio de los productos ofrecidos
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>En la página web de Luz Verde Dietética, www.luzverdedietetica.com.ar, se ofrecen productos y servicios
            propios como de otras empresas. La información sobre productos y precios está sujeta a cambios sin previo
            aviso.<br>
            Todos los precios expresados en el Sitio incluyen IVA, salvo que se indique lo contrario.<br>
            Todos los precios en el Sitio están expresados en pesos argentinos, moneda de curso legal de la República
            Argentina.<br>
            Antes de comprar, el Usuario deberá tener en cuenta que los productos seleccionados pueden no encontrarse
            disponibles, dado que toda compra se encuentra sujeta a disponibilidad.<br>
            Asimismo, en el caso de que el o los productos seleccionados no se encuentren disponibles, Luz Verde
            Dietética se comunicará con el Usuario y lo invitará a que elija una de las siguientes opciones:
            <br>

            A) Esperar la entrega del producto elegido (en caso de demora);<br>

            B) Cancelación de la compra y devolución del importe por el medio de pago original;<br>

            C) Optar por un producto alternativo que le ofrezca Luz Verde Dietética para el caso particular;<br>

            D) Cancelación de la compra y emisión de una orden de compra por el mismo importe.<br>

            Cuando el Usuario opte por cancelar la compra, se devolverá el importe abonado según el medio de pago que se
            haya elegido oportunamente. En el caso de que el Usuario opte por el producto alternativo que le ofrece Luz
            Verde Dietética, éste deberá contener características iguales o superiores.<br>

            Importante: para las situaciones contempladas en este apartado, el Usuario tendrá un plazo de siete (7) días
            para elegir una de las opciones mencionadas. En caso de que el Usuario guarde silencio al respecto, Luz
            Verde Dietética podrá presumir que ha optado por la cancelación de la compra, y procederá a la devolución
            del importe abonado por el medio de pago original.</p>
        </mat-expansion-panel>

        <!-- ITEM 5 -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Devolución del importe abonado
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>En los casos mencionados en el punto anterior en que el Usuario haya optado por la devolución del importe
            abonado, deberá tener en cuenta que el reintegro puede demorar como máximo 30 días hábiles, debido a plazos
            y trámites administrativos. Para los casos de devolución vía depósito bancario, la cuenta bancaria deberá
            estar a nombre del titular de la cuenta de usuario desde donde se haya realizado la operación. En caso de
            que no coincidan las titularidades, se requerirá la expresa autorización del titular de la cuenta de usuario
            como condición indispensable previa al depósito</p>
        </mat-expansion-panel>

        <!-- ITEM 6 -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Cambios y/o devolución de los productos
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>El usuario tendrá derecho a devolver los productos adquiridos en el Sitio, durante el plazo de tres (3)
            días corridos, contados a partir de la entrega del producto en el domicilio indicado por el Usuario en el
            supuesto de los productos que hayan sufrido <strong>roturas o deterioros en tránsito</strong>. Para ello,
            deberá notificar a Luz Verde Dietética, dentro del plazo señalado su decisión de devolver el producto, y
            poner a disposición de
            Luz Verde Dietética el o los productos adquiridos. Los productos deberán estar en el mismo estado en que
            fueron recibidos por el Usuario, sin haber sido utilizados por éste, y con el embalaje original. Luz Verde
            Dietética devolverá al Usuario todos los importes abonados por el mismo dentro de las setenta y dos (72)
            horas de retirado el/los productos por Luz Verde Dietética.<br>

            En el supuesto de los productos que hayan sufrido roturas o deterioros en tránsito, éstos no deben haber
            sido utilizados por el Usuario, y deben encontrarse en las mismas condiciones en que fueron recibidos, con
            sus embalajes y etiquetas. Por ejemplo, a mero título ilustrativo, si el producto se encuentra en su
            envoltorio plástico ("blister), éste debe ser conservado en forma prolija y cuidadosamente, caso contrario
            no podrá efectuarse el cambio del mismo.<br>

            El producto podrá ser cambiado o el dinero reintegrado dentro del plazo señalado siempre y cuando el envase
            no haya sido abierto o alterado de ninguna manera.<br>

            Se podrán realizar cambios por fallas en el producto siempre y cuando el Usuario notifique al servicio de
            Atención al Cliente dentro del plazo estipulado y luego de pruebas técnicas se pueda comprobar la falla
            indicada por el Usuario.</p>
        </mat-expansion-panel>

        <!-- ITEM 7 -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Formas y medios de pago
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>El Usuario podrá abonar el importe total de su compra en contacto / efectivo en nuestro negocio (Av. Colón
            128, Córdoba) al momento de retirar la mercadería, o bien, puede seleccionar Pagar con Mercado Pago o
            Transferencia / Depósito Bancario. <br>
            Con Mercado Pago, usted puede abonar en contado / efectivo en Rapipago o en Pago Fácil, o elegir cualquier
            medio alternativo como tarjeta de crédito, débito o depósitos bancarios. Usted puede conocer cuáles son las
            tarjetas y bancos disponibles para operar con Mercado Pago.</p>
        </mat-expansion-panel>

        <!-- ITEM 8 -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Envío de productos dentro de la provincia de Córdoba. Tiempo de entrega
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>Las entregas se realizarán en la dirección que el Usuario tenga registrado en su cuenta. La validez de la
            misma es de su responsabilidad. <br>

            El tiempo de entrega depende de la disponibilidad del producto, del tiempo de envío y de la aprobación del
            medio de pago. Los envíos se realizan en toda la provincia de Córdoba, República Argentina.</p>
        </mat-expansion-panel>

        <!-- ITEM 9 -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Gastos de envío
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>Los envíos dentro de la ciudad de Córdoba y alrededores (Villa Allende, Mendiolaza, Unquillo, Río Ceballos,
            Carlos Paz, Cosquín, Salsipuedes, y otras localidades) son gratuitos para compras si superan los tres mil
            pesos (ARS $3000).<br>

            Para envíos dentro del interior del País, se podrá elegir el medio de transporte sugerido por Luz Verde
            Dietética o el Usuario podrá escoger el que más le satisfaga o le resulte cómodo en costos / tiempos de
            entrega del medio de transporte.</p>
        </mat-expansion-panel>

        <!-- ITEM 10 -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Horarios de entrega
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>Los productos serán entregados previa notificación de Luz Verde Dietética con el Usuario para coordinar el
            día y el horario de entrega de la mercadería adquirida.</p>
        </mat-expansion-panel>

        <!-- ITEM 11 -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Imágenes
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>Las imágenes asociadas a los productos que en el sitio www.luzverdedietetica.com.ar aparezcan, son
            meramente ilustrativas.</p>
        </mat-expansion-panel>

        <!-- ITEM 12 -->
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Puntos de venta y precios en el Sitio
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>Los precios de los puntos de venta que se sugieren para encontrar nuestros productos, no necesariamente
            coinciden con los precios exhibidos en el Sitio. El sitio NO SE HACE RESPONSABLE por la comercialización de
            nuestros productos y tampoco tiene influencia en los precios en dichos puntos de venta.</p>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
