import { CartEntity } from 'src/app/models/cart.entity';
import { BoxSliderDataEntity } from './box-slider.entity';

export class UserEntity {
  Id?: number;
  Nombre?: string;
  Apellido?: string;
  Dni?: number;
  Cuit?: string;
  Email?: string;
  Celular?: number;
  Domicilio?: string;
  NroDomicilio?: number;
  Barrio?: string;
  CodPostal?: number;
  Localidad?: string;
  IdProvincia?: number;
  FechaRegistro?: Date;
  Password?: string;
  RepeatPassword?: string;
  NewPassword?: string;
  IsLogged?: boolean;
  Data: UserData;
  TipoUsuario: string;
  Provincia: string;
  IdLocalidad: number;
  IdBarrio: number;

  constructor() {
  }

  validarPassword() {
    if (this.Password !== this.RepeatPassword) {
      return false;
    }
    return true;
  }

  getFechaRegistro() {
    return new Date();
  }

  registerUser(values: UserEntity) {
    this.Nombre = values.Nombre;
    this.Apellido = values.Apellido;
    this.Email = values.Email;
    this.Password = values.Password;
    this.RepeatPassword = values.RepeatPassword;
  }

  setUserMailPass(values: UserEntity) {
    this.Email = values.Email;
    this.Password = values.Password;
  }

}

class UserData {
  TotalAmount?: number;
  TotalQuantity?: number;
  NroVenta?: number;
}
