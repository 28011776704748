import { Component, OnInit } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MailDataEntity } from 'src/app/models/mail-data-entity';
import { ResultHelper } from 'src/app/models/Utils/result-helper.entity';
import { MailService } from 'src/app/services/Endpoints/mail.endpoint';
import { SnackBarService } from 'src/app/services/Utils/snackbar.service';
import { UserStorageService } from 'src/app/services/Utils/user-storage.service';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.scss']
})
export class ContactoComponent implements OnInit {
  mail = new MailDataEntity();

  constructor(
    private userStorageService: UserStorageService,
    private mailService: MailService,
    private snackBarService: SnackBarService) {
  }

  ngOnInit() {
  }

  sendMail(btnEnviar: MatButton) {
    this.mailService.sendContactMailService(this.mail).subscribe((result: ResultHelper) => {
      if (result.Ok) {
        this.snackBarService.openSnackBar({ tipo: "primary" }, 'Gracias por contactarte con nosotros. En breve responderemos tu consulta', 4000, { pos: 'right' }, undefined, 'Ok');
        this.mail = new MailDataEntity();
      } else {
        this.snackBarService.openSnackBar({ tipo: "danger" }, result.Mensaje, 3000, { pos: 'right' }, undefined, 'Ok');
      }
      btnEnviar.disabled = false;
    },
      (error) => {
        this.snackBarService.openSnackBar({ tipo: "danger" }, error, 3000, { pos: 'right' }, undefined, 'Ok');
        btnEnviar.disabled = false;
      });

  }

}
