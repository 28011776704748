<mat-accordion>
  <mat-expansion-panel #matExpansionPanel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Mi cuenta
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-selection-list [multiple]="false">
      <mat-list-option *ngFor="let itemMenu of miCuentaList" role="listitem" (click)="showMenuContent(itemMenu.Link)">
        <mat-icon>{{ itemMenu.Icon }}</mat-icon>
        <span>{{ itemMenu.Title }}</span>
      </mat-list-option>
    </mat-selection-list>
    <button mat-stroked-button (click)="cerrarSesion()" class="btn-cerrar-sesion">Cerrar Sesión</button>
  </mat-expansion-panel>
</mat-accordion>
