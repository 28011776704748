import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Categoria } from 'src/app/models/categoria.entity';
import { CmbSelectEntity } from 'src/app/models/cmb-select.entity';
import { MobileFilterEntity } from 'src/app/models/mobile-filter.entity';
import { UserStorage } from 'src/app/models/user-storage.entity';
import { UserStorageService } from 'src/app/services/Utils/user-storage.service';
import { ModalFiltroProductosComponent } from '../modal-filtro-productos/modal-filtro-productos.component';

@Component({
  selector: 'app-category-menu-mobile',
  templateUrl: './category-menu-mobile.component.html',
  styleUrls: ['./category-menu-mobile.component.scss']
})
export class CategoryMenuMobileComponent implements OnInit {
  @Input() cantResultados = 0;
  @Input() listCmbFiltro: CmbSelectEntity[] = [];
  @Input() categoryList: Categoria[] = [];
  @Output() filtersListEmitter = new EventEmitter();
  filtersList: MobileFilterEntity[] = [];

  @Input() set setCategoryChecked(id) {
    if (id > 0) {
      this.categoryList.find(x => x.Id === id && x.Checked).Checked = false;
    }
  };

  userStorage: UserStorage;
  userStorage$ = new Observable<UserStorage>();

  constructor(
    private userStorageService: UserStorageService,
    private dialog: MatDialog
  ) {
    this.userStorage$ = this.userStorageService.emitUserStorage;
    this.userStorage$.pipe().subscribe((userStorageResult: UserStorage) => {
      if (userStorageResult) {
        this.userStorage = userStorageResult;
      }
    });
  }

  ngOnInit() {
  }

  openModalFiltrar() {
    this.categoryList.map(x => x.Checked = false); // SETEO TODAS LAS CATEGORIAS CON EL CAMPO "CHECKED" EN FALSE
    if (this.filtersList.length) { // SI TENIAMOS FILTROS APLICADOS, LOS ASIGNO A LAS LISTAS QUE VAN A IR AL MODAL
      this.filtersList.forEach((filterItem) => {
        if (filterItem.PriceFilter) { // SI ES FILTRO POR PRECIO
          this.listCmbFiltro[0].Data.find(x => x.Id === filterItem.Id).Checked = true;
          this.listCmbFiltro[0].Data.find(x => x.Id !== filterItem.Id).Checked = false;
        } else { // SI ES FILTRO POR CATEGORIA
          this.categoryList.find(x => x.Id === filterItem.Id).Checked = true;
        }
      });
    } else {
      this.listCmbFiltro[0].Data[1].Checked = true; // POR DEFECTO LISTADO CON PRECIOS ASCENDENTE
    }

    const lists = {
      priceList: this.listCmbFiltro[0].Data,
      categoryList: this.categoryList
    }

    const dialogRef = this.dialog.open(ModalFiltroProductosComponent, {
      width: '350px',
      panelClass: 'dialog-mobile',
      autoFocus: false,
      data: {
        lists
      }
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result instanceof Array) { // EL MODAL ESTA ENVIANDO FILTROS (SE PRESIONO EL BOTON APLICAR)
        this.filtersList = [...result];
      }
      this.filtersListEmitter.emit(this.filtersList);
    });
  }

  getResultadoText() {
    return `${this.cantResultados} resultado${(this.cantResultados === 1) ? '' : 's'}`
  }

}
