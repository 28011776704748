<div class="container">
  <div class="row">
    <div class="col-12">
      <app-section-title [title]="'Te brindamos todas las comodidades'" class="section-title"></app-section-title>

      <div class="box-container" *ngFor="let item of lstBox">
        <div class="img-container">
          <img [src]="item.ImgUrl" alt="{{ item.Title }}" class="img-content-8">
        </div>
        <div class="content">
          <p><strong>{{ item.Title }}</strong></p>
          <p>{{ item.Description }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
