import { Directive, DoCheck, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appOnlyNumber]'
})
export class OnlyNumberDirective {
  // SE COMENTAN LAS SIGUIENTES LINEAS PARA NO PERDER SU GRAN VALOR SENTIMENTAL
  private el: HTMLInputElement;
  private isKeyPressed = false;
  // private supList = ['⁰', '¹', '²', '³', '⁴', '⁵', '⁶', '⁷', '⁸', '⁹'];
  // private supList = /[\u2070-\u209f\u00b0-\u00be]+/g;

  constructor(private elementRef: ElementRef) {
      this.el = this.elementRef.nativeElement;
  }

  /*() {
      // DOCHECK SOLO PARA CUANDO SE CARGA EL INPUT CON VALOR
      if (!this.isKeyPressed) {
          let value = this.el.value;
          if (!Number(value)) {
              return false;
          }
          // if (this.supList.test(value)) {
          //     value = this.splitNumber(value)[0] + this.supToNumber(this.splitNumber(value)[1]);
          // }
          if (value.includes('$')) {
              value = value.replace('$', '');
          }
          this.el.value = '$' + this.localeString(value);
      }
  }*/

  /*@HostListener('blur', ['$event']) blur(event) {
      // AL PERDER EL FOCO SOBRE INPUT, SE APLICA LA DIRECTIVA
      let value = event.target.value;
      if (!value) {
          return false;
      }
      if (value.includes('$')) {
          // SI EL VALOR YA TIENE EL SIMBOLO, LO ELIMINA
          value = this.el.value.replace('$', '');
          // if (this.supList.test(value)) {
          //     // VALIDA QUE TENGA SUPS
          //     // CONVIERTE EL SUP EN NRO NORMAL PARA LUEGO ARMAR
          //     // EL VALOR CON SEPARADOR DE DECIMALES CON LA COMA
          //     const sup = this.supToNumber(value.toString().slice(-2));
          //     value = value.split(this.supList)[0] + ',' + sup;
          // }
      }
      this.el.value = '$' + this.localeString(value);
      this.isKeyPressed = false;
  }*/

  /*@HostListener('ngModelChange', ['$event']) ngChange(event) {
      if (!this.isKeyPressed) {
          // AL PERDER EL FOCO SOBRE INPUT, SE APLICA LA DIRECTIVA
          let value = event;
          if (!value) {
              return false;
          }
          if (value.toString().includes('$')) {
              // SI EL VALOR YA TIENE EL SIMBOLO, LO ELIMINA
              value = value.toString().replace('$', '');
          }
          this.el.value = '$' + this.localeString(value);
      }
  }*/

  /*@HostListener('focus', ['$event']) focus(event) {
let value = event.target.value;
if (!value) {
  return false;
}
if (value.includes('$')) {
  // SI EL VALOR YA TIENE EL SIMBOLO, LO ELIMINA
  value = this.el.value.replace('$', '');
  // if (this.supList.test(value)) {
  //     const sup = this.supToNumber(value.toString().slice(-2));
  //     value = value.split(this.supList)[0] + ',' + sup;
  //     console.log(value);
  // }
}
this.el.value = '$' + this.splitNumber(value);
}*/

  @HostListener('keypress', ['$event']) keypress(event) {
      // HOSTLISTENER PARA EVITAR INGRESAR LETRAS
      this.isKeyPressed = true;
      const charCode = event.which ? event.which : event.keyCode;
      if (charCode !== 46 && charCode !== 44 && charCode > 31 && (charCode < 48 || charCode > 57)) {
          return false;
      }
  }

  @HostListener('keydown', ['$event']) keyDown(event) {
      // HOSTLISTENER PARA EVITAR INGRESAR LETRAS
      this.isKeyPressed = true;
      // const charCode = event.which ? event.which : event.keyCode;
      // if (charCode !== 46 && charCode !== 44 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      //   return false;
      // }
  }

  private splitNumber(num) {
      // SI SE INGRESÓ UNA COMA EN EL INPUT, LA REEMPLAZO POR EL PUNTO
      if (num.toString() && num.includes(',')) {
          num = Number(num.toString().replace(',', '.'));
      }

      const numberToFormat = (Math.round(num * 100) / 100).toFixed(2).split('.');
      const sup = numberToFormat[1];
      const integer = numberToFormat[0]
          .split('')
          .reverse()
          .join('')
          .replace(/(?=\d*\.?)(\d{3})/g, '$1.')
          .split('')
          .reverse()
          .join('')
          .replace(/^[\.]/, '');

      return integer + ',' + sup;
  }

  // superScript(num): string {
  //     // SI SE INGRESÓ UNA COMA EN EL INPUT, LA REEMPLAZO POR EL PUNTO
  //     if (num.toString() && num.includes(',')) {
  //         num = Number(num.toString().replace(',', '.'));
  //     }
  //     // SEPARO LA PARTE ENTERA DE LOS DECIMALES
  //     const numberToFormat = this.splitNumber(num);
  //     const sup = numberToFormat[1];
  //     const integer = numberToFormat[0]
  //         .split('')
  //         .reverse()
  //         .join('')
  //         .replace(/(?=\d*\.?)(\d{3})/g, '$1.')
  //         .split('')
  //         .reverse()
  //         .join('')
  //         .replace(/^[\.]/, '');

  //     // SE REEMPLAZAN LOS DECIMALES PARA GENERAR EL SUP
  //     const stringReplaced = sup ? this.numberToSup(sup) : null;

  //     return stringReplaced ? integer + stringReplaced : integer;
  // }

  // superScriptToNumber(num): string {
  //     // SEPARO LA PARTE ENTERA DE LOS DECIMALES
  //     const splitNumber = num.split(',');
  //     const integer = splitNumber[0].toString().replace('.', '');
  //     const decimals = splitNumber[1];

  //     const stringReplaced = decimals ? decimals : null;

  //     return stringReplaced ? integer + ',' + stringReplaced : integer;
  // }

  // private numberToSup(supString) {
  //     return supString
  //         .replace(/0/g, '⁰')
  //         .replace(/1/g, '¹')
  //         .replace(/2/g, '²')
  //         .replace(/3/g, '³')
  //         .replace(/4/g, '⁴')
  //         .replace(/5/g, '⁵')
  //         .replace(/6/g, '⁶')
  //         .replace(/7/g, '⁷')
  //         .replace(/8/g, '⁸')
  //         .replace(/9/g, '⁹');
  // }

  // private supToNumber(supString) {
  //     return supString
  //         .replace(/⁰/g, '0')
  //         .replace(/¹/g, '1')
  //         .replace(/²/g, '2')
  //         .replace(/³/g, '3')
  //         .replace(/⁴/g, '4')
  //         .replace(/⁵/g, '5')
  //         .replace(/⁶/g, '6')
  //         .replace(/⁷/g, '7')
  //         .replace(/⁸/g, '8')
  //         .replace(/⁹/g, '9');
  // }

  localeString(nStr) {
      if (nStr === '') {
          return '';
      }
      let x;
      let x1;
      let x2;
      let rgx;
      let y1;
      let y2;
      nStr += '';
      x = nStr.split('.');
      x1 = x[0];
      x2 = x.length > 1 ? ',' + x[1] : '';
      rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
          x1 = x1.replace(rgx, '$1' + '.' + '$2');
      }

      if (x1.indexOf(',') !== -1) {
          y1 = x1.slice(x1.lastIndexOf(',')).replace(/\./g, '');

          y2 = x1.split(',');
          x = y2[0] + y1;
      } else {
          x = x1 + x2;
          if (this.missingOneDecimalCheck(x)) {
              return (x += '0');
          }
          if (this.missingAllDecimalsCheck(x)) {
              return (x += ',00');
          }
      }

      return x;
  }

  missingOneDecimalCheck(nStr) {
      nStr += '';
      const x = nStr.split(',')[1];
      if (x && x.length === 1) {
          return true;
      }
      return false;
  }

  missingAllDecimalsCheck(nStr) {
      nStr += '';
      const x = nStr.split(',')[1];
      if (!x) {
          return true;
      }
      return false;
  }
}

