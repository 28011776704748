export class Pedido {
  public Id: number;
  public Monto: number;
  public NroVenta: number;
  public ModoEntrega: string;
  public IdEstadoPago: number;
  public EstadoPago: string;
  public ImgUrl: string;
  public IdEstadoOperacion: number;
}
