import { Pedido } from './../../models/pedido.entity';
import { CartDataEntity, UserStorage } from './../../models/user-storage.entity';
import { Injectable } from '@angular/core';
import { CryptoService } from './crypto.service';
import { CarritoService } from '../Endpoints/carrito.endpoint';
import { SnackBarService } from './snackbar.service';
import { BehaviorSubject } from 'rxjs';
import { UserEntity } from 'src/app/models/user.entity';
import { ResultHelper } from 'src/app/models/Utils/result-helper.entity';
import { take, tap } from 'rxjs/operators';
import { CartEntity } from 'src/app/models/cart.entity';

@Injectable()
export class UserStorageService {
  private usuarioStorage: UserStorage;
  private encUserData: string;
  private payment: Pedido;
  emitUserStorage: BehaviorSubject<any>;
  paymentBehavior: BehaviorSubject<any>;

  constructor(private cryptoService: CryptoService,
    private carritoService: CarritoService,
    private snackBarService: SnackBarService) {
      this.emitUserStorage = new BehaviorSubject<any>(this.usuarioStorage);
      this.paymentBehavior = new BehaviorSubject<any>(this.payment);
  }

  getEmitUserStorage() {
    this.emitUserStorage.next(this.usuarioStorage);
  }
  /* LOGIN */

  /**
   * SE DEVUELVE EL OBJETO USUARIO AL COMPONENTE
   */
  get getUserStorage() {
    return this.usuarioStorage;
  }

  /**
   * SE GUARDA EL OBJETO USUARIO EN EL SERVICIO LOCAL
   */
  public setUserStorage(userStorage: UserStorage) {
    this.usuarioStorage = userStorage;
    this.actualizaTotales();
    this.getEmitUserStorage();
  }

  /**
   * REQUEST PARA OBTENER EL NROVENTA DEL USUARIO
   */
  findNroVenta() {
    const obResult = new BehaviorSubject(0);
    this.carritoService.findNroVentaService(this.usuarioStorage.Usuario.Id).subscribe((result: ResultHelper) => {
      if (this.usuarioStorage.Usuario.IsLogged && result.Result) {
        this.usuarioStorage.NroVenta = result.Result;
        obResult.next(result.Result);
      } else {
        this.snackBarService.openSnackBar({ tipo: "danger" }, result.Mensaje, 3000, { pos: 'right' }, undefined, 'Ok');
      }
    }, (error) => {
      this.snackBarService.openSnackBar({ tipo: "danger" }, error, 3000, { pos: 'right' }, undefined, 'Ok');
    });
    return obResult;
  }

  /**
  * SE GUARDA EL NROVENTA USUARIO EN EL SERVICIO LOCAL
  */
  setNroVentaStorage(nroVenta: number) {
    this.usuarioStorage.NroVenta = nroVenta;
    this.getEmitUserStorage();
  }

  /**
   * ACTUALIZA LOS VALORES TOTALES DEL CARRITO
   */
  actualizaTotales() {
    this.cartTotal();
    this.cartQuantity();
    // this.emitUserStorageData();
  }

  /**
   * CIERRA SESION Y LIMPIA OBJETOS
   */
  cerrarSesionService() {
    const obResult = new BehaviorSubject(false);
    if (localStorage.getItem('userValues')) {
      this.usuarioStorage = new UserStorage();
      localStorage.removeItem('userValues');
      // LO GUARDO EN EL OBSERVABLE
      this.getEmitUserStorage();
      obResult.next(true);
    }
    return obResult;
  }

  /**
   * ENCRIPTO LOS DATOS DE USUARIO Y LOS GUARDO EN EL LOCALSTORAGE
   */
  setLocalStorageUser() {
    // ENCRIPTO EL OBJETO USUARIO PARA LUEGO SETERARLO EN EL LOCALSTORAGE
    this.encUserData = this.cryptoService.encrypt('userValues', JSON.stringify(this.usuarioStorage.Usuario));
    localStorage.setItem('userValues', this.encUserData);
    // LO GUARDO EN EL OBSERVABLE
    // this.emitUserStorageData();
  }

  /**
   * SE AGREGAR/ACTUALIZA PRODUCTO DEL CARRITO DEL STORAGE FRONT
   */
  addProductToCart(cartItem: CartEntity) {
    const obResult = new BehaviorSubject({});
    const index = this.usuarioStorage.CartList.findIndex(x => x.IdProducto === cartItem.IdProducto && x.NroVenta === cartItem.NroVenta);
    if (index >= 0) {
      const actualProduct = this.usuarioStorage.CartList[index];
      actualProduct.Producto.Quantity = Number(actualProduct.Producto.Quantity) + Number(cartItem.Cantidad); // INCREMENTO LA CANTIDAD DEL MISMO PRODUCTO
      if (actualProduct.Producto.Quantity !== cartItem.Cantidad) {
        obResult.next({ 'Cantidad': actualProduct.Producto.Quantity, 'Actualiza': true, 'IdCart': actualProduct.Id });
      }
    } else if (index === -1) {
      const actualLength = this.usuarioStorage.CartList.length || 0;
      this.usuarioStorage.CartList.push(cartItem); // AGREGO UN PRODUCTO NUEVO
      if (actualLength !== this.usuarioStorage.CartList.length) {
        obResult.next({ 'Cantidad': cartItem.Producto.Quantity, 'Actualiza': false });
      }
    }
    //this.reloadData();
    return obResult;
  }

  /**
   * OBTIENE EL MONTO TOTAL DEL CARRITO
   */
  cartTotal() {
    this.usuarioStorage.CartData.TotalAmount = this.usuarioStorage.CartList.reduce((acc, cur) =>
      Number(acc) + (Number(cur.Producto.Price || 0) * Number(cur.Producto.Quantity || 0)), 0);
  }

  /**
   * OBTIENE LA CANTIDAD DE ITEMS DEL CARRITO
   */
  cartQuantity() {
    this.usuarioStorage.CartData.TotalQuantity = this.usuarioStorage.CartList.length;
  }

  dbToCartProducts() {

  }

  /**
   * OBTIENE LOS PRODUCTOS EXISTENES EN EL CARRITO
   */
  checkCarritoItems(returnValues: boolean) {
    const resultBehavior = new BehaviorSubject([]);
    this.carritoService.getItemsCarrito(this.usuarioStorage.NroVenta).subscribe((result: ResultHelper) => {
      if (result.Ok) {
        if (returnValues) {
          resultBehavior.next(result.Result);
        } else {
          result.Result.forEach(cartElement => {
            this.addProductToCart(cartElement);
          });
        }

        // SI LA LISTA DEL CARRITO NO ESTA VACIA, SETEO LOS TOTALES
        if (this.usuarioStorage.CartList) {
          this.actualizaTotales();
        }
        // this.emitUserStorageData();
      } else {
        this.snackBarService.openSnackBar({ tipo: "danger" }, result.Mensaje, 3000, { pos: 'right' }, undefined, 'Ok');
      }
    },
      (error) => { this.snackBarService.openSnackBar({ tipo: "danger" }, error, 3000, { pos: 'right' }, undefined, 'Ok'); });
    return resultBehavior;
  }

  /**
   * BUSCA EL PRODUCTO EN EL LISTADO Y ACTUALIZA LA CANTIDAD
   * @param itemProduct
   */
  modificarCantidadProducto(itemProduct: CartEntity) {
    let productoItemList = this.usuarioStorage.CartList.find(x => x.Id === itemProduct.Id && x.NroVenta === itemProduct.NroVenta);
    productoItemList.Producto.Quantity = itemProduct.Cantidad;
    this.actualizaTotales();
    this.getEmitUserStorage();
  }

  /**
   * BUSCA EL ITEM CARRITO EN EL LISTADO Y LO REMUEVE DEL SERVICIO
   * @param cartItem
   */
  removeProductFromCart(cartItem: CartEntity) {
    const index = this.usuarioStorage.CartList.indexOf(cartItem);
    if (index !== -1) {
      this.usuarioStorage.CartList.splice(index, 1);
      this.actualizaTotales();
      this.getEmitUserStorage();
    }
  }

  cleanPedidoVenta() {
    this.usuarioStorage.CartData = new CartDataEntity();
    this.usuarioStorage.CartList = [];
    this.usuarioStorage.NroVenta = null;
    this.usuarioStorage.IdDescuento = null;
    this.usuarioStorage.ValorDescuento = null;
    this.actualizaTotales();
    this.getEmitUserStorage();
  }

  setTotalVenta(total) {
    this.usuarioStorage.TotalVenta = total;
    this.getEmitUserStorage();
  }

  setPaymentBehavior(payment) {
    this.payment = payment;
    this.emitPayment();
  }

  emitPayment() {
    this.paymentBehavior.next(this.payment);
  }

}
