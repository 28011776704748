import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';

export class MercadopagoEntity {
  PersonalValues: PersonalData;
  CardValues: CardData;
  PaymentMethodResponse: PaymentMethodResponse;
  CuotasResponse: CuotasResponse;

  constructor() {
    this.PersonalValues = new PersonalData();
    this.CardValues = new CardData();
  }

}

class PersonalData {
  NombreCompleto: string;
  Email: string;
  DocType: string;
  DocNumber: number;
}

class CardData {
  NombreTitular: string;
  ExpYear: string;
  ExpMonth: string;
  NumeroTarjeta: number;
  Cvv: string;
  BancoSeleccionado: number;
  CuotaSeleccionada: string;
}

export interface PaymentMethodResponse {
  accreditation_time: number;
  additional_info_needed: any[];
  deferred_capture: string;
  financial_institutions: any[];
  id: string;
  max_allowed_amount: number;
  min_allowed_amount: number;
  name: string;
  payment_type_id: string;
  processing_modes: any[];
  secure_thumbnail: string;
  settings: any[];
  status: string;
  thumbnail: string;
}

export interface CuotasResponse {
  discount_rate: number;
  installment_amount: number;
  installment_rate: number;
  installment_rate_collector: any[];
  installments: number;
  labels: any[];
  max_allowed_amount: number;
  min_allowed_amount: number;
  payment_method_option_id: string;
  recommended_message: string;
  reimbursement_rate: any;
  total_amount: number;
}

export interface MercadopagoBody {
  TransactionAmount: number;
  Token: any;
  Description: string;
  Cuotas: string;
  PaymentMethodId: string;
  BancoEmisorId: string;
  ExternalRef: any;
  Nombre: string;
  Apellido: string;
  Email: string;
}

export interface DocTypesResponse {
  id: string;
  max_length: number;
  min_length: number;
  name: string;
  type: string;
}

export interface PaymentBody {
  cardholderName?: string;
  email: string;
  docType: string;
  docNumber: number;
  cardExpirationMonth?: string;
  cardExpirationYear?: string;
  cardNumber?: string;
  securityCode?: string;
  issuer?: any;
  installments: string;
  transactionAmount: number;
  paymentMethodId: string;
  description: string;
  token?: any;
  issuer_id?: number;
  nombre?: string;
  apellido?: string;
  external_reference?: any;
}

export interface PaymentResponse {
  id: number;
  date_created: Date;
  date_approved: Date;
  date_last_updated: Date;
  date_of_expiration: Date;
  money_release_date: Date;
  operation_type: string;
  issuer_id: any;
  payment_method_id: string;
  payment_type_id: string;
  status: string;
  status_detail: string;
  currency_id: any;
  description: string;
  live_mode: boolean;
  sponsor_id: any;
  authorization_code: any;
  money_release_schema: any;
  taxes_amount: number;
  counter_currency: any;
  shipping_amount: any;
  pos_id: any;
  store_id: any;
  collector_id: any;
  totalAmount: number;
}
