<div class="container">
  <div class="row my-3">
    <div class="col-sm-12 col-lg-3 mobile-col">
      <div class="jumbotron p-3 h-100 position-relative desktop-display">
        <h1 class="m-0 text-center h1-title">Mi Cuenta</h1>
        <mat-divider></mat-divider>
        <p class="m-2 text-center p-user">Hola <strong>{{ ((userStorage$ | async)?.Usuario.Nombre || 'user') | titlecase }}</strong></p>
        <mat-divider class="w-50 mx-auto"></mat-divider>
        <mat-selection-list [multiple]="false">
          <mat-list-option *ngFor="let itemMenu of miCuentaList" role="listitem" (click)="showMenuContent(itemMenu.Link)">
            <mat-icon>{{ itemMenu.Icon }}</mat-icon>
            <span>{{ itemMenu.Title }}</span>
          </mat-list-option>
          <mat-divider></mat-divider>
        </mat-selection-list>
        <button mat-button (click)="cerrarSesion()" class="btn-cerrar-sesion">Cerrar Sesión</button>
      </div>
      <!-- MOBILE -->
      <app-nav-mi-cuenta-mobile class="mobile-display" [miCuentaList]="miCuentaList" (showMenuContentEmit)="showMenuContent($event)"
      (cerrarSesionEmit)="cerrarSesion()"></app-nav-mi-cuenta-mobile>
    </div>
    <div class="col">
      <div class="jumbotron p-3 h-100 position-relative mat-elevation-z2" [ngClass]="{'no-bg': typeMenuContent !== 'a'}">
        <app-mis-datos *ngIf="typeMenuContent === 'a'"></app-mis-datos>
        <app-mis-pedidos *ngIf="typeMenuContent === 'b'"></app-mis-pedidos>
        <app-mis-favoritos *ngIf="typeMenuContent === 'c'"></app-mis-favoritos>
        <app-actualiza-datos *ngIf="typeMenuContent === 'd'"></app-actualiza-datos>
      </div>
    </div>
  </div>
</div>
