import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, TimeoutError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductoService {

  private readonly _apiProductosUrl = 'https://gmdisegnipruebas.minos.urltemporal.com/apis/Controller/ProductosController.php?action=';

  protected getRequestHeaders(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
    return headers;
  }

  protected handleError(error) {
    if (error instanceof TimeoutError) {
      return throwError('En este momento no podemos realizar la operación. Por favor intente nuevamente más tarde.');
    }
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Status: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(error);
  }

  constructor(private http: HttpClient) { }

  /*--------------------GET------------------------*/

  getProductosService() {
    const url = `${this._apiProductosUrl}getProductos`;
    return this.http.get(url, { headers: this.getRequestHeaders() }).pipe(
      retry(1),
      catchError(error => {
        return this.handleError(error);
      }));
  }

  getCategoriasService() {
    const url = `${this._apiProductosUrl}getCategoriasProductos`;
    return this.http.get(url, { headers: this.getRequestHeaders() }).pipe(
      retry(1),
      catchError(error => {
        return this.handleError(error);
      }));
  }

}
