<p *ngIf="!favoritoEntity?.length">
  No hay productos agregados como favoritos
</p>

<div class="card-deck mx-auto" *ngIf="favoritoEntity.length">
  <div *ngFor="let item of favoritoEntity">
    <app-card-producto [productoItem]="item.Producto" class="my-2" [fromFavoritos]="true" (productFavoriteEmit)="removeFromFavorites($event)">
    </app-card-producto>
  </div>
</div>
