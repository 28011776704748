import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CmbSelectEntity } from 'src/app/models/cmb-select.entity';

@Component({
  selector: 'app-cmb-dropdown',
  templateUrl: './cmb-dropdown.component.html',
  styleUrls: ['./cmb-dropdown.component.scss']
})
export class CmbDropdownComponent implements OnInit {
  @Input() listCmb: CmbSelectEntity[] = [];
  @Output() cmbSelectedEmit = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  getSelectedOption(item) {
    this.cmbSelectedEmit.emit(item);
  }

}
