import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { CartEntity } from 'src/app/models/cart.entity';
import { UserStorage } from 'src/app/models/user-storage.entity';
import { ResultHelper } from 'src/app/models/Utils/result-helper.entity';
import { CarritoService } from 'src/app/services/Endpoints/carrito.endpoint';
import { BottomSheetService } from 'src/app/services/Utils/bottom-sheet.service';
import { RedirectorService } from 'src/app/services/Utils/redirector.service';
import { SnackBarService } from 'src/app/services/Utils/snackbar.service';
import { UserStorageService } from 'src/app/services/Utils/user-storage.service';

@Component({
  selector: 'app-modal-agregar-cantidad',
  templateUrl: './modal-agregar-cantidad.component.html',
  styleUrls: ['./modal-agregar-cantidad.component.scss']
})
export class ModalAgregarCantidadComponent implements OnInit {

  userStorage: UserStorage;
  isLogged = false;
  productItem: CartEntity;
  quantity = 0;
  userStorage$ = new Observable<UserStorage>();

  constructor(
    private redirectorService: RedirectorService,
    private userStorageService: UserStorageService,
    private bottomSheetService: BottomSheetService,
    public dialogRef: MatDialogRef<ModalAgregarCantidadComponent>,
    @Inject(MAT_DIALOG_DATA) public injectedData: any,
    private carritoService: CarritoService,
    private snackBarService: SnackBarService) {
    this.userStorage$ = this.userStorageService.emitUserStorage;
    this.userStorage$.subscribe((userStorageResult: UserStorage) => {
      if (userStorageResult) {
        this.userStorage = userStorageResult;
      }
      this.isLogged = this.userStorage.Usuario.IsLogged;
    });
    this.productItem = this.injectedData;
  }

  ngOnInit() {
  }

  agregarCantidadAProducto() {
    if (this.quantity) {
      let cartEntity = new CartEntity();
      cartEntity.Id = this.productItem.Id;
      cartEntity.Cantidad = Number(this.productItem.Producto.Quantity) + Number(this.quantity);
      cartEntity.NroVenta = this.userStorage.NroVenta;
      cartEntity.Id = this.productItem.Id;
      cartEntity.Producto = this.productItem.Producto;
      // ACTUALIZO LA CANTIDAD EN EL CARRITO DE LA BD
      this.carritoService.modificarCantidadProductoCarritoService(cartEntity).subscribe((result: ResultHelper) => {
        if (result.Ok) {
          this.snackBarService.openSnackBar({ tipo: "primary" }, 'Cantidad actualizada', 3000, { pos: 'right' }, undefined, 'Ok');
        } else {
          this.snackBarService.openSnackBar({ tipo: "danger" }, result.Mensaje, 3000, { pos: 'right' }, undefined, 'Ok');
        }
      }, (error) => {
        this.snackBarService.openSnackBar({ tipo: "danger" }, error, 3000, { pos: 'right' }, undefined, 'Ok');
      });
      // ACTUALIZO LA CANTIDAD EN EL SERVICIO FRONT
      this.userStorageService.modificarCantidadProducto(cartEntity);
    }
    this.dialogRef.close(true);
  }

}

export interface DialogData {
  data: any;
}
