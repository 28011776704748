<div class="container">
  <div class="row mb-1">
    <div class="col-sm-12 col-md-12 col-lg-3"></div>
    <div class="col category-menu">
      <app-chip [chipList]="listChipCategory" (chipItemEmit)="getIndexCategoryToRemove($event)"></app-chip>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-12 col-lg-3 mb-3 px-0">
      <app-category-menu-desktop class="category-menu"
      (itemCategoryEmit)="addCategoryToChips($event)" [setCategoryChecked]="categoryId"
      (productSortEmitter)="filtrarProductosPorPrecio($event.Id)"
      [listCmbFiltro]="listCmbFiltro" [categoryList]="categoryList">
      </app-category-menu-desktop>
      <app-category-menu-mobile class="category-menu-mobile" (filtersListEmitter)="getMobileFilters($event)"
      [cantResultados]="listProducts?.length" [listCmbFiltro]="listCmbFiltro" [categoryList]="categoryList"
      ></app-category-menu-mobile>
    </div>
    <div class="col-sm-12 col-md-12 col-lg-9 mb-3 px-0 d-flex justify-content-center">
      <div class="card-deck mx-auto">
        <app-card-producto *ngFor="let productItem of listProducts" [productoItem]="productItem" class="product-oferta"
          (productEmit)="getProductoQuantity($event)"></app-card-producto>
        <ngx-spinner name="spinnerProducts"></ngx-spinner>
      </div>
    </div>
  </div>
</div>
