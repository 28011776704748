<footer>
  <div class="first-row">
    <div class="first-container">
      <div class="columns-container">
        <!-- PRIMER COLUMNA -->
        <mat-list role="list" class="list-container">
          <div mat-subheader class="title-list">DATOS DE CONTACTO</div>
          <mat-list-item role="listitem" class="link-item" *ngFor="let firstColumnItem of firstColumnList">
            <a href="javascript:void(0)" title="{{ firstColumnItem.Title }}">
              <i *ngIf="firstColumnItem.Icon" class="{{ firstColumnItem.Icon }}"></i>
              <span>
                {{ firstColumnItem.Detalle }}
              </span>
            </a>
          </mat-list-item>
          <mat-list-item role="listitem" class="link-item social-items">
            <a class="icon-btn" href="javascript:void(0)" title="Seguinos en facebook">
              <i class="fab fa-facebook"></i>
            </a>
            <a class="icon-btn" href="javascript:void(0)" title="Enviar por email">
              <i class="fab fa-instagram"></i>
            </a>
          </mat-list-item>
        </mat-list>

        <!-- SEGUNDA COLUMNA -->
        <mat-list role="list" class="list-container">
          <div mat-subheader class="title-list">MENU</div>
          <mat-list-item role="listitem" class="link-item" *ngFor="let secondColumnItem of secondColumnList">
            <a href="javascript:void(0)" (click)="redirect(secondColumnItem.Link)" title="{{ secondColumnItem.Title }}">
              <span>
                {{ secondColumnItem.Detalle }}
              </span>
            </a>
          </mat-list-item>
        </mat-list>

        <!-- TERCERA COLUMNA -->
        <mat-list role="list" class="list-container">
          <div mat-subheader class="title-list">CLIENTE</div>
          <mat-list-item role="listitem" class="link-item" *ngFor="let thirdColumnItem of thirdColumnList">
            <a href="javascript:void(0)" (click)="redirectToMyAccount()" title="{{ thirdColumnItem.Title }}">
              <span>
                {{ thirdColumnItem.Detalle }}
              </span>
            </a>
          </mat-list-item>
        </mat-list>

        <!-- CUARTA COLUMNA -->
        <mat-list role="list" class="list-container list-ayuda">
          <div mat-subheader class="title-list">AYUDA</div>
          <mat-list-item role="listitem" class="link-item" *ngFor="let fourthColumnItem of fourthColumnList">
            <a href="javascript:void(0)" (click)="redirect(fourthColumnItem.Link)" title="{{ fourthColumnItem.Title }}">
              <span>
                {{ fourthColumnItem.Detalle }}
              </span>
            </a>
          </mat-list-item>
        </mat-list>
      </div>

      <!-- BOX SUSCRIPCION -->
      <div class="box-subscribir">
        <div class="title-container">
          <p>Quiero recibir ofertas y novedades de productos.</p>
        </div>
        <div class="input-container">
          <mat-form-field appearance="outline">
            <mat-label>Nombre y Apellido</mat-label>
            <input matInput placeholder="">
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>mi@correo.com</mat-label>
            <input matInput placeholder="">
          </mat-form-field>
        </div>
        <div class="btn-container">
          <button mat-stroked-button type="button" class="rounded gm-default">SUSCRIBIRME</button>
        </div>
        <div class="icon-container">
          <div class="img-content">
            <div class="img-item" *ngFor="let imgItem of iconsImg">
              <img [src]="imgItem" alt="">
            </div>
          </div>
        </div>
      </div>

      <mat-divider class="mx-auto"></mat-divider>

      <div class="second-row">
        <div class="first-box">
          <a href="javascript:void(0)" (click)="redirect('terminos-y-condiciones')">
            <span>
              TÉRMINOS Y CONDICIONES
            </span>
          </a>

          <a href="javascript:void(0)" (click)="redirect('politicas-privacidad')">
            <span>
              POLÍTICAS DE PRIVACIDAD
            </span>
          </a>
        </div>
        <div class="second-box">
          <p>© 2020 - Dietética Ser Natural - Dietética. Todos los derechos reservados</p>
        </div>
      </div>
    </div>

  </div>

  <div class="third-row">
    <div class="logo-container">
      <div class="text-container">
        <p>diseñado por:</p>
      </div>
      <div class="img-container">
        <img [src]="logoImg" alt="">
      </div>
    </div>
  </div>

</footer>
