<div class="container">
  <div class="row">
    <div class="col p-5">
      <app-section-title [title]="'Políticas de privacidad'"></app-section-title>
      <p class="p-content">
        Para poder utilizar el Sitio de manera eficiente y segura, los Usuarios deberán aportar ciertos datos, entre
        ellos, su nombre y apellido, domicilio, cuenta de mail, documento de identidad, sin los cuales se tornaría
        imposible brindar los servicios. Por eso se requiere que éstos sean verdaderos y exactos. Los datos recabados
        por los formularios correspondientes serán incorporados a la base general clientes de Luz Verde Dietética.
        <br>
        Cuando se ingresan datos y números correspondientes a tarjetas de crédito estos son encriptados, asegurando así
        que se mantengan en total confidencialidad y no puedan ser vistos por otras personas. Los Usuarios tienen la
        facultad de ejercer el derecho de acceso, rectificación, bloqueo o supresión de los datos personales que hayan
        sido suministrados al Sitio personalmente o a través de un tercero. Se aclara, que estos derechos sólo podrán
        ejercerse sobre aquellas informaciones que puedan considerarse datos personales en los términos de la Ley
        Nº25.326.
        <br>
        Asimismo, los Usuarios podrán requerir en cualquier momento la baja de su solicitud y la eliminación de su
        cuenta de la base de datos, enviando un correo electrónico ventas@luzverdedietetica.com.ar solicitando su baja
        de la base de datos o solicitud.
        <br>
        Al registrarse en el sitio y formar parte de la base de datos de clientes, los usuarios aceptan que Luz Verde
        Dietética se comunique con ellos por vía postal, telefónica o electrónica para enviar información que Luz Verde
        Dietética considere, a su exclusivo criterio, que pueda ser de su interés, incluyendo publicidad e información
        sobre ofertas y promociones. En caso de que los usuarios no deseen ser contactados con estos fines, podrán
        manifestárselo fehacientemente a Luz Verde Dietética, quien procederá a interrumpir este tipo de comunicaciones
        en el menor tiempo que le sea posible.
      </p>
    </div>
  </div>
</div>
