import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {
  constructor() { }

  /**
   * Funcion para encriptar cualquier valor
   * @param value Valor a encriptar
   * @returns encrypted value
   */
  encrypt(key, value) {
    return CryptoJS.AES.encrypt(value, key).toString();
  }

   /**
   * Funcion para desencriptar cualquier valor
   * @param value Valor a desncriptar
   * @returns decrypted value
   */
  decrypt(key, value) {
    return CryptoJS.AES.decrypt(value, key).toString(CryptoJS.enc.Utf8);
  }

}
