import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatHorizontalStepper } from '@angular/material/stepper';
import { Observable } from 'rxjs';
import { ConfirmaPedidoEntity } from 'src/app/models/confirma-pedido.entity';
import { MailDataEntity } from 'src/app/models/mail-data-entity';
import { UserStorage } from 'src/app/models/user-storage.entity';
import { ResultHelper } from 'src/app/models/Utils/result-helper.entity';
import { CarritoService } from 'src/app/services/Endpoints/carrito.endpoint';
import { MailService } from 'src/app/services/Endpoints/mail.endpoint';
import { RedirectorService } from 'src/app/services/Utils/redirector.service';
import { SnackBarService } from 'src/app/services/Utils/snackbar.service';
import { UserStorageService } from 'src/app/services/Utils/user-storage.service';

@Component({
  selector: 'app-stepper-cart',
  templateUrl: './stepper-cart.component.html',
  styleUrls: ['./stepper-cart.component.scss']
})
export class StepperCartComponent implements OnInit {
  @Output() stepperIndexEmit = new EventEmitter();
  @Output() isNextEnabled = new EventEmitter();
  @ViewChild('stepper') stepperRef: MatHorizontalStepper;
  @Input() totalAmount: number = 0;
  montoTotalAPagar: number;
  userStorage: UserStorage;
  userStorage$ = new Observable<UserStorage>();


  isTermsChecked = false;

  constructor(
    private redirectorService: RedirectorService,
    private userStorageService: UserStorageService,
    private carritoService: CarritoService,
    private snackBarService: SnackBarService,
    private mailService: MailService
  ) {
    this.userStorage$ = this.userStorageService.emitUserStorage;
    this.userStorage$.pipe().subscribe((res) => {
      if (res) {
        this.userStorage = res;
      }
    });
  }

  ngOnInit() {
    var docWidth = document.documentElement.offsetWidth;

    [].forEach.call(
      document.querySelectorAll('*'),
      function (el) {
        if (el.offsetWidth > docWidth) {
          console.log(el);
        }
      }
    );
  }

  getStepperIndex(index?) {
    index = (index) ? index : this.stepperRef.selectedIndex;
    this.stepperIndexEmit.emit(index);
  }

  goForward(goForwardEvent?) {
    if (goForwardEvent) {
      if (this.stepperRef.selectedIndex === 1) { // SI VIENE DEL STEP DEL DETALLE, AL CONFIRMAR EL PEDIDO
        if (goForwardEvent.avanza) {
          this.postConfirmarPedido(goForwardEvent.tipoEntrega);
        }
      } else {
        this.redirectToNextStep();
      }
    }
  }

  private redirectToNextStep() {
    this.isNextEnabled.emit(true);
    this.stepperRef.next();
  }

  postConfirmarPedido(tipoEntrega) {
    let confirmaVenta = new ConfirmaPedidoEntity();
    confirmaVenta.IdCliente = this.userStorage.Usuario.Id;
    confirmaVenta.NroVenta = this.userStorage.NroVenta;
    confirmaVenta.TipoEntrega = tipoEntrega;
    confirmaVenta.MontoTotal = this.totalAmount;
    if (this.userStorage.IdDescuento) {
      confirmaVenta.IdDescuento = this.userStorage.IdDescuento;
      confirmaVenta.TieneDescuentoAplicado = true;
    }

    this.carritoService.confirmarPedidoService(confirmaVenta).subscribe((result: ResultHelper) => {
      if (result.Ok) {
        this.sendMail();
      } else {
        this.snackBarService.openSnackBar({ tipo: "danger" }, result.Mensaje, 3000, { pos: 'right' }, undefined, 'Ok');
      }
    },
      (error) => { this.snackBarService.openSnackBar({ tipo: "danger" }, error, 3000, { pos: 'right' }, undefined, 'Ok'); });
  }

  sendMail() {
    let mailData = new MailDataEntity();
    mailData.NombreCliente = `${this.userStorage.Usuario.Nombre + ' ' + this.userStorage.Usuario.Apellido}`;
    mailData.NroVenta = this.userStorage.NroVenta;
    mailData.Fecha = new Date();
    mailData.Email = this.userStorage.Usuario.Email;

    this.mailService.sendPedidoMailService(mailData).subscribe((result: ResultHelper) => {
      if (result.Ok) {
        this.snackBarService.openSnackBar({ tipo: "success" }, 'Compra confirmada con éxito', 3000, { pos: 'right' }, undefined, 'Ok');
        this.redirectToNextStep();
      } else {
        this.snackBarService.openSnackBar({ tipo: "danger" }, result.Mensaje, 3000, { pos: 'right' }, undefined, 'Ok');
      }
    },
      (error) => { this.snackBarService.openSnackBar({ tipo: "danger" }, error, 3000, { pos: 'right' }, undefined, 'Ok'); });
  }


}
