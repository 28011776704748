import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-confirmar-pedido',
  templateUrl: './modal-confirmar-pedido.component.html',
  styleUrls: ['./modal-confirmar-pedido.component.scss']
})
export class ModalConfirmarPedidoComponent implements OnInit {
  nroPedido;

  constructor(
    private dialogRef: MatDialogRef<ModalConfirmarPedidoComponent>,
    @Inject(MAT_DIALOG_DATA) public injectedData: any) {
    this.nroPedido = injectedData.NroVenta;
  }

  ngOnInit() {
  }

  confirmarPedido() {
    this.dialogRef.close(true);
  }

}
