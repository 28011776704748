import { UserStorageService } from 'src/app/services/Utils/user-storage.service';
import { UserStorage } from 'src/app/models/user-storage.entity';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserEntity } from 'src/app/models/user.entity';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
  private isLogged = false;
  private userStorage: UserStorage;
  userStorage$ = new Observable<UserStorage>();

  constructor(
    private userStorageService: UserStorageService,
    private router: Router,
    //private senderService: SenderService
  ) {
    this.userStorage$ = this.userStorageService.emitUserStorage;
    this.userStorage$.pipe().subscribe((res) => {
      if (res) {
        this.userStorage = res;
        this.isLogged = this.userStorage.Usuario.IsLogged;
      }
    });
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    // let setMostrar;
    // if (!this.auth.isLogueado) {
    //   setMostrar = false;
    //   this.senderService.enviarObjeto(setMostrar);
    //   if (state.url === '/login') {
    //     return true;
    //   }
    //   this.router.navigate(['login']);
    //   return false;
    // } else if (state.url === '/login') {
    //   this.router.navigate(['home']);
    //   return true;
    // }
    // setMostrar = true;
    // this.senderService.enviarObjeto(setMostrar);
    return true;
  }
}
