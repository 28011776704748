<!-- <form> -->
  <app-header-title [title]="'Información Personal'"></app-header-title>
  <div class="d-flex personal-information">
    <mat-form-field class="flex-one">
      <input matInput placeholder="Nombre" [(ngModel)]="userStorage.Usuario.Nombre">
      <mat-icon matSuffix>person</mat-icon>
    </mat-form-field>
    <mat-form-field class="flex-one">
      <input matInput placeholder="Apellido" [(ngModel)]="userStorage.Usuario.Apellido">
      <mat-icon matSuffix>person</mat-icon>
    </mat-form-field>
  </div>

  <div class="d-flex">
    <mat-form-field class="flex-one">
      <input matInput type="email" placeholder="Correo" [(ngModel)]="userStorage.Usuario.Email">
      <mat-icon matSuffix>drafts</mat-icon>
    </mat-form-field>
    <mat-form-field class="flex-one">
      <input type="tel" matInput placeholder="Celular" [(ngModel)]="userStorage.Usuario.Celular">
      <mat-icon matSuffix>call</mat-icon>
    </mat-form-field>
  </div>

  <div class="d-flex">
    <mat-form-field class="flex-one">
      <input matInput placeholder="Domicilio" [(ngModel)]="userStorage.Usuario.Domicilio">
      <mat-icon matSuffix>home</mat-icon>
    </mat-form-field>
    <mat-form-field class="flex-one">
      <input appOnlyNumber type="text" matInput placeholder="Número" [(ngModel)]="userStorage.Usuario.NroDomicilio">
      <mat-icon matSuffix>money</mat-icon>
    </mat-form-field>
  </div>

  <div class="d-flex">
    <mat-form-field class="flex-one">
      <mat-label>Provincia</mat-label>
      <mat-select [(ngModel)]="userStorage.Usuario.IdProvincia" (selectionChange)="getLocalidadesPorProvincia($event.value)">
        <mat-option *ngFor="let provincia of provinciasList" [value]="provincia.Id">{{ provincia.Nombre }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="flex-one">
      <mat-label>Localidad</mat-label>
      <mat-select [(ngModel)]="userStorage.Usuario.IdLocalidad" [disabled]="!localidadesList?.length" (selectionChange)="getBarrioPorLocalidad($event.value)">
        <mat-option *ngFor="let localidad of localidadesList" [value]="localidad.Id">{{ localidad.Nombre }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="d-flex">
    <mat-form-field class="flex-one">
      <mat-label>Barrio</mat-label>
      <mat-select [(ngModel)]="userStorage.Usuario.IdBarrio" [disabled]="!barrioList?.length">
        <mat-option *ngFor="let barrio of barrioList" [value]="barrio.Id">{{ barrio.Nombre }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="flex-one">
      <input appOnlyNumber type="text" matInput placeholder="Código postal" [(ngModel)]="userStorage.Usuario.CodPostal">
      <mat-icon matSuffix>money</mat-icon>
    </mat-form-field>
  </div>

  <button mat-raised-button class="px-3 mb-3" (click)="updateDatosPersonales()">
    Guardar
  </button>

  <!-- ACTUALIZACION DE CONTRASEÑA -->

  <app-header-title [title]="'Actualizar Contraseña'"></app-header-title>
  <div class="display-type">
    <mat-form-field appearance="outline" class="d-block pass-form-field">
      <mat-label>Contraseña actual</mat-label>
      <input matInput [type]="hidePass ? 'password' : 'text'" [(ngModel)]="userStorage.Usuario.Password" (input)="verifyBtnSavePass()">
      <button mat-icon-button matSuffix (click)="hidePass = !hidePass" [attr.aria-label]="'Ocultar contraseña'"
        [attr.aria-pressed]="hidePass">
        <mat-icon>{{hidePass ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>
    <mat-form-field appearance="outline" class="d-block pass-form-field">
      <mat-label>Nueva Contraseña</mat-label>
      <input matInput [type]="hidePass ? 'password' : 'text'" [(ngModel)]="userStorage.Usuario.NewPassword" (input)="verifyBtnSavePass()">
      <button mat-icon-button matSuffix (click)="hidePass = !hidePass" [attr.aria-label]="'Ocultar contraseña'"
        [attr.aria-pressed]="hidePass">
        <mat-icon>{{hidePass ? 'visibility_off' : 'visibility'}}</mat-icon>
      </button>
    </mat-form-field>
  </div>

  <button mat-raised-button class="px-3 btn-guardar" (click)="updatePassword()" [disabled]="!isPassUpdateEnabled">
    Actualizar
  </button>
