<div [ngClass]="{'focusItem': menuElimiarProducto}"></div>

<mat-table [dataSource]="dataSource" matSort>

  <ng-container matColumnDef="producto">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Producto </mat-header-cell>
    <mat-cell *matCellDef="let row" data-label="producto"> {{row.Producto.Title}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="cantidad">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Cantidad </mat-header-cell>
    <mat-cell *matCellDef="let row" data-label="cantidad"> {{row.Producto.Quantity}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="precio">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Precio </mat-header-cell>
    <mat-cell *matCellDef="let row" data-label="precio"> {{ row.Producto.Price | gmCurrency }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="subtotal">
    <mat-header-cell *matHeaderCellDef mat-sort-header> Subtotal </mat-header-cell>
    <mat-cell *matCellDef="let row" [style.color]="row.color" data-label="subtotal">
      {{ setSubtotalPorProducto(row.Producto) | gmCurrency }} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="accion">
    <mat-header-cell *matHeaderCellDef mat-sort-header></mat-header-cell>
    <mat-cell *matCellDef="let row" [style.color]="row.color" data-label="">
      <button mat-icon-button matTooltip="Agregar cantidad" aria-label="Agregar cantidad producto al carrito"
        (click)="addQuantityToProduct(row)">
        <mat-icon>add_shopping_cart</mat-icon>
      </button>

      <button mat-icon-button matTooltip="Borrar producto" aria-label="Borrar producto de carrito"
        [matMenuTriggerFor]="menu" (menuOpened)="toggleMenuOpened()" (menuClosed)="toggleMenuOpened()">
        <mat-icon>delete_forever</mat-icon>
      </button>
      <mat-menu #menu="matMenu" class="p-3 text-center menu-marcacion">
        <p>¿Eliminar producto?</p>
        <button cdkFocusInitial mat-stroked-button color="primary" class="m-2"
          (click)="deleteProductFromCart(row)">Si</button>
        <button mat-stroked-button class="m-2">No</button>
      </mat-menu>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="cartColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: cartColumns;">
  </mat-row>
</mat-table>
