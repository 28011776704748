import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, TimeoutError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  private readonly _apiGeneralUrl = 'https://gmdisegnipruebas.minos.urltemporal.com/apis/Controller/GeneralController.php?action=';


  protected getRequestHeaders(params?: any): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json'
      // 'Access-Control-Allow-Headers': 'Content-Type'
    });
    return headers;
  }


  handleError(error: HttpErrorResponse) {
    if (error instanceof TimeoutError) {
      return throwError('En este momento no podemos realizar la operación. Por favor intente nuevamente más tarde.');
    }
    console.log(error)
    return throwError(error);
  }

  constructor(private http: HttpClient) { }

  /*--------------------GET------------------------*/

  getProvinciasService() {
    const url = `${this._apiGeneralUrl}getProvincias`;

    return this.http.get(url, { headers: this.getRequestHeaders() }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  getLocalidadesPorProvinciaService(idProvincia) {
    const url = `${this._apiGeneralUrl}getLocalidadesPorIdProvincia`;
    const params = new HttpParams()
      .set('param', (idProvincia) ? JSON.stringify(idProvincia) : null);

    return this.http.get(url, { headers: this.getRequestHeaders(), params }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }

  getBarriosPorLocalidadService(idLocalidad) {
    const url = `${this._apiGeneralUrl}getBarrios`;
    const params = new HttpParams()
      .set('param', (idLocalidad) ? JSON.stringify(idLocalidad) : null);

    return this.http.get(url, { headers: this.getRequestHeaders(), params }).pipe(
      catchError(error => {
        return this.handleError(error);
      }));
  }
}
