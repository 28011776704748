import { NavEntity } from '../models/nav.entity';

export const getMiCuentaNavList = (): Array<NavEntity> => {
  const navMenuList = [
    {
      Title: 'Mi Cuenta',
      Icon: 'account_circle',
      Disabled: false,
      Hidden: false,
      Link: 'a'
    },
    {
      Title: 'Mis Pedidos',
      Icon: 'local_mall',
      Disabled: false,
      Hidden: false,
      Link: 'b'
    },
    {
      Title: 'Mis Favoritos',
      Icon: 'favorite',
      Disabled: false,
      Hidden: false,
      Link: 'c'
    },
    {
      Title: 'Actualizar Mis Datos',
      Icon: 'perm_contact_calendar',
      Disabled: false,
      Hidden: false,
      Link: 'd'
    }
  ];
  return navMenuList;
};
