export class Producto {
  Id?: any;
  ImgUrl?: string;
  Title?: string;
  Price?: any;
  Quantity: number;
  BtnText?: string;
  Subtotal?: number;
  DetalleProd: string;
  IdCat: number;
  NombreCat: string;
  IdSubCat: number;
  NombreSubCat: string;
  IdMostrar: number;
  IdTipoProducto: number;
  NombreTipoProducto: string;
  Codigo: number;

  public getSubtotal() {
    return this.Quantity * this.Price;
  }
}
