import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { CartEntity } from 'src/app/models/cart.entity';
import { UserStorage } from 'src/app/models/user-storage.entity';
import { ResultHelper } from 'src/app/models/Utils/result-helper.entity';
import { CarritoService } from 'src/app/services/Endpoints/carrito.endpoint';
import { UsuarioService } from 'src/app/services/Endpoints/usuarios.endpoint';
import { BottomSheetService } from 'src/app/services/Utils/bottom-sheet.service';
import { RedirectorService } from 'src/app/services/Utils/redirector.service';
import { SnackBarService } from 'src/app/services/Utils/snackbar.service';
import { UserStorageService } from 'src/app/services/Utils/user-storage.service';
import { RegisterComponent } from './register/register.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  hidePass = true;
  checkoutForm;
  userStorage = new UserStorage();
  userStorage$ = new Observable<UserStorage>();

  constructor(
    private redirectorService: RedirectorService,
    private userStorageService: UserStorageService,
    private bottomSheetService: BottomSheetService,
    private snackBarService: SnackBarService,
    private usuariosService: UsuarioService,
    private carritoService: CarritoService,
    private formBuilder: FormBuilder
  ) {
    this.userStorage$ = this.userStorageService.emitUserStorage;
    this.userStorage$.pipe().subscribe((res) => {
      if (res) {
        this.userStorage = res;
      }
    });

    this.checkoutForm = this.formBuilder.group(
      {
        Email: '',
        Password: ''
      }
    );
  }

  ngOnInit() {
  }

  openRegisterForm() {
    this.bottomSheetService.dismissBottomSheet(true, RegisterComponent);
  }

  /**
  * VALIDACION DE DATOS INGRESADOS POR EL USUARIO
  */
  private validarDatos() {
    if (!this.userStorage.Usuario.Email) {
      this.snackBarService.openSnackBar({ tipo: "danger" }, 'Debe ingresar un email válido', 3000, { pos: 'right' }, undefined, 'Ok');
      return false;
    }
    if (!this.userStorage.Usuario.Password) {
      this.snackBarService.openSnackBar({ tipo: "danger" }, 'Debe ingresar una constraseña válida', 3000, { pos: 'right' }, undefined, 'Ok');
      return false;
    }
    return true;
  }

  getLoginUsuario(values) {
    this.userStorage.Usuario.setUserMailPass(values);
    // this.userEntity.setUserMailPass(values);
    if (this.validarDatos()) { // SE VALIDAN LOS CAMPOS INGRESADOS POR EL USUARIO
      // SE SETEA EL LOGUEO
      this.usuariosService.getUsuarioLoginService(this.userStorage.Usuario).subscribe((result: ResultHelper) => {
        if (result.Result.IsLogged) { // SI EL BACKEND DEVUELVE LOS DATOS DEL USUARIO OK
          this.userStorage.Usuario = result.Result; // ASIGNO LOS DATOS DEL USUARIO DEL BACKEND AL USUARIO ACTUAL
          this.userStorageService.setUserStorage(this.userStorage); // GUARDO EL USUARIO LOGUEADO EN EL STORAGE
          this.snackBarService.openSnackBar({ tipo: "success" }, 'Inició sesión correctamente', 3000, { pos: 'right' }, undefined, 'Ok');
          this.userStorageService.findNroVenta().subscribe((nroVentaResult: number) => { // SOLICITO EL NROVENTA AL SERVICIO
            this.userStorageService.setNroVentaStorage(nroVentaResult); // SETEO EL NROVENTA EN EL STORAGE DEL FRONT
            this.userStorageService.checkCarritoItems(false);
            this.userStorageService.setLocalStorageUser(); // GUARDO EL USUARIO LOGUEADO EN EL LOCALSTORAGE ENCRIPTADO
            this.bottomSheetService.dismissBottomSheet(); // OCULTA EL BOTTOM SHEET DONDE ESTA EL FORM DE LOGUEO
            this.checkoutForm.reset(); // LIMPIA LOS CAMPOS
            this.checkCarritoItems(); // RECUPERO PRODUCTOS DEL CARRITO
          });
        } else {
          this.snackBarService.openSnackBar({ tipo: "danger" }, 'Verifique email y contraseña', 3000, { pos: 'right' }, undefined, 'Ok');
        }
      },
        (error) => {
          this.snackBarService.openSnackBar({ tipo: "danger" }, error, 3000, { pos: 'right' }, undefined, 'Ok');
        });
    }
  }

  /**
   * RECUPERACION DE PRODUCTOS AGREGADOS AL CARRITO
   */
  checkCarritoItems() {
    this.carritoService.getItemsCarrito(this.userStorage.NroVenta).subscribe((result: ResultHelper) => {
      if (result.Ok) {
        // SI EL BACKEND DEVUELVE PRODUCTOS, LOS AGREGO AL CARRITO DEL STORAGE
        result.Result.forEach((cartItemElement: CartEntity) => {
          this.userStorageService.addProductToCart(cartItemElement);
        });
      } else {
        this.snackBarService.openSnackBar({ tipo: "danger" }, result.Mensaje, 3000, { pos: 'right' }, undefined, 'Ok');
      }
    },
      (error) => { this.snackBarService.openSnackBar({ tipo: "danger" }, error, 3000, { pos: 'right' }, undefined, 'Ok'); });
  }

}
