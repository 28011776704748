<!-- DATOS PERSONALES -->
<app-header-title [title]="'Datos personales'"></app-header-title>
<div class="px-3 mt-3 form-field-container">
  <mat-form-field class="flex-one" appearance="outline" [floatLabel]="'always'">
    <input matInput type="email" placeholder="E-Mail" id="email"
      [(ngModel)]="userStorage.Usuario.Email" [disabled]="true">
  </mat-form-field>
</div>
<div class="px-3 form-field-container">
  <mat-form-field class="flex-one mr-2" appearance="outline" [floatLabel]="'always'">
    <input matInput type="text" placeholder="Nombre" id="nombre" [(ngModel)]="userStorage.Usuario.Nombre"
      data-checkout="cardholderName" [disabled]="true">
  </mat-form-field>
  <mat-form-field class="flex-one" appearance="outline" [floatLabel]="'always'">
    <input matInput type="text" placeholder="Apellido" id="apellido" [(ngModel)]="userStorage.Usuario.Apellido"
      data-checkout="cardholderName" [disabled]="true">
  </mat-form-field>
</div>
<div class="px-3 form-field-container">
  <mat-form-field class="flex-one mr-2" appearance="outline" [floatLabel]="'always'">
    <mat-label>Tipo de documento</mat-label>
    <mat-select [(ngModel)]="mercadopagoObj.PersonalValues.DocType">
      <mat-option *ngFor="let docType of doctypesList" [value]="docType.id">{{docType.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="flex-one" appearance="outline" [floatLabel]="'always'">
    <input matInput type="email" placeholder="Número documento" id="nroDoc"
      [(ngModel)]="mercadopagoObj.PersonalValues.DocNumber">
  </mat-form-field>
</div>
