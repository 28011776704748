import { NavEntity } from '../models/nav.entity';


export const getSubNavMenuList = (): Array<NavEntity> => {
  const navMenuList = [
    {
      Title: 'Inicio',
      Icon: 'fas fa-home',
      Disabled: false,
      Hidden: false,
      Link: 'home'
    },
    {
      Title: 'Productos',
      Icon: 'fas fa-tag',
      Disabled: false,
      Hidden: false,
      Link: 'productos'
    },
    {
      Title: 'Ofertas',
      Icon: 'fas fa-percentage',
      Disabled: false,
      Hidden: false,
      Link: 'ofertas'
    },
    {
      Title: 'Contacto',
      Icon: 'fas fa-paper-plane',
      Disabled: false,
      Hidden: false,
      Link: 'contacto'
    }
  ];
  return navMenuList;
};

export const getCategoryNavList = (): Array<NavEntity> => {
  const navMenuList = [
    {
      Title: 'Cereales',
      Icon: '',
      Disabled: false,
      Hidden: false,
      Link: ''
    },
    {
      Title: 'Lácteos',
      Icon: '',
      Disabled: false,
      Hidden: false,
      Link: ''
    },
    {
      Title: 'Almacén',
      Icon: '',
      Disabled: false,
      Hidden: false,
      Link: ''
    },
    {
      Title: 'Varios',
      Icon: '',
      Disabled: false,
      Hidden: false,
      Link: ''
    },
    {
      Title: 'Más Catergorías',
      Icon: '',
      Disabled: false,
      Hidden: false,
      Link: ''
    }
  ];
  return navMenuList;
};
