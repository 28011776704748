import { Component, OnInit } from '@angular/core';
import { BoxEntity } from 'src/app/models/box.entity';

@Component({
  selector: 'app-ofertas',
  templateUrl: './ofertas.component.html',
  styleUrls: ['./ofertas.component.scss']
})
export class OfertasComponent implements OnInit {
  lstBoxOferta: BoxEntity[] = [];

  constructor() { }

  ngOnInit() {
    this.getOfertasList();
  }

  getOfertasList() {
    this.lstBoxOferta = [
      {
        Description: 'Aprovechá las ofertas de este mes de Rincón Integral',
        ImgUrl: './assets/images/index/oferta.png'
      },
      {
        Description: 'Aprovechá las ofertas de este mes de Rincón Integral',
        ImgUrl: './assets/images/index/oferta.png'
      }
    ];
    /*this.boxOfertasList.forEach((item, index) => {
      item.Id = index;
    });*/
  }

}
