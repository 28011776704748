import { Component, Input, OnInit } from '@angular/core';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { ImgCarouselEntity } from 'src/app/models/img-carousel';

@Component({
  selector: 'app-banner-slideshow',
  templateUrl: './banner-slideshow.component.html',
  styleUrls: ['./banner-slideshow.component.scss']
})
export class BannerSlideshowComponent implements OnInit {
  @Input() fullscreen = false;
  @Input() imageList: ImgCarouselEntity[] = [];
  config: SwiperConfigInterface = {
    pagination: {
      el: '.swiper-pagination',
    },
    autoplay: { delay: 3000 },
    slidesPerView: 'auto',
    spaceBetween: 10,
    centeredSlides: true,
    initialSlide: 1,
    grabCursor: true
  }

  constructor() { }

  ngOnInit() {
    this.setCarrouselImg();
  }

  private setCarrouselImg() {
    this.imageList = [
      {
        Url: 'https://mdbootstrap.com/img/Photos/Slides/img%20(68).jpg',
        IsActive: true
      },
      {
        Url: 'https://mdbootstrap.com/img/Photos/Slides/img%20(6).jpg',
        IsActive: false
      },
      {
        Url: 'https://mdbootstrap.com/img/Photos/Slides/img%20(9).jpg',
        IsActive: false
      }
    ];
  }

}
