<div class="container">
  <div class="row my-3 p-2">
    <div class="col-sm-12 col-lg-3 p-0 soft-shadow m-2">
      <div class="jumbotron">
        <h1 class="display-4">Ser Natural</h1>
        <hr class="my-4">
        <p class="lead p-location">
          <mat-icon aria-hidden="false" aria-label="Ubicacion">location_on</mat-icon>
          Tucumán 216
        </p>
        <p class="lead p-location">
          <i class="fab fa-whatsapp"></i>
          (+54) 9 351 2091556
        </p>
        <p class="lead p-location">
          <mat-icon aria-hidden="false" aria-label="Ubicacion">alternate_email</mat-icon>
          ventas@dieteticasernatural.com.ar
        </p>
      </div>
    </div>
    <div class="col soft-shadow p-0 m-2">
      <div class="jumbotron">
        <h1 class="display-4">Formulario de contacto</h1>
        <hr class="my-4">
        <app-formulario [mail]="mail" (sendMailEmitter)="sendMail($event)"></app-formulario>
      </div>
    </div>
  </div>
</div>
