<div class="sidenav-content">
  <mat-nav-list>
    <div mat-subheader>Menú</div>
    <mat-list-item *ngFor="let itemMenu of subNavList" class="d-flex">
      <i class="mr-2" [ngClass]="itemMenu.Icon"></i>
      <a onclick="this.blur()" href="javascript:void(0)" (click)="redirect(itemMenu.Link)">
        {{ itemMenu.Title }}</a>
    </mat-list-item>
    <mat-divider></mat-divider>
    <div mat-subheader>Categorías</div>
    <mat-list-item *ngFor="let itemCategoria of categoryMenuList" class="d-flex">
      <i class="mr-2" [ngClass]="itemCategoria.Icon"></i>
      <a onclick="this.blur()" href="javascript:void(0)" (click)="redirect(itemCategoria.Link)">
        {{ itemCategoria.Title }}</a>
    </mat-list-item>
  </mat-nav-list>
</div>

