import { Producto } from './producto.entity';

export class CartEntity {
  Id?: number;
  IdProducto: number;
  IdCliente: number;
  NroVenta: number;
  Fecha: Date;
  Cantidad: number;
  Posicion: number;
  ActualizaCantidad: boolean;
  Producto: Producto;
}
