import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Categoria } from 'src/app/models/categoria.entity';

@Component({
  selector: 'app-list-checkbox',
  templateUrl: './list-checkbox.component.html',
  styleUrls: ['./list-checkbox.component.scss']
})
export class ListCheckboxComponent implements OnInit {
  @Input() itemList: Categoria[] = [];
  @Output() optionSelectedEmitter = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  emitSelectedItem(item: Categoria) {
    // OBTENGO EL OBJETO SELECCIONADO
    item.Checked = !item.Checked;
    this.optionSelectedEmitter.emit(item);
  }

}
